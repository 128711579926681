.skeleton_Wrapper{
  position: relative;
  overflow: hidden;
  width: 100%;
}
.skeleton{
  background: #e4e4e4;
  border-radius: 1rem;
}

.skeleton.productBox {
  width: 150px;
  height: 216px;
}

.skeleton.productBanner {
  width: 100%;
  height: 260px;
}

.skeleton.productBannerSmall {
  width: 328px;
  height: 328px;
}

.skeleton.productTitle{
  width: 50%;
  height: 30px;
  border-radius: 0.5rem;
}

.skeleton.productPrice{
  width: 25%;
  height: 24px;
  border-radius: 0.5rem;
}

.skeleton.productPreviewImage {
  height: 424px;
  width: 100%;
  border-radius: 0;
}

.skeleton.productThumbnail{
  height: 80px;
  width: 80px;
  border-radius: 0;
}

.shimmer_Wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  animation: loading 1.2s infinite;
}

.shimmer{
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  transform: skew(-20deg);
  box-shadow: 0 0 36px 36px rgba(255, 255, 255, 0.1);
}

@keyframes loading {
  0% { transform: translateX(-150%); }
  50% {
    transform: translateX(-60%);
  }
  100% {
    transform: translateX(150%);
  }
}

@media screen and (min-width: 768px) {
  .skeleton.productBox {
    width: 312px;
    height: 356px;
  }
  
  .skeleton.productBanner {
    width: 100%;
    height: 400px;
  }
  
  .skeleton.productBannerSmall {
    width: 1120px;
    height: 326px;
  }
}

@media screen and (min-width: 1024px) {
  .skeleton.productBox {
    width: 312px;
    height: 356px;
  }
  
  .skeleton.productBanner {
    width: 100%;
    height: 435px;
  }
  
  .skeleton.productBannerSmall {
    width: 1120px;
    height: 326px;
  }
}