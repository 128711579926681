.changeNameContainer {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Quicksand";
  margin-top: var(--pageMarginTop);
}

.changeNameContent {
  padding: 10px 54px 5px 118px;
  display: flex;
  width: 100%;
}

.changeNameFormContainer {
  width: 60%;
}

.changeNameForm {
  margin-bottom: 35px;
}

/* Image Side  */

.changeNameImageContainer {
  width: 40%;
  padding-top: 50px;
}

.changeNameImageContainer img {
  width: 430px;
  height: 430px;
}

/* Form Side */

.changeNameHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 40px;
}

.changeNameFormLabelTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #000000;
}

.changeNameInputDiv {
  padding: 10px;
  border: 1px solid #a6a6a6;
  width: 530px;
  margin-bottom: 13px;
  border-radius: 7px;
}

.changeNameInputField {
  border: none;
  outline: none;
  padding: 3px;
  width: 100%;
  font-size: 20px;
}

.changeNameInputField::placeholder {
  font-size: 20px;
  color: black;
}

.changeNamecheckBoxDiv {
  display: flex;
  gap: 5px;
  margin-top: 7px;
  margin-bottom: 17px;
}

.changeNameCheckbox {
  display: inline-block;
  width: 20px;
  border: 2px solid #0066c0;
}

.changeNameConfirmCheckbox {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0066c0;
}

.changeNameButtonSection {
  display: flex;
  gap: 60px;
}

.changeNamesubmitButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.changeNamesubmitButton p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.changeNamecancelButton {
  outline: none;
  border: none;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.changeNamecartButtons1Cancel {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
}

.changeNameFormLabelSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.expandable-hide {
  background: #fff;
  overflow: hidden;
  padding: 0;
  margin-bottom: 0;
  border: none;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 0;
}

.expandable-show {
  margin-bottom: 13px;
  padding: 10px;
  border: 1px solid #a6a6a6;
  height: auto;
  color: #000;
  overflow: auto;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media screen and (max-width: 500px) {

  .changeNameContainer {
    position: relative;
  }

  .changeNameImageContainer {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: -100;
    padding-top: 10px;
  }

  .changeNameImageContainer img {
    width: 150px;
    height: 150px;
  }

  .changeNameContent {
    padding: 10px 14px 5px 10px;
  }

  .changeNameFormContainer {
    width: 100%;
  }

  .changeNameInputDiv,
  .expandable-show {
    padding: 5px;
    max-width: 340px;
    width: 90%;
    margin-bottom: 10px;
    background-color: white;
  }

  .changeNameHeading {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .changeNameFormLabelTitle {
    font-size: 25px;
    line-height: 33px;
  }

  .changeNameInputField::placeholder {
    font-size: 15px;
  }

  .changeNameFormLabelSubTitle {
    font-size: 17px;
    line-height: 22px;
  }

  .changeNameButtonSection {
    gap: 30px;
  }

}