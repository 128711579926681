.parentClass {
    padding: 20px;
    width: 100vw;
    margin-top: var(--pageMarginTop);
}

.userHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 9%;
    gap: 30px;
}

.profileImage {
    border-radius: 50%;
    height: 140px;
    width: 130px;
}

.userName {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    /* identical to box height */


    color: #000000;
}

.divider {
    width: 0px;
    height: 100px;
    margin-top: 2%;
    border: 1px solid #000000;
}

.mobileCard {
    display: flex;
    flex-direction: column;
    gap: 50px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 20px 0;
    margin: 2%;
}

/* .cart-banner {
    display: flex;
    justify-content: space-evenly;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #0066c0;
    margin-top: 2rem;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }

.cart-banner-item {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
} */

#sub-banner1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
}


.icn-sz {
    width: 35px;
    height: 35px;
    /* padding: 1rem; */
    margin-right: 10px;
}

.itm-child {
    display: flex;
    flex-direction: column;
}

.user_Profile_Pic_Container {
    margin: 0 0 0.5rem;
    position: relative;
}


.user_Profile_Pic {
    max-height: 130px;
    max-width: 130px;
    aspect-ratio: 1;
}

.user_Profile_Pic img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    min-width: 120px;
    object-fit: cover;
}

.user_Camera_Icon {
    max-width: 2rem;
    max-height: 2rem;
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
}

.user_Camera_Icon:where(:hover, :focus-visible) {
    scale: 1.2;
    cursor: pointer;
}


@media screen and (max-width: 520px) {
    .userName {
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        color: #000000;
    }

    .user_Profile_Pic img {
        min-width: 60px;
    }

    .divider {
        width: 0px;
        height: 70px;
        margin-top: 5%;
        border: 1px solid #000000;
    }
}