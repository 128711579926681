.findStoreContainer {
    width: 100%;
    padding: 20px 100px;
    font-family: "Quicksand";
    font-style: normal;
    margin-top: var(--pageMarginTop);
  }
  
  .findStoreTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }
  
  /* Section Ends  */
  
  .findStoreMapSearchContainer {
    width: 410px;
    margin-bottom: 40px;
  }
  
  .findStoreMapTitle {
    font-weight: 700;
    font-size: 37px;
    line-height: 43px;
    color: #000000;
    margin-top: 40px;
  }

  .findStoreMapTitle span {
    text-transform: capitalize;
  }
  
  .findStoreMapInputTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    margin-top: 20px;
  }
  
  .findStoreMapSearch {
    display: flex;
    border: 1px solid #bebebe;
    padding: 5px 10px;
    border-radius: 6px;
  }
  
  .findStoreMapSearchIcon {
    margin-right: 10px;
    font-weight: 300;
    font-size: 1rem;
    color: #7F7F7F;
  }
  
  .findStoreMapSearchbar {
    outline: none;
    border: none;
    width: 390px;
  }
  .findStoreMapSearchbar::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }
  
  /* Section Ends  */
  
  .findStoreContactHeader {
    margin-top: 20px;
    display: flex;
  
    gap: 20px;
    flex-direction: column;
  }
  
  .findStoreContactBurgerIcon {
    height: 32px;
    width: 32px;
  }
  
  .findStoreContactHeaderTitle,
  .findStoreContactPersonName {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    margin-top: 2px;
    color: #000000;
  }
  
  .findStoreContactPersonAddress {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #707070;
    width: 500px;
  }
  
  .findStoreContactPersonIcon {
    height: 26px;
    width: 26px;
  }
  .findStoreContactPersonNumber {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0066c0;
  }
  
  .findStoreContactPersonPhone {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 500px) {
    .findStoreContainer {
      padding: 20px 20px;
    }
  
    .findStoreTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #000000;
    }
  
    /* Section Ends  */
  
    .findStoreMapSearch {
      padding: 1px 6px;
    }
    .findStoreMapSearchIcon {
      margin-right: 5px;
      font-weight: 300;
      font-size: 0.4rem;
    }
    .findStoreMapSearchContainer {
      width: 300px;
    }
    .findStoreMapSearchbar {
      width: 290px;
    }
    .findStoreMapSearchbar::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #7f7f7f;
    }
  
    /* Section Ends  */
    .findStoreContactHeaderTitle,
    .findStoreContactPersonName {
      font-size: 25px;
      line-height: 50px;
    }
  
    .findStoreContactPersonAddress {
      font-weight: 600;
      font-size: 16px;
      width: 330px;
    }
  
    .findStoreContactPersonIcon {
      height: 21px;
      width: 21px;
    }
    .findStoreContactPersonNumber {
      font-size: 18px;
      line-height: 28px;
    }
  
    .findStoreContactPersonPhone {
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
  }