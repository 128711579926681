.form-input {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 10px;
  height: 48px;
  max-width: 400px;
  font-family: "Montserrat";
  margin-bottom: 10px;
  transition: 0.1s;
  border-radius: 10px;
}
.form-input.invalid {
  border: #ff1200f5 2px solid;
  color: #cc1507f5;
}
.form-input.invalid::placeholder {
  color: #cc14078f !important;
}
.form-input:focus-within {
  outline: none;
}
.form-input::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #8d9091;
}
.form-input:disabled {
  cursor: not-allowed;
}

.chart-container {
  margin-top: 30px;
}

.primary-btn {
  outline: none;
  margin-left: 15px;
  padding: 6px 13px;
  border: none;
  border-radius: 20px;
  background-color: #7336cfb5;
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
}
.primary-btn:hover {
  background-color: #7336cfd8;
}
.secondary-btn {
  outline: none;
  /* margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  background-color: #3692cfbd;
  color: white;
  font-family: "Montserrat";
  font-size: 13px;
}
.secondary-btn:hover {
  background-color: rgba(54, 174, 207, 0.827);
}

.date-picker-container {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 30px;
  font-family: "Montserrat";
}
.date-picker-element {
  display: flex;
  align-items: center;
  gap: 10px;
}
.date-picker-element h6 {
  font-size: 14px;
  width: 120px;
  margin: 0;
}
.date-picker-element input {
  padding: 3px 8px;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
  border-radius: 10px;
}

.chart-wrapper {
  max-width: 1200px;
  margin-top: 20px;
}

@media screen and (max-width: 700px) {
  .date-picker-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
