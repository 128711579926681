@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap");
@font-face {
    font-family: "Quicksand", sans-serif !important;
    src: url(/src/assets/fonts/NeoSansProRegular.ttf);
    unicode-range: U+0964-0965, U+0C82-0CF2, U+200C-200D, U+20B9, U+25CC;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --headerSearchHeight: 35px;
  --paymentPageLeft: 6rem;
  --pageMarginTop: 6.3rem;
  --primaryYellow: #FFCC0A;
  --primaryDarkBlue: #1b325e;
}

/* Mobile */
@media screen and (max-width: 1000px) {
  :root {
    --pageMarginTop: 5.3rem;
  }
}

/* @media (min-width: 501px ) and (max-width: 1000px) {
  :root {
    --pageMarginTop: 5.3rem;
  }
} */

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.relative {
  position: relative;
}

.signUpParentClass{
  display: flex;
  flex-direction: column;
  gap: 30px;
  background-size: cover;
  border-radius: 10px;
  margin-top: 97px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 48px;
}

.signup-wrapper {
  backdrop-filter: blur(50px);
  background: linear-gradient(180deg, #F3F6FF 0%, rgba(243, 246, 255, 0) 100%);
  border-radius: 10px;
  padding: 48px 1.3rem 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  width: 100%;
  height: 100%;
  position: relative;
}

.page-heading {
  /* font-size: 20px;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #1b325e; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #000000;
}

.page-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3d5176;
}

.input-field {
  padding: 16px;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: white;
  width: 100%;
}

.input-field::placeholder {
  color: #8d9091;
}

.submit-button {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 328px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
}

.submit-button p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.submit_Button_2 {
  width: 100%;
}

.submit_Button_2 .submit-button {
  background: #dde0e7;
  box-shadow: none;
}

.submit_Button_2 .submit-button p {
  color: #1b325e;
}

.submit_Button_3 {
  width: 100%;
  text-decoration: none;
}

.submit_Button_3 .submit-button {
  background: #f8f9fa;
  box-shadow: none;
}

.submit_Button_3 .submit-button p {
  color: #1b325e;
}

.extra-btn {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #1b325e;
  cursor: pointer;
  text-decoration: none;
}

.extra-btn:hover {
  color: #1b325e;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.signup-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.inputfield-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.inputField {
  position: relative;
}

.button-Container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
}

.tc-Footer {
  width: 100%;
  /* position: fixed;
  bottom: 0; */
  /* margin-bottom: 12px; */
  margin: 96px 0 0;
}

.footer-Text {
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: #828ea6;
}

.footer-Text span {
  font-weight: 600;
  color: #1b325e;
  cursor: pointer;
}

/* input[type='date']::before{
  color: lightgray;
  content: attr(placeholder) !important;
}

input[type='date']:focus::before{
  content: '' !important;
} */

.resend-btn {
  color: rgb(0, 102, 192);
  letter-spacing: -0.5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: right;
  background: transparent;
  border: none;
  outline: none;
  opacity: 1 !important;
  width: fit-content;
  align-self: flex-end;
}

.btn-disable {
  pointer-events: none !important;
}

.homepage_wrapper {
  margin-top: var(--pageMarginTop);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  max-width: 1920px;
}

.page_Margin_Top {
  margin: 14.125rem 0 0;
}

.page_Wrapper {
  margin-top: var(--pageMarginTop)!important;
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
} 

.page_Wrapper:not(:has(.new_Filter_Show)) .new_Filter_Container {
  display: none;
}

.empty_order_sec {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding: 80px 0;
  margin: 1rem 0 1rem;
}

.not_found_wrapper{
  margin-top: var(--pageMarginTop);
}

.empty_order_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

input,
textarea {
  border-radius: 0.25rem;
  background: #ffffff;
  border: 0.063rem solid #efefef;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: 100%;
}

input::placeholder,
textarea::placeholder {
  color: #8d9091;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.mobile_None {
  display: none;
}

.custom-date-picker {
  display: block !important;
}

.custom-date-picker input {
  /* padding: 16px;  */
  width: 100%;
  text-align: left;
}

.custom-date-picker .react-date-picker__wrapper {
  border: none;
  margin-top: -11px;
}

.custom-date-picker .react-date-picker__calendar {
  z-index: 1000;
}

.floating_Footer {
  height: 64px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
}

.floating_Footer_Wrapper {
  display: flex;
  gap: 0.75rem;
  align-items: center;
}

.floating_Footer_Left,
.floating_Footer_Right {
  width: 45%;
}

.floating_Footer_Center {
  width: 100%;
}
.scroll_Buffer {
  min-width: 1px;
  min-height: 50px;
  margin: 0 0px 0 0;
}

.tab_Display_Block {
  display: none !important;
}

.text4_xl {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

#head-bar-sugges {
  width: 70%;
  margin-left: 1rem;
  height: 1px;
  border: 2px solid #eeeeee;
  background: #eeeeee;
}

@media screen and (min-width: 768px) {
  .submit-button {
    max-width: 552px;
  }

  .page_Wrapper {
    margin: 0;
    padding: 0 0.8rem 0.75rem;
  }

  .section_Wrapper {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
    background: #ffffff;
    border: 1px solid #dde0e7;
  }

  .tab_None {
    display: none !important;
  }
  .tab_Display_Block {
    display: block !important;
  }

  .tab_Display_Flex {
    display: flex !important;
  }

  .page_Margin_Top {
    margin: 8.75rem 0 0;
  }

  .floating_Footer {
    display: none;
  }

  .text4_xl {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
  }  
  
  .signUpParentClass{
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-size: cover;
    border-radius: 10px;
    margin-top: 148px;
  }
}

@media screen and (min-width: 850px) {
  .page_Wrapper {
    padding: 0 1.5rem 0.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .signup-wrapper {
    max-width: 360px;
    border-radius: 10px;
    height: fit-content;
    backdrop-filter: blur(50px);
    background: linear-gradient(180deg, #F3F6FF 0%, rgba(243, 246, 255, 0) 100%);
    box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
  }

  .page_Wrapper {
    padding: 0 2.5rem 1.25rem;
  }
}

@media screen and (min-width: 1400px) {
  .page_Wrapper {
    padding: 0 3.75rem 1.5rem;
  }
}

/* ---- Radio Btn ---- */
.radiobtn-label {
  display: flex;
  align-items: center;
  height: 18px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 1.875rem;
}
.radiobtn-label input[type="radio"] {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}
.radiobtn-label .radio-custom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #98a3b7;
  border-radius: 50%;
  box-sizing: border-box;
}
.radiobtn-label .radio-custom:after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1b325e;
}
.radiobtn-label input[type="radio"]:checked + .radio-custom {
  border-color: #1b325e;
}
.radiobtn-label input[type="radio"]:checked + .radio-custom:after {
  display: block;
}

.radiobtn-label input[type="radio"]:checked ~ .radiobtn-label {
  z-index: 100;
}

/* ---- Check Box ---- */
.checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 2rem;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.checkbox-label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 24px;
  height: 24px;
  border: 1px solid #1b325e;
  border-radius: 4px;
  background-color: #ffff;
}
.custom-checkmark:after {
  content: "";
  position: absolute;
  /* display: none; */
  opacity: 0;
  top: 6px;
  left: 9px;
  height: 9px;
  width: 4.5px;
  border: 2px solid #1b325e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  transition: 0.3s ease;
}
.checkbox-label input:checked ~ .custom-checkmark {
  background-color: #ffffff;
}
.checkbox-label input:checked ~ .custom-checkmark::after {
  /* display: block; */
  opacity: 1;
}
.react-parallax-bgimage {
  height: 100% !important;
  /* width: 100% !important; */
  object-fit: contain !important;
}
input.numeric-text::-webkit-outer-spin-button,
input.numeric-text::-webkit-inner-spin-button {
    display:none;
}

.custom-fileSelect {
  display: inline-block;
  background-color: #1b325e;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  width: 30%;
  text-align: center;
} 


video {
  width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.required_field:invalid {
  outline: 1px solid red;
}
.required_field:valid {
  outline: 2px solid green;
}

.accordion-header button {
  text-transform: capitalize;
}

.yellow-theme-btn {
  border-radius: 0 0 0 1rem;
  background: #ffcc0a;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 200px;
  width: 70%;
  height: 70%;
  outline: none;
  cursor: pointer;
  border: none;
  transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
}

.text-xs {
  font-size: 12px;
}

.text-link {
  color: #3581C1;
  font-weight: 500;
}

.SignUpInputLable{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.input-field {
  padding: 16px;
  height: 15px;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: white;
  width: 100%;
}

.input-field::placeholder {
  color: #8d9091;
}

.TCStyle{
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.socialParent{
  display: flex;
  flex-direction: row;
  gap: 1px;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  background-color: #34509E;
  border-radius: 11px
}

.socialImage{
  width: 22%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width : 600px) {
  .page_Wrapper{
    margin-top: 0;
  }
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 1rem;
}

.admin_required_field:where(:focus, :focus-visible):invalid {
  outline: 1px solid red;
}
.admin_required_field:valid {
  outline: 1px solid green;
}

.normal-link {
  text-decoration: none;
}

.normal-link:hover {
  color: inherit;
}