.product_Section_1 {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.product_Section_1 .product_Name {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.product_Section_1 .product_Preview_Section {
  margin: 1rem 1.5rem 2.5rem;
}

.product_Section_1 .product_Preview_Section .product_Carousel_Image {
  max-width: 280px;
  max-height: 280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.product_Section_1 .product_Preview_Section .carousel .carousel-indicators {
  bottom: -30px;
}

.product_Delivery_Section {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.product_Alternate_Section {
  margin: 0 0 0.5rem;
  background: #ffffff;
}

.product_Section_1 .product_Price_Desc {
  display: flex;
  gap: 0.625rem;
  align-items: center;
  margin: 0 0 0.375rem;
}

.product_Section_1 .product_Price_Desc .product_Discount_Price {
  color: #08090a;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin: 0;
}

.product_Section_1 .product_Price_Desc .product_Original_Price {
  color: #6d747a;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-decoration: line-through;
  margin: 0;
}

.product_Section_1 .product_Price_Desc .product_Availability,
.product_Section_1 .product_Offer_Counter p,
.product_Discount {
  letter-spacing: -0.015em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.product_Alternate_Section .accordion .accordion-item {
  border: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button {
  padding: 1rem 0.75rem 1rem 1rem;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button p {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: #08090a;
  letter-spacing: -0.01em;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button p span {
  font-weight: 600;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: #ffffff;
  box-shadow: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
  filter: invert(1) contrast(0) saturate(0) brightness(0.5);
}

.product_Delivery_Details {
  margin: 0 0 1rem;
}

.product_Delivery_Details p {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.product_Delivery_Details p span {
  font-weight: 600;
}

.product_Page_Availability {
  margin: 0 0 1rem;
}

.product_Page_Availability p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0;
}

.product_Delivery_Footer {
  padding: 1.5rem 0 0;
  border-top: 1px solid #ebedf0;
}

.product_Section_Heading {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.key_feature_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  color: #000000;
}

.KeyStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}

.product_Offer_Section {
  padding: 1rem;
  background: #ffffff;
  height: auto;
  margin: 0 0 0.5rem;
}

.product_Offer_Header {
  display: flex;
  justify-content: flex-start;
  gap: 0.375rem;
}

.product_Offer_Cards_Container {
  max-width: 100%;
  min-height: 105px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 1000px;
  padding-bottom: 0.625rem;
  cursor: auto;
}

.product_Offer_Cards_Wrapper {
  display: flex;
  gap: 0.5rem;
  white-space: nowrap;
  padding: 0 0 10px;
}

.product_Offer_Header img {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.product_Description_Section {
  padding: 1rem 0;
  background: #ffffff;
  height: auto;
  margin: 0 0 0.5rem;
}

.product_Description_Header {
  margin: 0 1rem 0.75rem;
}

.description_See_Less,
.description_See_More {
  letter-spacing: -0.015em;
  color: var(--primaryDarkBlue);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
  margin: 0;
}

.description_See_More {
  text-align: right;
  margin: 0 1rem 0 0;
}

.description_See_More.description_Expanded {
  visibility: hidden;
}

.product_Description_Wrapper {
  gap: 0.5rem;
  margin: 0;
  padding: 0 1rem 0 2rem;
  max-height: 100px;
  height: 100%;
  overflow-y: hidden;
}

.product_Description_Wrapper.description_Expanded {
  max-height: max-content;
}

.product_Description {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.productPage_Table {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.productPage_Table h5 {
  margin: 0 0 1rem;
}

.productPage_Image_Gallery {
  background: #ffffff;
  padding: 1rem;
}

.productPage_Image_Gallery h5 {
  margin: 0 0 0.75rem;
}

.image_Gallery_Wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.product_Gallery_Image {
  width: 100%;
  height: 100%;
}

.product_Page_Floating_Wrapper {
  justify-content: center;
}

.product_Page_Floating_Wrapper .floating_Footer_Left {
  text-align: center;
}

.floater_Add_Cart {
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--primaryDarkBlue) !important;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.floater_Add_Cart:hover {
  color: var(--primaryDarkBlue) !important;
}

.slug-URL {
  /* font-family: Quicksand; */
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.tagList {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-res-style,
.btn-res-style:hover {
  background: #FFCC0A;
  /* border: 1px solid #FFCC0A; */
  font-size: 10px;
}

.product-child-1 {
  /* width: 100% !important; */
  order: 2;
}

.product-child-2 {
  order: 1;
  width: 100% !important;
}

.product-child-3 {
  order: 3;
  width: 100% !important;
}

.add_to_wishlist_icon {
  color: #5A99CD;
  background: #F8F8F8;
  height: 30px !important;
  width: 30px !important;
  padding: 2%;
  border-radius: 10px;
}

.product_share_icon {
  color: #5A99CD;
  background: #F8F8F8;
  height: 30px !important;
  width: 30px !important;
  padding: 6%;
  border-radius: 10px;
}

.productTag {
  width: 48%;
  height: 40px;
  background: rgb(248, 249, 250);
  border: 1px solid rgb(202, 204, 209);
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  border-radius: 10px;
  color: var(--primaryDarkBlue);
}

.product-flex-container {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 3.5rem;
  align-items: center;
}

.discountOffer {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 20px;
  color: #3581c1;
}

.netPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #241a6b;
}

.realPrice {
  font-weight: 700;
  font-size: 1.01rem;
  line-height: 40px;
  color: #000000;
}

.Condition {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.reviewHead {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  display: flex;
  gap: 4px;
  scroll-margin-top: 9rem;
  margin-right: 1rem;
}

.review-child-1 {
  width: 100%;
  order: 1;
}

.review-child-2 {
  width: 100%;
  order: 2;
}

.product-desc {
  font-family: Quicksand;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.desc-readMore {
  color: #000000;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.product-desc span:where(:hover, :focus-visible) {
  color: var(--primaryDarkBlue);
  font-weight: bolder;
}

.offername {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  align-items: center;
  display: flex;
  max-width: 118px;
}

.bank_image {
  width: 80px;
  height: 24px;
}

.services-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}

.deal-end-text {
  padding-left: 14px;
  font-weight: 700;
  font-size: 16px;
}

.combo-offer-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #241A6B;
  text-transform: uppercase;
}

.combo-image-wrapper {
  position: relative;
  max-width: 146px;
  max-height: 146px;
  height: 100%;
  width: 100%;
}

.combo-image-wrapper img {
  max-width: 146px;
  max-height: 146px;
  height: 100%;
  width: 100%;
}

.combo-checkbox {
  position: absolute;
  top: 8px;
  width: auto;
  right: 8px;
}

.combo-product-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.combo-free-text {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;

  color: #241A6B;
}

.combo-product-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #000000;
}

.offer-lasts {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #241A6B;
}

.offer-time-wrapper {
  background-color: #FFF0BF;
  ;
}

.offer-time-number {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--primaryDarkBlue);
}

.offer-time-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: var(--primaryDarkBlue);
}

.pincode-submit-btn {
  background-color: var(--primaryDarkBlue) !important;
}

.color-product-box {
  width: 16%;
  /* height: 69px; */
  border: 1px solid #CACCD1;
  border-radius: 10px;
}

.color-product-box:hover,
.color-product-box:focus-visible {
  scale: 1.1;
  border: 3px solid #29292b;
  transition: scale 300ms ease;
}

.specs-product-box {
  font-size: 0.85rem;
  border-width: 2px!important;
  border-color: var(--primaryDarkBlue)!important;
  padding: 0.5rem 1.5rem;
  width: unset;
  height: unset;
}

.specs-product-box:hover,
.specs-product-box:focus-visible {
  scale: 1.1;
  border: 1.25px solid var(--primaryYellow);
  background-color: #ffce0a42;
  transition: scale 300ms ease;
}

@media screen and (max-width: 768px) {
  .slug-URL-Container span {
    font-size: 10px;
  }

  .specs-section-wrapper {
    margin-top: 2%!important;
  }

  .color-product-box {
    height: auto;
  }

  .specs-product-box {
    padding: 0.15rem 0.75rem;
    font-size: 0.75rem;
  }

}

.slug-URL-Container {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .discountOffer {
    font-size: 34px;
  }

  .netPrice {
    font-size: 30px;
  }

  .realPrice {
    font-size: 1rem;
  }

  .key_feature_heading {
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .product_Section_Heading_empty_div {
    width: 45% !important;
  }

  .sub-head-cart1 {
    margin-right: 0 !important;
    width: auto !important;
  }

}

@media screen and (min-width: 768px) {
  .product_Section_1 .product_Preview_Section {
    display: none;
  }

  .product_Side_Section {
    padding: 1rem;
    background: transparent;
    width: 36vw;
    max-width: none;
    gap: 1.5rem;
    border: none;
    box-shadow: none;
  }

  .product_Side_Section .image_Preview_Side_Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
  }

  .product_Side_Section .image_Preview_Selected {
    max-width: 327px;
    height: 100%;
    width: 100%;
    border-radius: 0.25rem;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
    cursor: pointer;
  }

  .product_Side_Section .image_Preview_Side_Section .image_Preview_Selected img {
    width: 100%;
    height: 100%;
    /* max-width: 327px; */
    /* max-height: 327px; */
    object-fit: contain;
  }

  .cursor-zoom-in {
    cursor: zoom-in !important;
  }

  .image_Preview_Side_Section .product_Thumbnails {
    display: flex;
    gap: 0.375rem;
    justify-content: center;
    align-items: center;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail {
    max-width: 72px;
    max-height: 72px;
    height: 100%;
    width: 100%;
    border: 1px solid #dde0e7;
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail img {
    width: 100%;
    height: 100%;
    max-height: 72px;
    max-height: 72px;
    object-fit: contain;
  }

  .product_Side_Section_Buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .button_Set_2 {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .button_Set_2 div {
    width: 50%;
  }

  .button_Set_2 .submit_Button_1 {
    width: 100%;
  }

  .product_Page_Right {
    width: 56vw;
  }

  .product_Section_1 .product_Name {
    margin: 0 0 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
  }

  .product_Section_1 .product_Price_Desc {
    gap: 1.125rem;
    margin: 0 0 0.75rem;
  }

  .product_Section_1 .product_Price_Desc .product_Original_Price,
  .product_Section_1 .product_Price_Desc .product_Availability,
  .product_Discount {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 500;
  }

  .product_Section_1 .product_Price_Desc .product_Original_Price,
  .product_Discount {
    font-weight: 600;
  }

  .product_Section_1 .product_Offer_Counter p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .product_Section_1 .product_Offer_Section {
    padding: 1rem 0;
    margin: 1rem 0 0;
  }

  .product_Section_1 .product_Offer_Section .product_Offer_Header {
    margin: 0 0 1rem;
  }

  .product_Description_Section {
    margin: 0 0 0.75rem;
    border-radius: 0.25rem;
  }

  .product_Description_Header {
    margin: 0 1rem 1rem;
  }

  .productPage_Table {
    margin: 0 0 1rem;
  }

  .productPage_Image_Gallery h5 {
    margin: 0 0 1rem;
  }

  .image_Gallery_Wrapper {
    gap: 0.5rem;
  }

  .product_Delivery_Section {
    padding: 0.875rem 1rem;
    border-radius: 0.25rem;
  }

  .product_Delivery_Details {
    margin: 0;
  }

  .product_Delivery_Details .product_Delivery_Pin-form {
    display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Pincode_Input {
    width: 65%;
    margin: 0 30px 0 0;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Submit_Btn {
    width: 100%;
    max-width: 160px;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Submit_Btn p {
    color: #ffffff;
  }

  .product_Alternate_Section {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .product_Alternate_Section_Header {
    margin: 0 0 0.75rem;
  }

  .product_Alternate_Section_Header p,
  .product_Alternate_Section p span {
    margin: 0;
    color: #08090a;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .product_Alternate_Section p span {
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  .product_Alternate_Section_Body {
    display: flex;
    gap: 0.75rem;
    flex-flow: wrap;
    margin: 0 0 2rem;
  }

  .product_Alternate_Section_Footer {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 0.75rem;
  }

  .product_Alternate_Section_Footer p {
    color: #08090a;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .product_Alternate_Section_Footer p span {
    letter-spacing: -0.01em;
    font-weight: 600;
    margin: 0;
  }

  .product_Alternate_Footer_Cards {
    display: flex;
    gap: 0.75rem;
  }
}

@media screen and (min-width: 1200px) {
  .product_Side_Section {
    padding: 1.2rem;
    gap: 1rem;
  }

  .product_Side_Section .image_Preview_Selected {
    max-width: 650px;
    max-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product_Side_Section .image_Preview_Side_Section .image_Preview_Selected img {
    /* max-width: 650px; */
    /* max-height: 650px; */
    margin: 0 auto;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail {
    max-width: 60px;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail img {
    max-width: 144px;
    max-height: 144px;
    width: 100%;
    height: 100%;
  }

  .product_Description_Section {
    padding: 1.5rem 0;
    margin: 0 0 0.5rem;
  }

  .product_Description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .description_See_More,
  .description_See_Less {
    font-size: 1rem;
  }

  .productPage_Table {
    padding: 1.5rem;
  }

  .productPage_Image_Gallery {
    padding: 1.5rem;
  }

  .product_Delivery_Section {
    padding: 1.5rem;
  }

  .product_Alternate_Section {
    padding: 1.5rem;
  }

  .product_Alternate_Section_Body {
    margin: 0 0 1.5rem;
    gap: 1.125rem;
  }

  .product-child-1 {
    order: 1;
    /* width: 30% !important; */
  }

  .product-child-2 {
    order: 2;
    width: 30% !important;
  }

  .product-child-3 {
    order: 3;
    width: 33% !important;
  }

  .product-flex-container {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    align-items: flex-start;
  }

  .review-child-2 {
    width: 49%;
    order: 2;
  }
}

.slug-URL {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.rws-container {
  min-width: auto;
}

.tabular-data {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex-basis: 33.33%;
  display: flex;
}

.column:not(:last-child)::after {
  content: "";
  background-color: #000;
  width: 1px;
  height: 100px;
  display: block;
  margin: auto 20px;
}

.column:not(:first-child) {
  padding-left: 16px;
}

.column .column-inner:first-child {
  flex-basis: auto;
  flex-wrap: nowrap;
}

.column .column-inner:last-child {
  flex-grow: 1;
  padding-left: 0.5rem;
}

.modalImageContainer {
  display: flex;
  padding: 20px 10px;
}

.modalImage {
  width: 100%;
  height: 100%;
}