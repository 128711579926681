.privacy-policy-container {
    width: 80vw;
    margin-top: var(--pageMarginTop);
    margin-bottom: 2rem;
}
.privacy-policy-container p {
    margin-bottom: 0!important;
}

.privacy-policy-container p > span {
    font-weight: bold;
    color: black;
}
.privacy-policy-container ol {
    list-style-position: inside;
}
.privacy-policy-container ol li::marker {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.756);
}

.secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 10px auto;
    width: 70%;
    gap: 20px;
    text-align: justify !important;
    color: #707070 !important;
}

.secondSectionHeader {
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .privacy-policy-container {
        width: 90vw;
    }
    .secondSection {
        width: 100%;
    }
}

