.cart_Product_Contianer {
  padding: 1rem 0.75rem 0.125rem;
  background: #ffffff;
}

.cart_Product_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0.25rem 1rem;
  border-bottom: 0.063rem solid #ebedf0;
}

.product_Details.cart_product_card {
  max-width: 230px;
}

.cart_Product_Left {
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
  margin: 0 0 1rem;
}
.cart_Product_Left a {
  text-decoration: none;
}
.cart_Product_Name {
  line-height: 1.25rem;
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Color {
  letter-spacing: 0.004em;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0 0 0.625rem;
}

.cart_Product_Price_Section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.cart_Product_Discount_Price {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.cart_Product_Original_Price {
  color: #6d747a;
  text-decoration-line: line-through;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Discount {
  letter-spacing: -0.01em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.cart_Product_Offers {
  letter-spacing: 0.004em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Delivery_Info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.375rem;
  flex: 1;
}

.cart_Product_Delivery_Estimate {
  letter-spacing: 0.004em;
  color: #08090a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Delivery_Charge {
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Availability {
  letter-spacing: 0.004em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Card_Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 140px;
}

.cart_Product_Image_Container {
  max-width: 122px;
  max-height: 122px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cart_Product_Image_Container img {
  width: 100%;
  height: 100%;
}

.cart_Product_Counter_Container {
  max-width: 122px;
  max-height: 40px;
  border: 0.063rem solid #08090a;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.counter_Icon_Container {
  padding: 0.625rem;
  cursor: pointer;
}

.counter_Icon_Container:first-child {
  border-right: 0.063rem solid #08090a;
}

.counter_Icon_Container:last-child {
  border-left: 0.063rem solid #08090a;
}

.counter_Icon_Container img {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.cart_Product_Counter {
  margin: 0;
  letter-spacing: -0.01em;
  color: #1b325e;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.5rem 1rem;
}

.combined_Button_Container {
  padding: 0.125rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.combined_Button_Container img {
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.combined_Button_One,
.combined_Button_Two {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.875rem 0.625rem 1.125rem;
  gap: 0.25rem;
  width: 50%;
  cursor: pointer;
}

.combined_Button_One p,
.combined_Button_Two p {
  margin: 0;
  color: #3d5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.combined_Button_One {
  border-right: 0.063rem solid #ebedf0;
}

.color_Red {
  color: #c5292a;
}

.cart_Product_Details {
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .cart_Product_Name {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 30ch;
    margin: 0 0 0.125rem;
  }

  .cart_Product_Color {
    letter-spacing: 0.004em;
    margin: 0;
  }

  .cart_Product_Price_Section {
    margin: 1rem 0 0;
    gap: 0.875rem;
  }

  .cart_Product_Discount_Price,
  .cart_Product_Original_Price {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cart_Product_Offers {
    margin: 0 0 1rem;
  }

  .product_Details.cart_product_card {
    /* justify-content: flex-start; */
    gap: 1.5rem;
    width: 75%;
    max-width: 100%;
  }

  .combined_Button_Container img {
    max-width: 20px;
    max-height: 20px;
  }

  .combined_Button_One p,
  .combined_Button_Two p {
    letter-spacing: -0.015em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.015em;
  }

  .cart_Product_Left {
    flex: 1;
    margin: 0;
  }
}

.cart-banner {
  display: flex;
  /* border: 1px solid yellowgreen; */
  justify-content: space-evenly;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0066c0;
  margin-top: 10rem;
  margin-bottom: 3rem;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
}

.cart-banner-item {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  color: #0066c0!important;
  text-decoration: none!important;
}

@media screen and (max-width: 520px) {
  .cart-banner {
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .cart-banner-item {
    width: 40%;
  }

  .cart-banner-item span {
    font-size: 10px;
    line-height: 14px;
  }
  .cart-banner-item span:last-child {
    font-size: 11px;
    line-height: 14px;
  }

}

@media screen and (min-width: 1024px) {
  .cart_Product_Wrapper {
    padding: 1rem 1rem 0.25rem;
  }

  .cart_Product_Wrapper {
    padding: 0 0.5rem 1.625rem;
  }

  .cart_Product_Name {
    font-size: 1.25rem;
    line-height: 1.75rem;
    max-width: 100%;
    margin: 0 0 0.25rem;
  }

  .cart_Product_Color {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cart_Product_Discount_Price,
  .cart_Product_Original_Price,
  .cart_Product_Discount {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cart_Product_Delivery_Estimate {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #08090a;
  }

  .cart_Product_Offers,
  .cart_Product_Availability {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .combined_Button_Container {
    gap: 0.375rem;
    padding: 0.25rem 0 0;
  }

  .combined_Button_One,
  .combined_Button_Two {
    padding: 0.875rem 1.875rem 0.625rem 1.125rem;
  }

  .combined_Button_Container img {
    max-width: 24px;
    max-height: 24px;
  }
}

#sub-banner1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: unset;
}

.crt-banenr-center {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
}

.icn-sz {
  width: 35px;
  height: 35px;
  /* padding: 1rem; */
  margin-right: 10px;
}
.itm-child {
  display: flex;
  flex-direction: column;
}