/* styles.css */
@media (min-width: 600px) {
    .product-box-skeleton .skeleton-item {
      width: 320px;
    }
    .skeleton-item-child-1 {
        width: 280px;
    }
  }
  
  @media (max-width: 599px) {
    .product-box-skeleton {
        height: 250px;
    }
    .product-box-skeleton .skeleton-item {
      width: 200px;
    }
    .skeleton-item-child-1 {
        width: 150px;
    }
  }
  