.your-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .your-form {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: grid;
    place-content: center;
  }
  
  .your-form .MuiTextField-root {
    margin-bottom: 20px;
  }
  
  .your-form .submit-button {
    width: 100%;
    margin-top: 20px;
  }