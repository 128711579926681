.logoModalContainer {
  padding: 20px 60px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: rgba(255, 255, 255, .7);
}

.logoModalContainer::before {
  content: "";
  position: absolute;
  display: block;
  backdrop-filter: blur(15px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  z-index: -1;
}

.logoModalItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 70px;
}

.logoModalItems>img {
  width: 60px;
  height: 50px;
}

.logoModalItems>img:nth-child(2) {
  width: 90px;
  height: 50px;
}

.logoModalItems>img:where(:hover, :focus-visible) {
  scale: 1.1;
  filter: drop-shadow(2px 4px 6px rgb(108, 107, 107));
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .logoModalContainer {
    padding: 0.75rem;
    gap: 0.7rem;
  }

  .logoModalItems>img {
    width: 1.45rem;
    aspect-ratio: 1/1;
  }

  .css-1wy80ie {
    width: 50vw !important;
  }

  .logoModalItems {
    gap: 1.5rem;
  }

  .logoModalItems>img:nth-child(2) {
    width: 3.6rem;
  }

}

@media screen and (max-width: 650px) {
  .logoModalItems>img {
    width: 33px;
    height: 33px;
  }
  .logoModalItems>img:nth-child(2) {
    width: 58px;
    height: 36px;
  }

  div[data-sequence^="sequence"] {
    width: 60vw!important;
  }
  .dropDownContainer {
    left: 10px;
  }
}