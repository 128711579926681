.desk_Page_Wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 0;
  gap: 1.5rem;
}

.order_Text{
  padding: 0 1rem;
  color: #6D747A;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.order_arriving_section, .order_delivered_section{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.order_delivered_section{
  padding: 1rem 0 0;
}

.order_delivered_section{
  margin: 0 0 1rem;
}

.side_Section{
  display: none;
}


@media screen and (min-width: 768px) {

  .desk_Page_Wrapper{
    flex-direction: row;
    gap: 1rem;
  }

  .order_Text{
    padding: 0.875rem 1rem;
  }

  .side_Section{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-width: 210px;
    max-width: 320px;
    max-height: fit-content;
    width: 25vw;
    height: max-content;
  }

  .side_Section_Heading{
    padding: 0 0 1rem;
    border-bottom: 1px solid #EBEDF0;
    margin: 0 0 1rem;
    color: #08090A;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  .side_Section_Checkbox:first-of-type, .side_Section_Checkbox:nth-of-type(2){
    margin-bottom: 0.75rem;
  }

  .order_Page_Right{
    width: 70vw;
  }

  .side_Section .cart_Add_Items{
    margin: 1.5rem 0 0;
    padding: 0 1rem;
  }

  .side_Section .cart_Add_Items .add_Items_Wrapper{
    padding: 1rem 0;
  }

  .side_Section .cart_Add_Items .cart_More_Items{
    padding: 0.875rem 0;
  }

  .order_Page_Right_Wrapper{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 0;
  }
}

@media  screen and (min-width: 850px) {
  .desk_Page_Wrapper{
    gap: 1.5rem;
  }

  .side_Section{
    width: 24vw;
  }

  .order_Page_Right{
    width: 69vw;
  }
}

@media screen and (min-width: 1200px) {
  .order_Text{
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #08090A;
  }
}

.order-Filter-Profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  background-color: rgb(255 255 255 / 100%);
  cursor: pointer;
  gap: 2px;
}

.order-Filter-Profile > div:first-child {
  border-right: 1px solid #2b3953;
}

.order-Filter-Profile > div {
  padding: 7px 0;
}

.order-Filter-Profile > div:where(:hover, :focus-visible) {
  transition: all 0.2s ease-in-out;
  background-color: #1b325e;
  color: white;
}

.order-container {
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 0.25rem;
  background: #ffffff;
  border: 1px solid #dde0e7;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
}

.order-body {
  display: flex;
}

.order-body > * {
  flex-basis: 100%;
  padding: 0.875rem 1rem;
}

.order-footer {
  text-align: center;
  width: 92%;
  margin-inline: auto;
  padding-block: 0.8em;
  font-size: 0.8em;
  border-top: 1px solid #EBEDF0;
  cursor: pointer;
  transition: all 300ms ease-in;
  color: #1b325e;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.order-footer:where(:focus, :hover) {
  font-weight: 900;
}
.order-arrival {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-arrival > * {
  margin-bottom: 0;
}
.order-arrival-text {
  font-size: 1.3em;
  font-weight: 500;
}

.order-name {
  font-weight: 600;
  margin-bottom: 0;
}

.order-color {
  font-size: 0.8em;
  font-weight: 500;
}

.order-arrival-placed {
  font-size: 0.7em;
  font-weight: 500;
}

.order-image > img {
  width: 80%;
  
}

.order-price {
  align-self: flex-start;
  font-weight: 500;
}