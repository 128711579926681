.section2_container {
  padding: 0.75rem 0 0.75rem 1rem;
  width: 100%;
  background-color: #ffffff;
}

.section2_heading {
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.75rem;
}

.section2_card_container {
  max-width: 100%;
  min-height: 105px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 1000px;
  padding-bottom: 0.625rem;
  cursor: auto;
}

.section2_card_container::-webkit-scrollbar {
  display: none;
}

.section2_card_wrapper {
  display: flex;
  gap: 0.75rem;
  white-space: nowrap;
  padding: 0 0 0.625rem;
}

@media screen and (min-width: 768px) {
  .section2_container {
    padding: 18px 0 18px 24px;
  }

  .section2_heading {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 18px;
  }

  .section2_card_wrapper {
    gap: 1.125rem;
    padding: 0 0 1.5rem;
  }
}

@media screen and (min-width: 1400px) {
  .section2_container {
    padding: 24px 0 24px 60px;
  }

  .section2_heading {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 24px;
  }
}
