@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap");
.thankyou_container {
  display: flex;
  flex-direction: column;
  /* border: 10px solid red; */
  /* width: 100%; */
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* width: 80vw; */
  /* height: 90vh; */
  margin-top: 44px;
  padding: 20px;
}
/* .thankyou-sec-1 {
  display: none;

} */
.thankyou-sec-1 {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;
  align-items: center;
  flex-direction: column;
}
.thk-child {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thk-price {
  display: flex;
  flex-direction: column;
  border: 2px solid red;
}
.final_product_details {
  display: flex;
  /* width: 50%; */
  height: 100%;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
}
.product_final-img {
  width: 100%;
  display: flex;
  /* justify-content:  center; */
  align-items: center;
  /* height: 100%; */
}
.product_final-img img {
  /* border:1px solid black; */
  width: 150px;
  height: 100px;
  /* width: 200px; */
  /* height: 260px; */
}
.product_final__details {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
}

.product_final__details__desc{
  max-width: 350px;
  width: 100%;
  min-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.icn-color-thnk {
  color: #fda829;
}
.thk2 {
  /* border: 1px solid royalblue; */
  display: flex;
  flex-direction: column;
}
.thk2-sub {
  /* border: 1px solid sienna; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
}
.tick-thk {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tick-thk img {
  width: 30%;
  /* height: 70%; */
  margin-top: 17px;
  /* margin-left: 2rem; */
  margin-bottom: 1rem;
}
.thk-sub-content {
  padding: 0;
}
.thk2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 500px; */
  margin-top: 30px;
}
.color-font {
  font-size: 30px;
  color: #1b325e;
}
.color-font2 {
  color: #1b325e;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  margin-bottom: 10px;
}
.thk-sub-content {
  width: 100%;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#price-thk {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  /* identical to box height */

  text-decoration-line: line-through;

  color: #7f7f7f;
}
#price-thk2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 62px;
  /* identical to box height */

  color: #241a6b;
}
/* .thk-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

} */
.mobileCard {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  border-radius: 10px;
  /* background-color: rgb(214, 174, 174); */
}
.mobilecheckoutStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
}
.pay2 {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  /* line-height: 62px; */
  /* identical to box height */

  color: #241a6b;
}

@media screen and (min-width: 600px) {
  .mobileCard {
    display: none;
  }
  /* .thankyou-sec-1 {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    gap:20px;
    align-items: center;
    height: 188.33%;
    padding: 5rem 0rem 5rem 0rem;
    border: 1px solid fuchsia;
  } */
  .color-font2 {
    font-size: 27px;
    color: #1b325e;
  }
  .tick-thk img {
    width: 10%;
    /* height: 70%; */
    /* margin-left: 2rem; */
    margin-bottom: 1rem;
  }
  .thk-sub-content {
    padding: 0px 5rem 0px 5rem;
  }
  .thk-sub-content {
    width: 65%;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.product_finall{
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 90px; */
  max-width: 900px;
}
.product_final__details__name{
  font-size: 15px;
  font-weight: 700;
  color: #000000;
}

@media screen and (max-width: 500px) {
  .thankyou_container {
    padding: 0px 0px;
  }
  .hrThankyou {
    display: none;
  }
  .thankyou-sec-2{
    padding: 0px;
  }
  .product_finall{
    /* flex-direction: column; */
    flex-direction: row;
    gap: 2px;
  }
  .thankyou-sec-1{
    padding: 0 10px;
    margin-bottom: 10px;
  }
  #price-thk {
    font-size: 18px;
    line-height: 20px;
  }
  #price-thk2 {
    line-height: 32px;
  }
  .product_final__details__name{
    line-height: 2px;
  }

  .product_final__details__desc{
    min-width: auto;
  }
}

.order_proessing {
  height: 400px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.order_proessing img {
  width: 100px;
}
