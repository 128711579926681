.parentClassCustomerSupport{
    width: 100vw;
    padding: 19px;
  }

.header{
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 31px;

  color: #000000;
}
.HeadingText{
    font-size: 25px;
    margin-bottom: 20px;
}
.contactusMain{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactusMainsub{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0) 132.2%);
    border-radius: 10px;
    padding: 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 62px;
    color: #000000;
    border: 1px solid rgb(240, 231, 231);
}
.contactphonesection{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 35px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #0066C0;
}

@media screen and (min-width: 768px) {
    .parentClassCustomerSupport{
      width: 100vw;
      padding: 19px 153px;
    }
    .HeadingText{
        font-size: 25px;
    }
    .contactusMain{
      margin: 0 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .contactusMainsub{
      width: 90%;
      align-items: center;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0) 132.2%);
      border-radius: 10px;
      padding: 25px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 62px;
      color: #000000;
      border: 1px solid rgb(240, 231, 231);
    }
}