.section-1{
    background-color:#142A53;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px 10%;
    gap: 2px;
}
.sec1Parent{
    background-color:#142A53;
    width: 100vw;
    display: flex;
    flex-direction: column;
    gap: 24;
}
.sec1Learn{
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
}
.section-1 span{
    color: white;
}
.firstSearch{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 60%;
}
.secondSearch{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    width: 30%;
}
.serachIcon{
    position: relative;
    left: 3rem;
    z-index: 9;
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
    width: 100vw;
    background-color: #F2F2F2;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}
.OpportunityCard{
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: 25%;
    text-align: center;
    background-color: #D9D9D9;
    border-radius: 10px;
    flex-wrap: wrap;
}
.headingOC{
    font-size: 20px;
    font-weight: 500;
}

.OCPara{
    font-size: 15px;
    font-weight: 600;
    width: 80%;
    color: #968080;
}
.EOClass{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    align-items: center;
}
.EO-image{
    width: 400px;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
}

.section-3{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.EO-text{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
}

.gradientClass{
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 65.17%);
    z-index: 999;
}
.section-4{
    padding: 20px 10%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.sec4main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 2px;
    background-color: #F2F2F2;
    padding: 20px 0;

}

.sec4Heading{
    font-size: 30px;
    font-weight: 600;
    margin-left: 3%;
}
.sec4Para{
    font-size: 19px;
    font-weight: 700;
    color: #707070;
}
.profileImageCareer{

font-size: 80px;
width: 20%;
}
.parentProfile{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.section-5{
    height: 600px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section-5 img{
    width: 50%;
    object-fit: cover;
}

.section-5 span{
    width: 50%;
    background-color: #1B325E;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 80px;
    padding: 0 20%;
    line-height: 90px;
}

.DesignationInput{
    background-color: white;
    border-radius: 10px;
    width: 90%;
    height: 60px;
    padding-left: 10%;
}
.headerText{
    font-size: 40px;
    font-weight: 400;
    color: white;
    font-style: normal;
}
.verticalLine{
    border-left: 2px solid white;
    height: 40px;
    margin: 0 10px;
}
.JobBtn{
    font-size: 20px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    right: 2%;
    position: absolute;
}

@media screen and (max-width:768px) {
    .OpportunityCard{
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 50px;
        width: 100%;
        text-align: center;
        background-color: #D9D9D9;
        border-radius: 10px;
        flex-wrap: wrap;
    }
    .EO-image{
        width: 350px;
        height: 350px;
        border-radius: 10px;
        object-fit: cover;
    }
    .sec4Para{
        font-size: 12px;
        font-weight: 700;
        color: #707070;
        width: 100%;
    }
    .profileImageCareer{
        width: 50%;
    }
    .parentProfile{
        display: flex;
        flex-direction: column;
        gap: 20px;
        align-items: center;
        justify-content: center; 
    }
    .section-2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 30px;
        width: 100vw;
        background-color: #F2F2F2;
        justify-content: center;
        align-items: center;
        padding: 50px 20px;
    }
    .sec4Heading{
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    .section-5 span{
        width: 50%;
        background-color: #1B325E;
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 50px;
        padding: 0 20%;
        line-height: 60px;
    }
    .firstSearch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 100%;
    }
    .section-1{
        background-color:#142A53;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 5%;
        gap: 20px;
    }
    .DesignationInput{
        background-color: white;
        border-radius: 10px;
        width: 90%;
        height: 60px;
        padding-left: 3.5rem;
    }
    .secondSearch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        width: 89%;
    }
    .JobBtn{
        font-size: 10px;
        font-weight: 500;
        color: white;
        cursor: pointer;
        right: 3%;
        position: absolute;
    }
}