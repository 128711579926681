.parentClassTC {
    width: 100vw;
    padding: 20px;
    margin-top: var(--pageMarginTop);
}

ol {
    padding-left: 0 !important;
}

.firstsection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.firstsection img {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.headerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    padding-left: 20px
}

.secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 10px auto;
    gap: 20px;
    text-align: justify !important;
    color: #707070 !important;
}

.secondSectionHeader {
    font-weight: 700;
    color: #000000;
}

@media screen and (min-width: 501px) {
    .firstsection {
        margin: auto;
        min-width: 22%;
    }

    .firstsection img {
        width: 45%;
        margin: auto;
    }

    .headerSection {
        flex-grow: 1;
        font-size: 1.5rem !important;
        align-items: center !important;
    }
}

@media screen and (min-width: 800px) {
    .headerSection {
        align-items: flex-start !important;
    }

    .firstsection img {
        width: 33%;
    }

    .secondSection {
        width: 70%;
    }

    .firstsection {
        width: 50%;
    }
}



@media screen and (max-width: 768px) {
    .secondSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        margin: 20px 10px;
        gap: 20px;
        width: 100%;
    }

    .headerSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
    }

    .imageStyle {
        width: 100%;
        height: 100%;
    }
}

#parent_container ol {
    counter-reset: custom-counter !important;
}

#parent_container li {
    display: block !important;
}

#parent_container li::before {
    content: counters(custom-counter, ".") " " !important;
    counter-increment: custom-counter !important;
}

#parent_container .container-li:has(.secondSectionHeader)::before {
    content: counters(custom-counter, ".") ". " !important;
    counter-increment: custom-counter !important;
}

#parent_container .secondSection li::before {
    font-weight: bold !important;
    letter-spacing: 0.2px !important;
}

#parent_container .container-li:has(.secondSectionHeader) {
    font-weight: bold !important;
    color: black !important;
    text-align: center !important;
}

@media screen and (max-width: 500px) {
    .secondSection {
        margin: 0;
        width: 100%;
    }

    .container-li {
        margin-bottom: 0.6rem;
    }

    .imageStyle {
        width: 33%;
    }

    .firstsection {
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .headerSection {
        line-height: 1.2 !important;
        gap: 0 !important;
        align-items: center !important;
        line-height: 1.2 !important;
        margin-top: 5px !important;
    }

    .headerSection>span:first-child {
        font-size: 1.4rem;
    }
}