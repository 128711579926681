.modal-open{
  overflow-y: auto !important;
  padding-right: 0 !important;
}
.modal.show .modal-dialog{
    transform: translate(0px, 70px) !important;
  }

.modal-header{
  border: none !important;
}

.modal-content{
  border-radius: 8px !important;
}

.modal_heading{
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.modal_header_desc{
  color: #6D747A;
  letter-spacing: 0.004em;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.btn-close{
  display: none;
}

.modal-body{
  padding: 0.5rem 1rem 1.5rem 1rem !important;
}

.modal-body a{
  text-decoration: none;
}

.modal-footer{
  flex-direction: column;
  align-items: flex-start !important;
  gap: 12px;
  border-top: 1px solid #CED4DA !important;
}

.modal_footer_link{
  display: flex;
  gap: 6px;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.modal_footer_link form{
  display: flex;
    gap: 6px;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.modal_footer_link .header_Pincode_Input{
  width: 65%;
  margin: 0 30px 0 0;
}

.modal_footer_link .header_Submit_Btn p {
  color: #FFFFFF;
}

.modal_footer_link img{
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.modal_footer_link p {
  letter-spacing: -0.5px;
  color: #1B325E;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.modal{
  z-index: 3000 !important;
}

.header_Pincode_Section{
  display: flex;
  gap: 0.75rem;
  align-items: center;
  /* justify-content: space-between; */
  margin: 1.125rem 0 0;
}

.header_Pincode_Input{
  width: 100%;
}

.header_Pincode_Input input{
  border: 1px solid #1B325E;
  border-radius: 0.25rem;
}

.header_Submit_Btn{
  min-width: 128px;
  width: 100%;
  max-width: 128px;
  margin: 0 -2.813rem 0 0;
}

.modal_Image_Preview{
  max-width: 275px;
  /* max-height: 300px; */
  margin: 0 auto 1rem;
}

.modal_Image_Preview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .modal.show .modal-dialog{
    transform: translate(0px, 75px) !important;
  }

  .location_Modal .modal-dialog, .pincode_Modal .modal-dialog{
    max-width: 600px;
  }

  .location_Modal .modal-header .btn-close{
    margin: -2rem -.5rem 1.25rem auto ;
  }
  .pincode_Modal .modal-header .btn-close {
    margin: -2rem -.5rem 4.25rem auto;
  }

  .btn-close{
    display: block;
    opacity: 1 !important;
  }

  .update_Heading .btn-close{
    opacity: 0.5 !important;
  }

  .location_Modal .modal-content{
    padding: 1.5rem 1.125rem;
  }

  .pincode_Modal .modal-content {
    padding: 1.5rem 1.125rem;
  }

  .location_Modal .modal-header{
    padding: 0 0.375rem 1.125rem;
  }

  .location_Modal .modal-body{
    padding: 0 0.375rem 1.5rem;
  }

  .pincode_Modal .modal-header{
    padding: 0 0.375rem 1.5rem;
  }

  /* .modal-header{
    padding: 1.5rem 1.5rem 1rem 1.5rem !important;
  } */

  /* .modal-body{
    padding: 0.125rem 1.5rem 1.5rem !important;
  } */

  .modal_heading{
    font-size: 20px;
    line-height: 28px;
  }

  .modal_header_desc{
    font-size: 14px;
    line-height: 20px;
  }

  /* .modal-footer{
    padding: 1.5rem !important;
  } */

    .location_Modal .modal-footer,
    .pincode_Modal .modal-footer {
      padding: 1.5rem 0.375rem 0;
    }

  .modal-footer > *{
    margin: 0 !important;
  }

  .modal_footer_link img{
    max-width: 24px;
    max-height: 24px;
  }

  .modal_footer_link p{
    font-size: 16px;
    line-height: 24px;
  }

  .update_Heading{
    padding: 1.5rem 0.75rem 0.75rem !important;
    border-bottom: 1px solid #DDE0E7 !important;
    margin: 0 0.75rem;
  }

    .pincode_Modal .modal-header .header_content{
      width: 100%;
    }

  .pincode_Modal .modal-header .header_content .header_Pincode_Section .header_Pincode_Input{
    max-width: 413px;
  }
}