@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap');


.product-card {
    width: 100%;
    display: flex;
    flex-basis: 21%;
    flex-direction: column;
    gap: 10px;
    padding: 1rem 0.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    min-width: 160px;
}

.product-img {
    color: initial;
    text-decoration: none;
}

a.product-img:hover {
    color: initial!important;
}

.tags-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    font-size: 0.9rem;
    width: 90%;
}

.tags {
    display: flex;
}

.tag-item {
    margin-right: 7px;
    padding: 0.2rem;
    border-radius: 5px;
}

.tag-discount {
    background-color: #1B325E;
    color: white;
    font-size: 8px;
}

.tag-best-seller {
    background-color: #FFCC0A;
    color: white;
}

.tag-fav {
    color: #FFCC0A;
}

.product-img {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.product-img:not(:has(img)) {
    justify-content: start;
}

.product-img>img {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 3/4; */
    /* object-fit: cover; */
    border: none;
}

.product-info {
    font-family: 'Quicksand', sans-serif;
    height: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 90%;
    padding: 10px;
}

.prod-name {
    font-weight: 700;
    margin-top: 5px;
    font-size: 16px;
}

.prod-desc {
    color: #3581C1;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


.prod-discount-price {
    width: 100px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #3581C1;

}

.emi-offer {
    display: flex;
    flex-direction: column;
    color: #3581C1;
    font-size: 0.68rem;
    justify-content: space-between;
    font-weight: bold;
    width: 100px;
    padding: 0.1rem;
    bottom: 70px;

}

#emi-bank-offer {
    margin-left: 4px;
}

.prod-buy {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    gap: 3px;
}

.prod-buy-btn {
    background-color: #FFCC0A;
    padding: 0.5rem;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
}

.CardShoppingCartIcon {
    background-color: #1B325E;
    color: white;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
    font-size: 33px;
}

.card-display-row {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow-x: scroll;
}

@media screen and (min-width: 580px) {
    .product-card {
        flex-basis: 48%;
    }
}

@media screen and (min-width: 768px) {
    .product-card {
        display: flex;
        flex-direction: column;
        margin: 0rem 0.15rem;
    }

    .product-img>img {
        border-radius: 6px;
    }

    .tags:has(:not(.tag-discount)) .tag-best-seller {
        margin-right: 0;
        padding: 4px 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .aside_res {
        min-width: 15vw !important;
    }
}

@media screen and (max-width: 450px) {
    .prod-name {
        font-size: 12px;
        text-overflow: ellipsis;
    }

    .product-card {
        margin: 0;
        margin-bottom: 0.75rem;
        /* width: 44%; */
        justify-content: center;
    }

    .prod-desc {
        font-size: 12px;
    }

    .tags-container {
        width: 95%;
    }

    .emi-offer {
        font-size: 0.6rem;
        font-weight: bold;
        flex-direction: column;
        width: 100%;
        padding: 0.1rem;
        bottom: 70px;

    }

    .prod-buy-btn {
        padding: 0.1rem;
    }

    #emi-bank-offer {
        margin-left: 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 449px) {
    .product-card {
        width: 100%;
    }

    .tag-item {
        margin-right: 2px;
    }

    .Product_Category_Container_res {
        row-gap: 1rem !important;
    }
}

@media screen and (max-width: 500px) {
    .tag-best-seller,.tag-discount {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 2px;
    }

    .tag-best-seller span,
    .tag-discount span {
        padding: 0 2px;
        font-size: 7px !important;
        font-weight: 600 !important;
    }
    .prod-discount-price {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .product-card {
        min-width: 148px;
    }
}

@media screen and (min-width: 1000px) {
    .product-card {
        min-width: min-content;
        margin-bottom: 1rem;
    }
}