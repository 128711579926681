
.banner-component{
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%
}
banner{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.banner img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 4px;
}
.mobilescreen{
    display: block;
    margin-top: 10px;
}
.mobilescreen img {
    max-height: 58px;
}
.desktop{
    display: none;
}
@media screen and (min-width: 708px) {
    .banner-component{
        display: flex; 
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        width: 100%;
        height: 100%
    }
    .banner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 99%;
        height: 100%
    }
    .banner img{
        width: 32.5%;
        max-height: 400px;
        min-height: 400px;
        object-fit: cover;
        border-radius: 10px;
        
    }
    .mobilescreen{
        display: none;
    }
    .desktop{
        display: flex;
        margin-top: 8px;
    }
}

@media screen and (max-width: 500px) {
    .mobilescreen img {
        height: 120px;
        max-height: 100%;
    }
}