.address_List {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0 0.75rem;
}

.add_New_Address {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  background-color: #ffffff;
  margin: 1rem 0 0;
  text-decoration: none;
  cursor: pointer;
}

.add_New_Address img {
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.add_New_Address p {
  margin: 0;
  color: #3d5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  width: 100%;
  z-index: 100;
}

.home_Delivery_Options {
  margin: 1rem 0 0;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.home_Delivery_Option {
  background: #ffffff;
  height: fit-content;
}

.home_Delivery_Label {
  height: fit-content !important;
}

.home_Delivery_Label .radio-custom {
  top: 20px !important;
  left: 16px !important;
}

.home_Delivery_New_Address {
  margin: 0.75rem 0 4.5rem;
}

@media screen and (min-width: 768px) {
  .add_New_Address,
  .address_List {
    max-width: 63%;
  }

  /* .add_New_Address{
    padding: 1rem 0;
  } */

  .add_New_Address {
    margin: 0;
  }

  .add_New_Address p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .home_Delivery_Label .radio-custom {
    top: 22px;
  }

  .home_Delivery_New_Address {
    max-width: 100%;
    margin: 1.5rem 0 4rem;
  }
}

@media screen and (min-width: 1200px) {
  .add_New_Address,
  .address_List {
    max-width: 69%;
  }

  .home_Delivery_New_Address {
    max-width: 100%;
  }
}
