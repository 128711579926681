.loginSecWrapper {
  padding: 10px 90px;
  font-family: "Quicksand";
  margin-top: var(--pageMarginTop);
}

.loginSecLeft{
    width: 65%;
}

.loginSecDetails{
    background-color: #F8F8F8;
    padding: 50px 60px 130px 75px;
}
.breadcrumbs {
  font-size: 16px;
}

.loginSecPageName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 17px;
  padding-left: 75px;
}

.loginSecTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: #000000;
  margin-bottom: 35px;
}

.loginSecDetailName {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: #000000;
}

.loginSecUserName {
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 36px;
  color: #000000;
}


.loginSecPara {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: #000000;
}

.loginSecRightIcon img {
  width: 32px;
  height: 32px;
}

.loginSecRightIcon img:where(:hover, :focus-visible) {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.1s ease-in-out;
}

.loginSecImageContainer{
    width: 35%;
    padding: 80px 140px 0 70px;
}

.loginSecImage {
  width: 333px;
  height: 338px;
}

.loginSecContent,
.loginSecDetailDiv {
  display: flex;
}

.loginSecContent{
    gap: 20px;
    margin-bottom: 50px;
}

.loginSecDetailDiv{
    justify-content: space-between;
}


.loginSecUserNameContainer{
    display: flex;
    align-items: center;
    gap: 19px;
    margin-bottom: 50px;
}

@media screen and (max-width : 500px) {
  .loginSecPageName {
  padding-left: 10px;
}

  .loginSecWrapper {
  padding: 10px 0px;
  }
  .loginSecLeft{
    width: 100%;
  }
  .loginSecDetails{
    background-color: #F8F8F8;
    padding: 20px 15px 80px 15px;
  }
  .loginSecDetailName {
  font-size: 24px;
  line-height: 30px;
}
.loginSecUserName {
  font-size: 22px;
  line-height: 20px;
}
.loginSecPara {
  font-size: 14px;
  line-height: 17px;
}
.loginSecMobileImageContainer{
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginSecImage {
  width: 143px;
  height: 148px;
  margin-top: -30px;
}
.loginSecTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}

.loginSecUserNameContainer,
.loginSecPara {
  margin-bottom: 1.2rem!important;
}

.loginSecRightIcon img {
  width: 1.4rem;
  height: 1.4rem;
}
.loginSecRightIcon {
  margin-right: 13px;
}

}