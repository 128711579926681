.absection-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding: 0 8%;
}
.absec-1sub{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 10px;
    font-weight: 700;
    font-style: normal;
}
.absec-1-img-1{
    height: 120px;
    width: 115px;
    position: absolute;
    right: 20rem;
}

.absec-1-img-2{
    width: 150px;
    height: 150px;
    position: absolute;
    right: 11rem;
}
.absection-2{
    width: 100vw;
    display: flex;
    flex-direction: row;
    z-index: 9;
    backdrop-filter: blur(15px);
    background: none;

}
.editionsec2{
    width: 73%;
    padding: 30px 0px 30px 8%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-weight: 700;
    background: #F8F8F8;
}
.editionsec2Imag{
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    margin-top: 1px;
    margin-left: 21%;
}
.absection-3{
    width: 100vw;
    display: flex;
    /* background-color: #F8F8F8; */
    flex-direction: row;
}
.mobilecss{
    width: 20rem;
    position: absolute;
    height: 15rem;
    top: -55px;
    left: 12%;
}
.mobileParentcss{
    top: 644px;
    width: 100%;
    height: 186px;
    position: absolute;
    backdrop-filter: blur(16px);
    padding-top: 19px;
}
@media screen and (max-width:450px) {
    
}