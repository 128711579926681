@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Nunito+Sans:300,400,500,600,700);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.headerbarContainer {
  background: #1b325e;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
}

.headerbarWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  background: #1b325e;
}

.headerbarLeft,
.headerbarRight {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
} 

.headerbarLeft_Inner {
  display: flex;
  align-items: center;
}

#headerbarCenter {
  display: none;
}

.header-pincode {
  font-size: 0.7rem;
  line-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 60px;
}

#header-hello {
  line-height: 95%;
}

.headerLocation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.location-logo {
  font-size: 2rem;
  width: 45px;
}

.hamburger {
  max-height: 40px;
  max-width: 40px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hamburger img {
  width: 100%;
  height: 100%;
}

.navLogo {
  cursor: pointer;
}

.left_location {
  display: none;
}

.right_login {
  display: none;
}
.cartIcon {
  color: white;
  margin-left: 20px;
}

#cartItems {
  position: relative;
  bottom: 15px;
  right: 21px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  background-color: #ffa500;
  justify-content: center;
  align-items: center;
}
.storeIcon,
.cartIcon,
.user_profile {
  cursor: pointer;
  text-decoration: none;
  text-decoration: none;
}

.storeIcon img,
.cartIcon img,
.user_profile img {
  height: 100%;
  width: 100%;
  max-width: 24px;
  max-height: 24px;
}

.storeIcon p,
.cartIcon p,
.user_profile p {
  display: none;
  margin: 0;
  text-decoration: none;
}

.storeLogo {
  display: flex;
  flex-direction: column-reverse;
}

.searchbarWrapper {
  width: 100%;
  padding: 0px 1rem 0.875rem 1rem;
  background: #1b325e;
}

.searchbar_Container {
  position: relative;
  width: 100%;
  height: inherit;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}
.search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.search-btn {
  width: 40%;
  height: var(--headerSearchHeight);
  font-size: 1.3rem;
  cursor: pointer;
  background-color: orange;
  border: none;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}
.searchbar {
  border: none;
  outline: none;
  height: var(--headerSearchHeight);
}

.searchbar::-webkit-input-placeholder {
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
}

.searchbar::placeholder {
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
}

.seachbar_Icon {
  position: absolute;
  top: 50%;
  left: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.search_Results {
  position: absolute;
  top: 104px;
  left: 0;
  background: #ffffff;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 500px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 2001;
}

.search_Results::-webkit-scrollbar {
  display: none;
}

.search_Result_Item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 0 0 0.625rem;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #969696;
}

.search_Result_Item p {
  margin: 0;
  color: var(--primaryDarkBlue);
  font-size: 15px; 
}

.search_Result_Item:hover {
  background: #e5e5e5;
}

.locationbarWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  background: #546586;
  padding: 0.875rem 1rem;
}

.locationbarWrapper p {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.004em;
  color: #ffffff;
  margin: 0;
}

.login_tab_only {
  display: none;
}

.categories_Container {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  padding: 0 0 0 1rem;
  cursor: auto;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: -1;
  padding-top: 15px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.categories_Container::-webkit-scrollbar {
  display: block;
  background: rgb(216, 214, 214);
  height: 2px;
}

.categories_Wrapper {
  display: flex;
  grid-gap: 32px;
  gap: 32px;
  white-space: nowrap;
  margin: auto;
  max-width: 90%;
}

.category {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  cursor: pointer;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  justify-content: center;
}

.category img {
  max-width: 60px;
  max-height: 60px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
}

.category p {
  margin: 0;
  letter-spacing: 0.015em;
  color: #707070;
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.325rem;
  /* width: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align: center;
}

.header2_tab {
  display: none;
}

.headerbar3_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 56px;
  z-index: 200;
  background: #1b325e;
}

.headerbar3_Wrapper {
  padding: 0.875rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.headerbar3_Wrapper .back_Btn {
  max-width: 28px;
  max-height: 28px;
  width: 100%;
  height: 100%;
}

.back_Btn:focus,
.back_Btn:hover {
  scale: 1.3;
  transition: all ease-in 200ms;
  cursor: pointer;
}

.headerbar3_Wrapper .nav_Logo {
  max-width: 26px;
  max-height: 30px;
  width: 100%;
  height: 100%;
}

.headerbar3_Wrapper p {
  letter-spacing: -0.01em;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  justify-self: center;
  margin: 0;
}

.radio_input:checked {
  border-color: #ffcc0a;
}
.lang_list_item {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #757878;
}
.signIn_list_item {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #757878;
}

.header_sign_in_btn {
  background-color: #ffcc0a !important;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000 !important;
  padding: 7px 10px;
  margin: 0 auto;
  width: 120px;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  border-radius: 0 0 0 0.7rem;
}

.header_sign_in_btn p {
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .category img {
    max-width: 80px;
    max-height: 80px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
  }
  .category p {
    margin: 0;
    letter-spacing: 0.015em;
    color: #707070;
    font-weight: 500;
    line-height: 1rem;
    font-size: 0.625rem;
    /* width: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    text-align: center;
  }

  .header2_tab {
    display: flex;
  }

  .headerbarWrapper {
    padding: 0.75rem 1.5rem;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    margin: auto;
    max-width: 1920px;
    width: 100%;
  }

  .headerbarCenter {
    flex: 1 1;
  }
  .headerbarCenter,
  .headerbarRight {
    justify-content: center;
  }

  .headerbarCenter {
    display: flex;
    flex-direction: column;
    grid-gap: 2.25rem;
    gap: 2.25rem;
    position: relative;
    margin-left: 10px;
  }

  .left_location {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    cursor: pointer;
  }

  .left_location p {
    letter-spacing: -0.01em;
    color: #dde0e7;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .map-location {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .searchbar {
    padding: 0.625rem 0.75rem 0.625rem 0.5rem;
  }

  .search-icon {
    margin-left: 2%;
  }

  .searchbar::-webkit-input-placeholder {
    line-height: 1.25rem;
  }

  .searchbar::placeholder {
    line-height: 1.25rem;
  }

  .search_Results {
    top: 50px;
    margin: 0;
  }
  .personIcon {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 2rem;
  }
  .right_login_comp {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0%;
  }
  .right_login {
    line-height: 0%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    margin: 0;
    font-size: 0.4rem;
  }

  .right_login a {
    cursor: pointer;
    letter-spacing: -0.01em;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .right_login a:hover {
    color: #ffffff;
  }

  .headerbarRight {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .map-location {
    display: flex;
    margin-right: 40px;
  }
  #lang-select {
    width: 100px;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
  }
  .IndianFlagIcon {
    width: 30px;
  }
  .lang-item {
    color: black;
    color: white;
  }
  .storeIcon,
  .cartIcon,
  .user_profile {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    align-items: center;
  }

  .storeIcon p,
  .cartIcon p,
  .user_profile p {
    display: block;
    color: #dde0e7;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    white-space: nowrap;
  }

  .searchbarWrapper,
  .locationbarWrapper {
    display: none;
  }
  .login_tab_only {
    display: block;
  }

  .categories_Container {
    padding: 0.5rem 1.5rem;
  }

  .categories_Wrapper {
    grid-gap: 1.125rem;
    gap: 1.125rem;
  }

  .category p {
    font-family: "" Noto Sans Kannada ", Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #707070;
  }

  .headerbar3_container {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .headerbarLeft_Inner {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .headerbarWrapper {
    grid-gap: 1.25rem;
    gap: 1.25rem;
  }
  .storeIcon {
    display: flex;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    align-items: center;
  }

  .storeIcon p {
    display: block;
    color: #dde0e7;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .cartIcon {
    order: 1;
  }

  .right_login,
  .user_profile {
    display: flex;
    order: 3;
  }

  .storeIcon,
  .cartIcon,
  .right_login,
  .user_profile {
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }

  .login_tab_only {
    display: none;
  }

  .categories_Container {
    padding: 0rem 3.75rem;
  }

  .categories_Wrapper {
    grid-gap: 32px;
    gap: 32px;
  }

  .headerbarRight {
    grid-gap: 2.5rem;
    gap: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  headerbarLeft.headerbarWrapper {
    padding: 0.75rem 1.875rem;
  }

  .headerbarLeft {
    justify-content: space-around;
  }
}

@media screen and (min-width: 1400px) {
  .headerbarWrapper {
    padding: 0.75rem 3.75rem;
  }

  .headerbarLeft {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .headerbarRight {
    grid-gap: 2.5rem;
    gap: 2.5rem;
    position: relative;
    left: 10px;
  }
}

.rec.rec-arrow {
  border-radius: 0;
}

.rec.rec-arrow:hover {
  border-radius: 50%;
  background-color: #1b325e;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

button.rec-dot {
  display: none;
}
.rec-pagination {
  display: none;
}

.carousel-itm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  cursor: pointer;
}

.header-res-top {
  display: flex;
  width: 100%;
  padding: 0px 1rem;
  flex-direction: row;
  font-family: "Quicksand";
  align-items: center;
}

.headerbarLeft_Inner_res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1 1;
  height: 70px;
}
.right_login_comp_res {
  width: 100%;
}
.right_login-res {
  color: white;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.personIcon {
  color: white;
}
.right_login-res a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.right_login_comp_res {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cartIcon_res {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cartItems_res {
  background-color: #ffa500;
  position: relative;
  bottom: 2.5rem;
  left: 1.4rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
}

.header-mob-signIn-res {
  margin-left: 10%;
  margin-right: 2%;
}

:where(.header-mob-signIn-res, .cartIcon_res):where(:focus-visible, :hover) {
  cursor: pointer;
}
.header-res-center {
  padding: 0px 1rem;
  font-family: "Quicksand";
}

.headerbarLeft_res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}
.left_location_res,
.map-location_res {
  display: flex;
  color: white;
  align-items: center;
}
.left_location_res p,
.map-location_res p {
  margin-block:  0;
}
.headerLocation_res {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 16px;
}
.location-logo_res {
  display: flex;
  justify-content: center;
  justify-content: center;
}
.IndianFlagIcon_res {
  width: 35px;
  border-radius: 5px;
}
#lang-select_res {
  width: 10px;
  background-color: transparent;
  border: none;
  color: grey;
  outline: none;
  cursor: pointer;
}
.map-location_res {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  z-index: 100000;
}
.map-select {
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
}
.header-res-bottom {
  font-family: "Quicksand";
  font-weight: 700;
  padding: 0px 4%;
}

#person-icon_res {
  font-size: 3rem;
}
.text2xl {
  font-weight: 700;
  font-size: 13px;
}
.text1xl {
  font-size: 13px;
}

.storeIcon,
.cartIcon,
.user_profile {
  cursor: pointer;
  text-decoration: none;
  text-decoration: none;
}

.storeIcon img,
.cartIcon img,
.user_profile img {
  height: 100%;
  width: 100%;
  max-width: 24px;
  max-height: 24px;
}

.user_profile img {
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.smallLogo img {
  width: 100px;
}
@media screen and (max-width: 500px) {
  .user_profile img {
    max-width: 31px;
    max-height: 33px;
  }
  .cartIcon_res {
    margin-top: 23px;
    padding-bottom: 3px;
  }
  #person-icon_res {
    font-size: 2.4rem;
  }
  .carousel-itm img {
    width: 52px;
  }
  .header-mob-signIn-res {
    padding-top: 7px;
  }
  .search_Results{
    top: 107px;
    width: 92%;
    left: 5px;
    z-index: 2001;
  }
  .search_Result_Item {
    height: 38px;
  }
}
.category p {
  font-size: 8.7px;
}

.selected_search_item {
  background-color: #e5e5e5;
}

.my-carousel .slider-prev,
.my-carousel .slider-next {
  background-color: white;
  color: black;
  width: 30px;
  height: 30px;
}

.my-carousel .slider-prev:hover,
.my-carousel .slider-next:hover {
  background-color: red;
}

.locationModalContainer {
  position: relative;
}

.locationModalHeader {
  background-color: #f0f2f2;
  padding: 15px 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: black;
}

.locationModalpara {
  padding: 0 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 4px;
  color: #757878;
}

.locationModalinstruction,
.locationModalpincode {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  padding: 10px 25px 0px;
  color: #0066c0;
}

.locationModalinstruction {
  display: inline-flex;
  grid-gap: 0.3rem;
  gap: 0.3rem;
  cursor: pointer;
}

.locationModalinstruction:where(:hover, :focus-visible) {
  scale: 1.01;
  transition: all 0.3s ease-in-out;
}

.locationModalinstruction  p {
  margin-bottom: 0;
}

.locationModalInputContainer {
  padding: 0 25px 0 25px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.locationModalinput {
  width: 70%;
  padding: 2px 5px;
}
.locationModalbutton {
  width: 20%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #0b0b0b;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #D6CFCF;
  padding:4px 16px;
  border-radius: 5px;
}

.locationmodalCloseIcon{
  position: absolute;
  top: 14px;
  right: 8px;
  cursor: pointer;
  z-index: 10000000;
}
@media screen and (max-width:500px) {
  
  .locationModalHeader {
    padding: 15px 20px;
  }
  
  .locationModalpara {
    padding: 0 0px 0px 20px;
  }
  
  .locationModalinstruction,
  .locationModalpincode {
    padding: 10px 20px 0px;
  }
  
  .locationModalInputContainer {
    padding: 0 20px 0 20px;
  }

  .headerbarRight_res{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }
}

.language_wrapper {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  z-index: 100000;
}  

.ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.dropDownBtn{
  position: relative;
  z-index: 9990;
}

.dropDownText{
  cursor: pointer;
}

@media screen and (min-width: 500px) and (max-width: 992px) {
    .left_location_res .location-logo_res {
      width: 18px!important;
      height: 18px;
    }
    .headerLocation_res, .lang-item {
      font-size: 0.75rem;
    }
    .headerbarRight_res {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
      gap: 10px;
    } 
    .categories_Container {
      top: 156px!important;
    }
    .mobilescreen img {
      max-height: 140px!important;
    }
    .products {
      width: 100%!important;
    }
    .storeIcon {
      grid-gap: 0;
      gap: 0;
    }
    .storeIcon p {
      margin-top: -4px;
    }
}
.dropDownContainer {
  position: fixed;
  left: 50px;
  bottom: 20;
  width: 170px;
  z-index: 9999;
  opacity: 0;
  -webkit-animation: fadeAnimate 0.3s forwards;
          animation: fadeAnimate 0.3s forwards;
}

.dropDownContainer[data-categorydropdownopen="open"] {
  -webkit-backdrop-filter: blur(3px);
          backdrop-filter: blur(3px);
}

@-webkit-keyframes fadeAnimate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAnimate {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dropDownItemsCon {
  background: linear-gradient(
    177.95deg,
    #f3f6ff 1.73%,
    rgba(243, 246, 255, 0) 115.91%
  );
  border-radius: 5px;
  position: relative;
}

.dropDownItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 0.6rem 0 1rem;
  position: relative;
}
.dropDownItem > h4 {
  color: black;
  font-size: 15px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  flex-grow: 1;
}

.rightArrowIcon {
  -webkit-transform: translateY(-4px);
          transform: translateY(-4px);
}

.rightArrowIcon .MuiSvgIcon-root {
  font-size: 19px;
  cursor: pointer;
  color: black;
}

.MuiSvgIcon-root.rotate {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media  screen and (max-width: 500px) {
  .dropDownItem > h4 {
    font-size: 0.75rem;
  }
  .dropDownItem {
    padding: 5px 8px;
  }
  .dropDownContainer {
    width: 35vw;
    left: 7px;
    top: 222px;
  }
}
.logoModalContainer {
  padding: 20px 60px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  background: rgba(255, 255, 255, .7);
}

.logoModalContainer::before {
  content: "";
  position: absolute;
  display: block;
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  z-index: -1;
}

.logoModalItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 70px;
  gap: 70px;
}

.logoModalItems>img {
  width: 60px;
  height: 50px;
}

.logoModalItems>img:nth-child(2) {
  width: 90px;
  height: 50px;
}

.logoModalItems>img:where(:hover, :focus-visible) {
  scale: 1.1;
  -webkit-filter: drop-shadow(2px 4px 6px rgb(108, 107, 107));
          filter: drop-shadow(2px 4px 6px rgb(108, 107, 107));
  cursor: pointer;
}

@media screen and (max-width: 500px) {
  .logoModalContainer {
    padding: 0.75rem;
    grid-gap: 0.7rem;
    gap: 0.7rem;
  }

  .logoModalItems>img {
    width: 1.45rem;
    aspect-ratio: 1/1;
  }

  .css-1wy80ie {
    width: 50vw !important;
  }

  .logoModalItems {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .logoModalItems>img:nth-child(2) {
    width: 3.6rem;
  }

}

@media screen and (max-width: 650px) {
  .logoModalItems>img {
    width: 33px;
    height: 33px;
  }
  .logoModalItems>img:nth-child(2) {
    width: 58px;
    height: 36px;
  }

  div[data-sequence^="sequence"] {
    width: 60vw!important;
  }
  .dropDownContainer {
    left: 10px;
  }
}
.absection-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100vw;
    padding: 0 8%;
}
.absec-1sub{
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    grid-gap: 10px;
    gap: 10px;
    font-weight: 700;
    font-style: normal;
}
.absec-1-img-1{
    height: 120px;
    width: 115px;
    position: absolute;
    right: 20rem;
}

.absec-1-img-2{
    width: 150px;
    height: 150px;
    position: absolute;
    right: 11rem;
}
.absection-2{
    width: 100vw;
    display: flex;
    flex-direction: row;
    z-index: 9;
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    background: none;

}
.editionsec2{
    width: 73%;
    padding: 30px 0px 30px 8%;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
    font-weight: 700;
    background: #F8F8F8;
}
.editionsec2Imag{
    width: 20rem;
    height: 20rem;
    object-fit: cover;
    margin-top: 1px;
    margin-left: 21%;
}
.absection-3{
    width: 100vw;
    display: flex;
    /* background-color: #F8F8F8; */
    flex-direction: row;
}
.mobilecss{
    width: 20rem;
    position: absolute;
    height: 15rem;
    top: -55px;
    left: 12%;
}
.mobileParentcss{
    top: 644px;
    width: 100%;
    height: 186px;
    position: absolute;
    -webkit-backdrop-filter: blur(16px);
            backdrop-filter: blur(16px);
    padding-top: 19px;
}
@media screen and (max-width:450px) {
    
}
.address_List {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0 0.75rem;
}

.add_New_Address {
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  background-color: #ffffff;
  margin: 1rem 0 0;
  text-decoration: none;
  cursor: pointer;
}

.add_New_Address img {
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.add_New_Address p {
  margin: 0;
  color: #3d5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #ffffff;
  padding: 0.5rem 1rem;
  width: 100%;
  z-index: 100;
}

.home_Delivery_Options {
  margin: 1rem 0 0;
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.home_Delivery_Option {
  background: #ffffff;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.home_Delivery_Label {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.home_Delivery_Label .radio-custom {
  top: 20px !important;
  left: 16px !important;
}

.home_Delivery_New_Address {
  margin: 0.75rem 0 4.5rem;
}

@media screen and (min-width: 768px) {
  .add_New_Address,
  .address_List {
    max-width: 63%;
  }

  /* .add_New_Address{
    padding: 1rem 0;
  } */

  .add_New_Address {
    margin: 0;
  }

  .add_New_Address p {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .home_Delivery_Label .radio-custom {
    top: 22px;
  }

  .home_Delivery_New_Address {
    max-width: 100%;
    margin: 1.5rem 0 4rem;
  }
}

@media screen and (min-width: 1200px) {
  .add_New_Address,
  .address_List {
    max-width: 69%;
  }

  .home_Delivery_New_Address {
    max-width: 100%;
  }
}

.address{
  background-color: #FFFFFF;
}

.address_Box{
  padding: 1rem 0.75rem 0.125rem;
  background-color: #FFFFFF;
  position: relative;
}

/*
.address_Box_Wrapper_Section{
  display: flex;
  padding-right: 10px;
} 
*/

.address_Box_Wrapper{
  padding: 0 0 1rem;
  border-bottom: 1px solid #EBEDF0;
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  gap: 8px;
  margin: 0 0.25rem;
}

.address_Box_Wrapper p{
  margin: 0;
  color: #08090A;
}

.address_Box_Wrapper p:nth-of-type(2), .address_Box_Wrapper p:nth-of-type(3){
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Default_Button{
  max-width: 145px;
  width: 100%;
  position: absolute;
  top: -0.6rem;
  right: 1.5rem;
}

.address_Default_Button.disable{
  opacity: 0.6;
  pointer-events: none;
}

.address_Box_Name{
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
}

.address_Box_Footer{
  margin: 0 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.25rem;
  gap: 0.25rem;
}

.address_Box_Footer img{
  max-width: 18px;
  max-height: 18px;
  height: 100%;
  width: 100%;
}

.address_Box_Footer p{
  margin: 0;
  color: #3D5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.address_Footer_Delete, .address_Footer_Edit{
  width: 80%;
  display: flex;
  /* align-items: center; */
  justify-content: end;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  margin: 0.125rem 0 0;
  padding: 0.75rem 0 0.63rem;
  cursor: pointer;
  text-decoration: none;
}

.address_Footer_Delete{
  border-right: 1px solid #DDE0E7;
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

.address_Footer_Edit{
  display: flex;
  justify-content: center;
  margin-top: 14px;
}

@media screen and (min-width: 768px) {
  .address_Box{
    padding: 1rem 0.75rem 0.25rem;
  }

  .address_Box_Name{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .address_Box_Wrapper p:nth-of-type(2){
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .address_Footer_Delete, .address_Footer_Edit{
    grid-gap: 0.375rem;
    gap: 0.375rem;
  }

  .address_Box_Footer p{
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.address_Form_Container{
  background: #FFFFFF;
  margin: 1rem 0 7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1.5rem 1rem;
}

.address_Form_Container div{
  display: flex;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.address_Form_Container input{
  width: 100%;
  padding: 1rem;
  border: 0.063rem solid #DDE0E7;
  box-sizing: border-box;
  border-radius: 0.25rem;
}

.address_Form_Header{
  display: none;
}

.address_Form_Submit{
  display: none;
}

.address_Form_Checkbox{
  margin: 0 0 0 1rem;
}

@media screen and (min-width: 768px) {
  .address_Form_Container{
    margin: 0 0 7rem;
    border: 1px solid #CED4DA;
    max-width: 96%;
    padding: 1.5rem 0.75rem;
  }

  .address_Form_Header{
    display: block;
    padding: 0 0.75rem 1rem;
    margin: 0;
    border-bottom: 1px solid #DDE0E7;
    letter-spacing: -0.01em;
    color: #000000;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .address_Form_Container input, .address_Form_Container div, .address_Form_Container button{
    max-width: 420px;
    margin: 0 0.75rem;
  }

  .address_Form_Container div input{
    margin: 0;
  }

  .address_Form_Submit{
    display: block;
  }
}

@media screen and (min-width: 1200px) {
  .address_Form_Container{
    max-width: 100%;
  }
}
.newAddressWrapper {
  padding: 16px 20px;
  font-family: "Quicksand";
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.newAddressContainer {
  display: flex;
}

.newAddressContent {
  padding-left: 100px;
  width: 60%;
}

.newAddressContent .newAddressTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 40px;
}

.newAddressContent .newAddressHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

/* Image */

.newAddressImageContainer {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  padding-top: 40px;
  padding-right: 100px;
}

.newAddressImageContainer img {
  width: 550px;
  height: 500px;
}

/* Form */

.newAddressFormContainer {
  background-color: #f8f8f8;
  padding: 35px 104px 200px 32px;
  border-radius: 10px;
}

.newAddressStateInput {
  width: 526px;
  height: 65px;
  /* filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25)); */
  padding: 20px 0 20px 28px; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  border: none;
  outline: none;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  border: 1px solid #d9d9d9;
}

.newAddressStateInput option {
  background-color: #ffffff;
  color: #000000;
}

.newAddressInputDiv {
  padding: 10px;
  width: 530px;
  margin-bottom: 13px;
  border-radius: 7px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
}

.newAddressInputField {
  border: none;
  outline: none;
  padding: 5px 5px 7px 10px;
  width: 100%;
  font-size: 20px;
}

.newAddressInputField.textArea {
  height: 140px;
  resize: none;
}
.newAddressInputField.landMark {
  height: 70px;
  resize: none;
}

.newAddressInputField::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b2b2b2;
}

.newAddressInputField::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #b2b2b2;
}

.newAddressInputTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 31px;
  color: #000000;
}

.newAddressDoubleInputDiv {
  padding: 10px;
  width: 530px;
  margin-bottom: 13px;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}

.smallInputDiv {
  width: 15%;
  padding: 0px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
  background-color: white;
}

.newAddressSmallInputField {
  height: 62px;
  outline: none;
  border: none;
}

.bigInputDiv {
  width: 85%;
  border: 1px solid #d9d9d9;
  border-radius: 7px;
}

.newAddressBigInputField {
  height: 62px;
  cursor: pointer;
  padding: 10px;
  outline: none;
  border: none;
}

.newAddressInputDiv:hover, .newAddressStateInput:hover, .newAddressBigInputField:hover{
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}

.newAddressSmallInputField::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}

.newAddressSmallInputField::placeholder {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 25px;
  color: #000000;
  text-align: center;
}

.newAddresscheckBoxDiv {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 30px;
  margin-bottom: 17px;
}

.newAddressCheckbox {
  display: inline-block;
  width: 20px;
  border: 2px solid #0066c0;
}

.newAddressConfirmCheckbox {
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 20px;
  color: #0066c0;
}

.newAddresssubmitButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.newAddresssubmitButton p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

@media screen and (max-width: 500px) {
  .newAddressContent {
    width: 100%;
    padding-left: 10px;
  }
  .newAddressContainer {
    position: relative;
  }
  .newAddressImageContainer {
    position: absolute;
    top: 0px;
    right: -5px;
    padding-top: 0px;
    padding-right: 0px;
    width: 210px;
    height: 160px;
  }
  .newAddressImageContainer img {
    width: 100%;
    height: 100%;
  }
  .newAddressFormContainer {
    padding: 15px 14px 110px 16px;
  }
  .newAddressStateInput {
    width: 90%;
    height: 45px;
    padding: 10px 0 10px 20px;
  }
  .newAddressInputDiv {
    padding: 6px;
    width: 90%;
  }
  .newAddressDoubleInputDiv {
    width: 90%;
    grid-gap: 19px;
    gap: 19px;
    padding: 0;
  }
  .bigInputDiv {
    width: 73%;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
  }

  .newAddressBigInputField {
    height: 62px;
    cursor: pointer;
    padding: 10px;
    outline: none;
    border: none;
  }

  .smallInputDiv {
    width: 27%;
    padding: 0px 7px;
    border: 1px solid #d9d9d9;
    border-radius: 7px;
    background-color: white;
  }

  .newAddressSmallInputField::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  .newAddressSmallInputField::placeholder {
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    color: #000000;
    text-align: center;
  }

  .newAddressInputTitle {
    font-size: 17px;
    line-height: 25px;
  }

  .newAddressContent .newAddressTitle {
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 20px;
  }

  .newAddressContent .newAddressHeading {
    width: 132px;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 44px;
  }
}


@media screen and (max-width: 380px){
  .newAddressImageContainer {
    width: 170px;
    height: 130px;
    top: 25px;
  }
}
.cart_Price_details{
  padding: 1rem 0.75rem 0.75rem;
  background-color: #FFFFFF;
  margin: 0 0 1rem;
}

.cart_Details_Header{
  padding: 0 0.25rem 0.75rem;
  border-bottom: 1px solid #EBEDF0;
  /* margin: 0 0.25rem; */
}

.cart_Details_Header p{
  margin: 0;
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

.cart_Details_Body{
  padding: 0.75rem 0.25rem;
  /* margin: 0 0.25rem; */
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  border-bottom: 1px solid #EBEDF0;
}

.cart_Details_Price , .cart_Details_Discount, .cart_Details_Delivery{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart_Details_Price p, .cart_Details_Discount p, .cart_Details_Delivery p {
  color: #08090A;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Details_Discount p:last-child{
  color: #3F8F00;
}

.cart_Details_Footer{
  padding: 0.75rem 0.25rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 0.25rem; */
}

.cart_Details_Footer p{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.cart_Details_Footer p:last-child{
  color: #3F8F00;
}

.cart_Details_Hide{
  margin: 0.75rem 0 0;
  border-top: 0.063rem solid #EBEDF0;
  text-align: center;
  padding: 0.75rem 0.25rem 0;
}

.cart_Details_Hide p{
  margin: 0;
  color: #3D5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .side_Section .cart_Price_details{
    border-radius: 0.25rem;
    margin: 0;
  }
}

@media screen and (min-width: 1024px) {
  .cart_Details_Header p {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

    .cart_Details_Price p,
    .cart_Details_Discount p,
    .cart_Details_Delivery p,
    .cart_Details_Footer p {
      font-size: 1rem;
      line-height: 1.5rem;
    } 
}
.page_Breadcrumb {
  display: none;
  padding: 1.5rem 0 0;
}

.page_Breadcrumb p,
.page_Breadcrumb a {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
  text-decoration: none;
}

.page_Breadcrumb img {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .page_Breadcrumb {
    display: flex;
  }

  .page_Breadcrumb_Wrapper {
    display: flex;
    grid-gap: 0.125rem;
    gap: 0.125rem;
    align-items: center;
  }
}

@media screen and (min-width: 1024px) {
  .page_Breadcrumb p,
    .page_Breadcrumb a {
      font-size: 1rem;
      line-height: 1.5rem;
    }
}

.headerContainer {
  background: #1b325e;
  width: 100%;
  height: 4rem;
  position: fixed;
  top: 0;
  z-index: 100;
  padding: 8px 0;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px 0 4px;
}

.logo {
  max-width: 128px;
  max-height: 50px;
  cursor: pointer;
}

.logo img {
  width: 100%;
  height: 100%;
}

.logo_desk {
  display: none;
}

.logo_desk_1200 {
  display: none;
}

.logo_tab {
  display: none;
}

.alternate-way {
  font-size: 14px;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: white;
  cursor: pointer;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .headerContainer {
    height: 4.5rem;
    padding: 16px 60px;
  }

  .logo_mob {
    display: none;
  }

  .logo_desk {
    display: block;
  }

  .logo_tab {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .headerContainer {
    padding: 16px 175px;
  }

  .logo {
    max-width: 156px;
    max-height: 44px;
  }

  .alternate-way {
    font-size: 18px;
    line-height: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .logo_tab {
    display: none;
  }

  .logo_desk_1200 {
    display: block;
    width: 140px;
  }
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 15px;
}
.lds-ellipsis div {
  position: absolute;
  top: 0px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #000;
  -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
          animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  -webkit-animation: lds-ellipsis1 0.6s infinite;
          animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  -webkit-animation: lds-ellipsis2 0.6s infinite;
          animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  -webkit-animation: lds-ellipsis3 0.6s infinite;
          animation: lds-ellipsis3 0.6s infinite;
}
@-webkit-keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes lds-ellipsis1 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(24px, 0);
            transform: translate(24px, 0);
  }
}

.bank_Offer_Container{
  padding: 1rem;
  background: #FFFFFF;
  margin: 0 0 1rem;
}

.bank_Offer_Heading{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 0.5rem;
}

.bank_Offer_Desc{
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 1.5rem;
}

.offer_Question{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.75rem;
}

.offer_Conditions_List{
  margin: 0;
  padding: 0 0 0 1.2rem;
}

.offer_Conditions_List li{
  color: #08090A;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 0.5rem;
}


.catelogue_Page {
  margin: 1rem 0 0;
  background: #ffffff;
  padding: 1rem 0.5rem;
}

.catelogue_Page_Header {
  margin: 0 0 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: space-between;
}

.catelogue_Header_Buttons {
  display: flex;
  justify-content: space-between;
}

.catelogue_Page_Heading {
  margin: 0;
  color: #08090a;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.catelogue_Form {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.catelogue_Form_Group {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.catelogue_Page_Header .button-Container {
  min-width: 120px;
  margin: 0 10px 0 0;
}

.catelogue_Page_List {
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin: 0.75rem 0;
}

.catalogue_List_Item {
  display: flex;
  padding: 0.5rem;
  border: 1px solid #ebedf0;
  border-radius: 0.25rem;
  justify-content: space-between;
  align-items: center;
}

.catalogue_List_Item p {
  margin: 0;
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.catalogue_List_Item p:first-of-type {
  width: 160px;
}

.catalogue_List_Content,
.catalogue_List_Buttons {
  display: flex;
}

.catalogue_List_Content {
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.catalogue_List_Buttons {
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.catalogue_Edit,
.catalogue_Delete {
  text-decoration: none;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}

.catalogue_Edit {
  background: #3d5176;
}

.catalogue_Edit:hover {
  color: #ffffff;
}

.catalogue_Delete {
  background: red;
}

.catalogue_Hint {
  margin: 0 0 0 0.5rem;
  color: #5f6368;
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.catelogue_Fieldset {
  /* padding: 0.75rem 0; */
  display: flex;
  flex-direction: row;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-flow: wrap;
}

.catelogue_Fieldset .dropdown .dropdown-toggle {
  background: transparent !important;
  border: 1px solid #ced4da !important;
  box-shadow: none !important;
}

.catelogue_Fieldset .dropdown .dropdown-toggle::after {
  border: none;
  display: none;
}

.catelogue_Fieldset .dropdown .dropdown-toggle:hover {
  background: transparent;
  border: 1px solid #ced4da;
}

.catelogue_Fieldset .show > .btn.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
  background: transparent;
  border: 1px solid #ced4da;
}

.catelogue_Fieldset .show > .dropdown-toggle.btn.btn-primary:focus,
.catelogue_Fieldset .show > .dropdown-toggle.btn.btn-primary:focus-visible {
  box-shadow: none;
  background: transparent;
  border: 1px solid #ced4da;
}

.catalogue_Dropdown {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  justify-content: space-between;
  align-items: center;
}

.catalogue_Dropdown span,
.catalogue_Dropdown p {
  color: #6d747a;
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.catalogue_Dropdown p {
  color: #08090a;
}

.catelogue_Product_Search {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.catelogue_Product_Search .button-Container button {
  max-width: 95%;
}

.catelog_Product_Info {
  display: flex;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  justify-content: space-between;
  margin: 1rem 0;
}

.catelog_Product_Info .product_Info_Left {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.catelog_Product_Info .product_Info_Left p {
  margin: 0;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.catelog_Product_Info .product_Info_Right {
  max-width: 100px;
  max-height: 100px;
}

.catelog_Product_Info .product_Info_Right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .catelogue_Product_Search > *{
  width: 50%;
} */

.addoffer_Input {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.addoffer_Input p {
  margin: 0;
}

.addoffer_Input .addoffer_Input2 {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.Catalogue_Section_Heading {
  margin: 0;
}

.catalogue_Image_Preview {
  flex-direction: row;
  flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
}

.input_Delete {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.input_Delete img {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.addoffer_Container p {
  margin: 0.5rem 0;
}

.catelogue_Table {
  overflow-x: scroll;
  margin: 15px 0px;
}

.catelogue_Table thead,
.catelogue_Table tr,
.catelogue_Table td,
.catelogue_Table th {
  padding: 0.5rem;
  border: 1px solid black;
  white-space: nowrap;
}

.DatePicker__calendarContainer {
  z-index: 200;
}

.addoffer_Input .addoffer_Input2.bank_offer_heading {
  justify-content: flex-start;
}

.offerPage_ProductList {
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.offerPage_ProductList p {
  width: 230px;
}

.offerPage_Hint {
  margin: 0 0 1.25rem 0.5rem;
}

.offer_Section {
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.offer_Section .addoffer_Input {
  width: 100%;
}

.offer_Section .offerPage_Hint,
.offer_Section .catalogue_Hint {
  align-self: flex-start;
}

.catelogue_Search_Input{
  grid-gap: 1rem;
  gap: 1rem;
}

.catelogue_Search_Input .button-Container{
  width: 50%;
}

.addProduct_Preview_Modal_Body p{
  margin: 0;
}

.addProduct_Preview_Modal_Body .addProduct_Modal_Values{
  font-size: 1.125rem;
  font-weight: 500;
}

.addProduct_Preview_Modal_Body .addProduct_Modal_Section{
  margin: 0.5rem 0 0.75rem;
}

.addProduct_Preview_Modal_Body .addProduct_Modal_Section_Gallery_Images{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
}

.addProduct_Preview_Modal_Body .addProduct_Modal_Section_Gallery_Images div{
  max-width: 450px;
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .catelogue_Page {
    padding: 1.5rem;
    width: 100%;
  }

  .catelogue_Page_Header .button-Container {
    min-width: 220px;
  }

  .catalogue_List_Item {
    padding: 0.75rem 1rem;
  }

  .catalogue_List_Content {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .catalogue_List_Buttons {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .catalogue_List_Item p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .catalogue_List_Item p:first-of-type {
    width: 230px;
  }

  .catalogue_Edit,
  .catalogue_Delete {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.75rem 1.25rem;
  }

  .catelogue_Product_Search {
    flex-direction: row;
  }

  .catelogue_Product_Search input.input-field,
  .addoffer_Input .addoffer_Input2.input-field {
    max-width: 60%;
  }

  .catelogue_Product_Search .button-Container,
  .addoffer_Input .addoffer_Input2 .button-Container {
    width: 35%;
  }

  .catelog_Product_Info .product_Info_Left {
    grid-gap: 1rem;
    gap: 1rem;
  }

  .catelog_Product_Info .product_Info_Left p {
    font-size: 1rem;
  }

  .catelog_Product_Info .product_Info_Right {
    max-width: 200px;
    max-height: 200px;
  }

  .addoffer_Input .addoffer_Input2 {
    flex-direction: row;
    justify-content: space-between;
  }

  .input_Delete {
    align-items: center;
    justify-content: center;
  }

  .catelogue_Product_Search .addoffer_Input2 {
    width: 100%;
  }

  .catelogue_Product_Search .addoffer_Input2 .input-field {
    max-width: 100%;
  }
}

.image-box img {
   object-fit: contain;
   aspect-ratio: 1/1;
   width: 150px;
}
.image-container {
  position: relative;
  padding: 7px 0;
  outline-offset: 5px;
  outline: 2px dashed black;
}

.image-container:where(:hover, :focus-visible) {
  scale: 1.12;
  background-color: rgb(255 230 209 / 80%);
}

.image-cross {
  position: absolute;
  top: -24px;
  font-size: 40px;
  right: 0;
  cursor: pointer;
  font-weight: bolder;
}
.modal-open{
  overflow-y: auto !important;
  padding-right: 0 !important;
}
.modal.show .modal-dialog{
    -webkit-transform: translate(0px, 70px) !important;
            transform: translate(0px, 70px) !important;
  }

.modal-header{
  border: none !important;
}

.modal-content{
  border-radius: 8px !important;
}

.modal_heading{
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

.modal_header_desc{
  color: #6D747A;
  letter-spacing: 0.004em;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin: 0;
}

.btn-close{
  display: none;
}

.modal-body{
  padding: 0.5rem 1rem 1.5rem 1rem !important;
}

.modal-body a{
  text-decoration: none;
}

.modal-footer{
  flex-direction: column;
  align-items: flex-start !important;
  grid-gap: 12px;
  gap: 12px;
  border-top: 1px solid #CED4DA !important;
}

.modal_footer_link{
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.modal_footer_link form{
  display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.modal_footer_link .header_Pincode_Input{
  width: 65%;
  margin: 0 30px 0 0;
}

.modal_footer_link .header_Submit_Btn p {
  color: #FFFFFF;
}

.modal_footer_link img{
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.modal_footer_link p {
  letter-spacing: -0.5px;
  color: #1B325E;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin: 0;
}

.modal{
  z-index: 3000 !important;
}

.header_Pincode_Section{
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  /* justify-content: space-between; */
  margin: 1.125rem 0 0;
}

.header_Pincode_Input{
  width: 100%;
}

.header_Pincode_Input input{
  border: 1px solid #1B325E;
  border-radius: 0.25rem;
}

.header_Submit_Btn{
  min-width: 128px;
  width: 100%;
  max-width: 128px;
  margin: 0 -2.813rem 0 0;
}

.modal_Image_Preview{
  max-width: 275px;
  /* max-height: 300px; */
  margin: 0 auto 1rem;
}

.modal_Image_Preview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (min-width: 768px) {
  .modal.show .modal-dialog{
    -webkit-transform: translate(0px, 75px) !important;
            transform: translate(0px, 75px) !important;
  }

  .location_Modal .modal-dialog, .pincode_Modal .modal-dialog{
    max-width: 600px;
  }

  .location_Modal .modal-header .btn-close{
    margin: -2rem -.5rem 1.25rem auto ;
  }
  .pincode_Modal .modal-header .btn-close {
    margin: -2rem -.5rem 4.25rem auto;
  }

  .btn-close{
    display: block;
    opacity: 1 !important;
  }

  .update_Heading .btn-close{
    opacity: 0.5 !important;
  }

  .location_Modal .modal-content{
    padding: 1.5rem 1.125rem;
  }

  .pincode_Modal .modal-content {
    padding: 1.5rem 1.125rem;
  }

  .location_Modal .modal-header{
    padding: 0 0.375rem 1.125rem;
  }

  .location_Modal .modal-body{
    padding: 0 0.375rem 1.5rem;
  }

  .pincode_Modal .modal-header{
    padding: 0 0.375rem 1.5rem;
  }

  /* .modal-header{
    padding: 1.5rem 1.5rem 1rem 1.5rem !important;
  } */

  /* .modal-body{
    padding: 0.125rem 1.5rem 1.5rem !important;
  } */

  .modal_heading{
    font-size: 20px;
    line-height: 28px;
  }

  .modal_header_desc{
    font-size: 14px;
    line-height: 20px;
  }

  /* .modal-footer{
    padding: 1.5rem !important;
  } */

    .location_Modal .modal-footer,
    .pincode_Modal .modal-footer {
      padding: 1.5rem 0.375rem 0;
    }

  .modal-footer > *{
    margin: 0 !important;
  }

  .modal_footer_link img{
    max-width: 24px;
    max-height: 24px;
  }

  .modal_footer_link p{
    font-size: 16px;
    line-height: 24px;
  }

  .update_Heading{
    padding: 1.5rem 0.75rem 0.75rem !important;
    border-bottom: 1px solid #DDE0E7 !important;
    margin: 0 0.75rem;
  }

    .pincode_Modal .modal-header .header_content{
      width: 100%;
    }

  .pincode_Modal .modal-header .header_content .header_Pincode_Section .header_Pincode_Input{
    max-width: 413px;
  }
}
.product_Information_Table{
  width: 100% !important;
  background-color: #FFFFFF;
  border-collapse: collapse;
  color: #000000;
  border-radius: 10px;
}

.product_Information_Table td{
  border-bottom-width: 1px;
  border-bottom-color: #707070;
  border-bottom-style: solid;
  padding: 0.5rem;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 5%;
}

.product_Information_Table .product_Table_Row:first-child td {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.product_Information_Table .product_Table_Row:last-child td {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-style: none;
}

.product_Information_Table .product_Table_Row:last-child td:last-child {
  border-bottom-right-radius: 10px;
}

.product_Table_Key{
  background: #F8F9FA;
  color: #08090A;
  font-weight: 600 !important;
}

@media screen and (min-width: 768px) {

  .product_Information_Table td{
    padding: 0.75rem;
    padding-left: 5%;
  }
}

@media screen and (min-width: 1200px) {

  .product_Information_Table td{
    padding: 0.75rem 1.25rem;
    padding-left: 5%;
  }
}
.customer_Support_Container{
  background: #FFFFFF;
  padding: 1rem 0.75rem 1.5rem;
  margin: 0;
}

.options_Container{
  margin: 1rem 0 1rem;
  background: #FFFFFF;
  padding: 1.5rem 0.75rem;
  position: relative;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
}

.support_Options{
  margin: 0;
  padding: 0;
  transition: all 0.3s ease-in-out;
}

.support_Form_Heading{
  padding: 0 0.25rem 0.75rem;
  margin: 0 0 1.125rem;
  border-bottom: 1px solid #DDE0E7;
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.support_Form_Heading span{
  display: none;
  cursor: pointer;
}

.support_Form_Input{
  width: 100%;
  padding: 1rem;
  margin: 0 0 1.5rem;
}

.support_Form_Query{
  /* margin: 0rem 0 2rem; */
  min-height: 40px;
  min-width: 330px;
  resize: none;
}

.support_Form_Submit_Container{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_Extra_Option{
  padding: 1rem;
  background: #EBEDF0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 0 1rem;
}

.support_Extra_Option div{
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
}

.support_Extra_Option p{
  margin: 0;
  color: #1B325E;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.support_Extra_Option img{
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.support_Query_Answer{
  padding: 1rem 0.75rem;
  background: #FFFFFF;
  margin: 1rem 0 1rem;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translateX(100vw);
          transform: translateX(100vw);
  transition: all 0.25s ease-in-out;
}

.support_Query_Heading{
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0 0.25rem 0.75rem;
  border-bottom: 1px solid #DDE0E7;
  margin: 0 0 0.75rem;
}

.support_Query_Heading p{
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.support_Query_Heading img{
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.support_Query_Desc{
  color: #6D747A;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0 0.25rem;
}

.option_Slide{
  -webkit-transform: translateX(-100vw);
          transform: translateX(-100vw);
}

.query_Active{
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.support_Page_Temp{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background: #FFFFFF;
  position: relative;
}

.options_Header{
  display: none;
}

.contact_Phone_Container{
  margin: 0 auto 21px auto;
  padding: 0.375rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.125rem 0.125rem rgba(0, 0, 0, 0.02);
border-radius: 0.25rem;
background: #F8F9FA;
}

.contact_Phone_Details p{
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #1B325E;
}

.contact_Phone_Details p:last-child{
  color: #546586;
  margin: 0.25rem 0 0;
}

.contact_Phone_Icon{
  padding: 0.625rem;
  background: #DDE0E7;
  border-radius: 50%;
}

.faq_Option{
  opacity: 1;
}

.customer_Support_Form {
  padding: 0.375rem 1rem;
}

.parentClassCustomerSupport{
  width: 100vw;
  padding: 19px;
  margin-top: var(--pageMarginTop);
}

.CustomerSupportMainSection{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
}

.CustSuppoLeftimg{
  width: 48%;
  margin: auto;
}

.CustSuppoRightimg{
  width: 100%;
}

.CustomerSupportHeader{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 31px;
  color: #000000;
}

.CustomerSupportHeadingText{
  font-size: 25px;
  margin-bottom: 20px;
} 

@media screen and (min-width: 768px) {
  .support_Page_Wrapper{
    padding: 0;
  }

  /* .options_Container, .support_Extra_Option{
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 4px;
    border: 1px solid #DDE0E7;
  } */

  .customer_Support_Container{
    margin: 0;
    padding: 3.75rem 8.75rem;
  }

  .customer_Support_Form{
    padding: 1.5rem 0.75rem;
  }

  .support_Form_Heading{
    display: flex;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    letter-spacing: -0.01em;
    font-weight: 600;
    padding: 0 0.75rem 1rem;
    margin: 0 0 1.5rem;
  }

  .support_Form_Heading span{
    display: block;
    max-width: 24px;
    max-height: 24px;
    height: 100%;
    width: 100%;
  }

  .support_Form_Heading span img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .form_Input_Area{
    padding: 0 3.5rem;
  }

  .options_Header{
    display: block;
    padding: 0rem 0.75rem 0.75rem;
    margin: 0 0 1.5rem;
    border-bottom: 0.063rem solid #DDE0E7;
    transition: all 0.3s ease-in-out;
  }

  .options_Header p{
    margin: 0;
    letter-spacing: -0.01em;
    color: #1B325E;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .support_Options .profile_Option{
    padding: 0 0.75rem 1rem;
    margin: 0 0 1rem;
    border: none;
  }

  .support_Options .profile_Option p{
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .support_Options .profile_Option:last-child{
    padding: 0 0.75rem 0.5rem;
    margin: 0;
  }

  .support_Extra_Option{
    background: #EBEDF0;
    padding: 1.875rem 1.5rem;
    margin: 0 0 3rem;
  }

  .support_Extra_Option p{
    color: #3D5176;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .contact_Phone_Container{
    display: none;
  }

  .parentClassCustomerSupport{
    width: 100vw;
    padding: 19px 153px;
  }

  .CustomerSupportMainSection{
    display: flex;
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
  }
  .HeadingText{
    font-size: 25px;
  }
}

@media screen and (min-width: 1024px) {

  .options_Container{
    max-width: 650px;
    margin: 3.75rem auto 2rem;
  }

  .customer_Support_Container{
    padding: 0;
    max-width: 650px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
  }

  .customer_Support_Form{
    padding: 1.5rem 0.75rem 2rem;
  }

  .support_Form_Heading{
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin: 0 0 2rem;
  }

  .support_Form_Input{
    margin: 0 0 2rem;
  }

  .support_Extra_Option{
    max-width: 650px;
    margin: 0 auto 3.75rem;
  }
}
.section-1{
    background-color:#142A53;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 50px 10%;
    grid-gap: 2px;
    gap: 2px;
}
.sec1Parent{
    background-color:#142A53;
    width: 100vw;
    display: flex;
    flex-direction: column;
    grid-gap: 24;
    gap: 24;
}
.sec1Learn{
    color: white;
    display: flex;
    justify-content: flex-end;
    margin-right: 4%;
}
.section-1 span{
    color: white;
}
.firstSearch{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    width: 60%;
}
.secondSearch{
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-gap: 10px;
    gap: 10px;
    width: 30%;
}
.serachIcon{
    position: relative;
    left: 3rem;
    z-index: 9;
    /* display: flex; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.section-2{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 30px;
    gap: 30px;
    width: 100vw;
    background-color: #F2F2F2;
    justify-content: center;
    align-items: center;
    padding: 80px 0;
}
.OpportunityCard{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 50px;
    width: 25%;
    text-align: center;
    background-color: #D9D9D9;
    border-radius: 10px;
    flex-wrap: wrap;
}
.headingOC{
    font-size: 20px;
    font-weight: 500;
}

.OCPara{
    font-size: 15px;
    font-weight: 600;
    width: 80%;
    color: #968080;
}
.EOClass{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}
.EO-image{
    width: 400px;
    height: 400px;
    border-radius: 10px;
    object-fit: cover;
}

.section-3{
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
}
.EO-text{
    text-align: center;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: 500;
}

.gradientClass{
    background: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 65.17%);
    z-index: 999;
}
.section-4{
    padding: 20px 10%;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    gap: 20px;
}
.sec4main{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    grid-gap: 2px;
    gap: 2px;
    background-color: #F2F2F2;
    padding: 20px 0;

}

.sec4Heading{
    font-size: 30px;
    font-weight: 600;
    margin-left: 3%;
}
.sec4Para{
    font-size: 19px;
    font-weight: 700;
    color: #707070;
}
.profileImageCareer{

font-size: 80px;
width: 20%;
}
.parentProfile{
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    justify-content: center;
}
.section-5{
    height: 600px;
    display: flex;
    flex-direction: row;
    width: 100%;
}
.section-5 img{
    width: 50%;
    object-fit: cover;
}

.section-5 span{
    width: 50%;
    background-color: #1B325E;
    color: white;
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 80px;
    padding: 0 20%;
    line-height: 90px;
}

.DesignationInput{
    background-color: white;
    border-radius: 10px;
    width: 90%;
    height: 60px;
    padding-left: 10%;
}
.headerText{
    font-size: 40px;
    font-weight: 400;
    color: white;
    font-style: normal;
}
.verticalLine{
    border-left: 2px solid white;
    height: 40px;
    margin: 0 10px;
}
.JobBtn{
    font-size: 20px;
    font-weight: 500;
    color: white;
    cursor: pointer;
    right: 2%;
    position: absolute;
}

@media screen and (max-width:768px) {
    .OpportunityCard{
        display: flex;
        flex-direction: column;
        grid-gap: 10px;
        gap: 10px;
        justify-content: center;
        align-items: center;
        padding: 50px;
        width: 100%;
        text-align: center;
        background-color: #D9D9D9;
        border-radius: 10px;
        flex-wrap: wrap;
    }
    .EO-image{
        width: 350px;
        height: 350px;
        border-radius: 10px;
        object-fit: cover;
    }
    .sec4Para{
        font-size: 12px;
        font-weight: 700;
        color: #707070;
        width: 100%;
    }
    .profileImageCareer{
        width: 50%;
    }
    .parentProfile{
        display: flex;
        flex-direction: column;
        grid-gap: 20px;
        gap: 20px;
        align-items: center;
        justify-content: center; 
    }
    .section-2{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        grid-gap: 30px;
        gap: 30px;
        width: 100vw;
        background-color: #F2F2F2;
        justify-content: center;
        align-items: center;
        padding: 50px 20px;
    }
    .sec4Heading{
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }
    .section-5 span{
        width: 50%;
        background-color: #1B325E;
        color: white;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 50px;
        padding: 0 20%;
        line-height: 60px;
    }
    .firstSearch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
        width: 100%;
    }
    .section-1{
        background-color:#142A53;
        width: 100vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 50px 5%;
        grid-gap: 20px;
        gap: 20px;
    }
    .DesignationInput{
        background-color: white;
        border-radius: 10px;
        width: 90%;
        height: 60px;
        padding-left: 3.5rem;
    }
    .secondSearch{
        display: flex;
        flex-direction: column;
        justify-content: center;
        grid-gap: 10px;
        gap: 10px;
        width: 89%;
    }
    .JobBtn{
        font-size: 10px;
        font-weight: 500;
        color: white;
        cursor: pointer;
        right: 3%;
        position: absolute;
    }
}
/* .App {
  display: flex;
  width: 100%;
  align-items: stretch;
} */

.DashboardWrapper {
  display: flex !important;
  width: 100%;
  align-items: stretch;
}

.dashboard_Whole_Wrapper {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: "Nunito Sans", sans-serif;
  /*background: #fafafa;*/
  background-color: #f5f9fc;
  min-width: 100%;
}

p.ml-3 {
  margin-left: 1rem !important;
}

/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
} */

/* a tag */
.dash_sidebar_a,
.dash_sidebar_a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.dash_sidebar_a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  position: fixed !important;

  left: 0;
  height: -webkit-fill-available;
  z-index: 1;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
}

.sidebar-header {
  background: #1b325e;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header>button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  min-height: 100vh;
}

.content.is-open {
  margin-left: 250px;
}

@media only screen and (max-width: 575.98px) {
  .DashboardWrapper {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
    position: relative !important;
  }

  .sidebar.is-open>.sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.container a {
  text-decoration: none;
}

.dropdown-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.dropdown-btn p {
  margin-bottom: 0;
}

.content:where(.is-open)>.container {
  width: 950px;
}
.order-dialog-box .css-2zwj5t-MuiPaper-root-MuiDialog-paper.MuiDialog-paperScrollBody {
 min-width: 80vw!important;
}
.catelogue_Pagee{
    margin: 1rem 0 0;
    background: #FFFFFF;
    padding: 1rem 1.5rem;
  }
  
  .catelogue_Page_Header{
    margin: 0 0 1.5rem;
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
  }
  
  .catelogue_Header_Buttons{
    display: flex;
    justify-content: space-between;
  }
  
  .catelogue_Page_Heading{
    margin: 0;
    color: #08090A;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  
  .catelogue_Form{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .catelogue_Form_Group{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .catelogue_Page_Header .button-Container{
    min-width: 120px;
    margin: 0 10px 0  0;
  }
  
  .catelogue_Page_List{
    display: flex;
    flex-direction: column;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    margin: 0.75rem 0;
  }
  
  .catalogue_List_Item{
    display: flex;
    padding: 0.5rem;
    border: 1px solid #EBEDF0;
    border-radius: 0.25rem;
    justify-content: space-between;
    align-items: center;
  }
  
  .catalogue_List_Item p{
    margin: 0;
    color: #08090A;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .catalogue_List_Item p:first-of-type{
    width: 160px;
  }
  
  .catalogue_List_Content, .catalogue_List_Buttons{
    display: flex;
  }
  
  .catalogue_List_Content{
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
  
  .catalogue_List_Buttons{
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .catalogue_Edit, .catalogue_Delete{
    text-decoration: none;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #FFFFFF;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .catalogue_Edit{
    background: #3D5176 ;
  }
  
  .catalogue_Edit:hover{
    color: #FFFFFF;
  }
  
  .catalogue_Delete{
    background: red;
  }
  
  .catalogue_Hint{
    margin: 0 0 0 0.5rem;
    color: #5f6368;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
  }
  
  .catelogue_Fieldset{
    /* padding: 0.75rem 0; */
    display: flex;
    flex-direction: row;
    grid-gap: 0.5rem;
    gap: 0.5rem;
    flex-flow: wrap;
  }
  
  .catelogue_Fieldset .dropdown .dropdown-toggle {
    background: transparent !important;
    border: 1px solid #CED4DA !important;
    box-shadow: none !important;
  }
  
  .catelogue_Fieldset .dropdown .dropdown-toggle::after {
    border: none;
    display: none;
  }
  
  .catelogue_Fieldset .dropdown .dropdown-toggle:hover {
    background: transparent;
    border: 1px solid #CED4DA;
  }
  
  .catelogue_Fieldset .show>.btn.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: transparent;
    border: 1px solid #CED4DA;
  }
  
  .catelogue_Fieldset .show>.dropdown-toggle.btn.btn-primary:focus,
  .catelogue_Fieldset .show>.dropdown-toggle.btn.btn-primary:focus-visible {
    box-shadow: none;
    background: transparent;
    border: 1px solid #CED4DA;
  }
  
  .catalogue_Dropdown {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
  }
  
  .catalogue_Dropdown span,
  .catalogue_Dropdown p {
    color: #6D747A;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }
  
  .catalogue_Dropdown p {
    color: #08090A;
  }
  
  .catelogue_Product_Search{
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem
  }
  
  .catelogue_Product_Search .button-Container button{
    max-width: 95%;
  }
  
  .catelog_Product_Info{
    display: flex;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    justify-content: space-between;
    margin: 1rem 0;
  }
  
  .catelog_Product_Info .product_Info_Left{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .catelog_Product_Info .product_Info_Left p {
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  
  .catelog_Product_Info .product_Info_Right{
    max-width: 100px;
    max-height: 100px;
  }
  
  .catelog_Product_Info .product_Info_Right img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* .catelogue_Product_Search > *{
    width: 50%;
  } */
  
  .addoffer_Input{
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }
  
  .addoffer_Input p{
    margin: 0;
  }
  
  .addoffer_Input .addoffer_Input2 {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .Catalogue_Section_Heading{
    margin: 0;
  }
  
  .catalogue_Image_Preview{
    flex-direction: row;
    flex-wrap: wrap;
    grid-gap: 1rem;
    gap: 1rem;
  }
  
  .input_Delete{
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  
  .input_Delete img{
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
  
  .addoffer_Container p{
    margin: 0.5rem 0;
  }
  
  .catelogue_Table{
    overflow-x: scroll;
    padding: 10px;
  }
  
  .catelogue_Table thead, .catelogue_Table tr, .catelogue_Table td, .catelogue_Table th{
    padding: 0.5rem;
    border: 1px solid black;
    white-space: nowrap;
  }
  
  .DatePicker__calendarContainer{
    z-index: 200;
  }
  
  .addoffer_Input .addoffer_Input2.bank_offer_heading{
    justify-content: flex-start;
  }
  
  @media screen and (min-width: 768px) {
    .catelogue_Pagee{
      padding: 1.5rem;
      max-width: 680px;
      width: 100%;
      margin: 2rem auto;
    }
  
    .catelogue_Page_Header .button-Container {
      min-width: 220px;
    }
  
    .catalogue_List_Item {
      padding: 0.75rem 1rem;
    }
  
    .catalogue_List_Content {
      grid-gap: 1rem;
      gap: 1rem;
    }
  
    .catalogue_List_Buttons {
      grid-gap: 1rem;
      gap: 1rem;
    }
  
    .catalogue_List_Item p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
  
    .catalogue_List_Item p:first-of-type {
      width: 230px;
    }
  
    .catalogue_Edit,
    .catalogue_Delete {
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0.75rem 1.25rem;
    }
  
    .catelogue_Product_Search {
      flex-direction: row;
    }
  
    .catelogue_Product_Search input.input-field, .addoffer_Input .addoffer_Input2.input-field{
      max-width: 60%;
    }
  
    .catelogue_Product_Search .button-Container, .addoffer_Input .addoffer_Input2 .button-Container{
      width: 35%;
    }
  
    .catelog_Product_Info .product_Info_Left {
      grid-gap: 1rem;
      gap: 1rem;
    }
  
    .catelog_Product_Info .product_Info_Left p {
      font-size: 1rem;
    }
  
    .catelog_Product_Info .product_Info_Right {
      max-width: 200px;
      max-height: 200px;
    }
  
  .addoffer_Input .addoffer_Input2 {
      flex-direction: row;
      justify-content: space-between;
    }
  
      .input_Delete {
        align-items: center;
        justify-content: center;
      }
  
      .catelogue_Product_Search .addoffer_Input2 {
        width: 100%;
      }
  
      .catelogue_Product_Search .addoffer_Input2 .input-field{
        max-width: 100%;
      }
  }
  
  @media screen and (min-width: 1024px) {
    .catelogue_Pagee{
      max-width: 980px;
    }
  }
.borderless{
    border: none;
    border-bottom-width: 0.2rem;
    border-radius: 0 !important;
}
.filter-list-active{
}
.card-counter {
  box-shadow: 2px 2px 10px #dadada;
  margin: 5px;
  padding: 20px 10px;
  background-color: #fff;
  height: 100px;
  border-radius: 5px;
  transition: 0.3s linear all;
}

.card-counter:hover {
  box-shadow: 4px 4px 20px #dadada;
  transition: 0.3s linear all;
}

.card-counter.primary {
  background-color: #007bff;
  color: #fff;
}

.card-counter.danger {
  background-color: #ef5350;
  color: #fff;
}

.card-counter.success {
  background-color: #66bb6a;
  color: #fff;
}

.card-counter.info {
  background-color: #26c6da;
  color: #fff;
}

.card-counter i {
  font-size: 5em;
  opacity: 0.2;
}

.card-counter .count-numbers {
  right: 35px;
  top: 20px;
  font-size: 32px;
  display: block;
}

.card-counter .count-name {
  right: 35px;
  top: 65px;
  font-style: italic;
  text-transform: capitalize;
  opacity: 0.5;
  display: block;
  font-size: 18px;
}

.table {
  background: white;
  border-radius: 0.2rem;
  width: 100%;
  padding-bottom: 1rem;
  /* color: #212529; */
  margin-bottom: 0;
}

.table th:first-child,
.table td:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: white;
  /* color: ; */
}

.table td {
  white-space: nowrap;
}

.table-icon {
  margin-right: 0.4rem;
}

.banner-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 15px;
  gap: 15px;
  margin: 30px 0;
  padding: 20px 15px;
  padding-top: 40px;
  position: relative;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.banner-product-card img {
  width: 400px;
  min-width: 400px;
}
.banner-product-card p {
  cursor: default;
  font-size: 18px;
}
.banner-product-card a {
  text-decoration: none;
}

.banner-product-card .icons {
  position: absolute;
  right: 18px;
  top: 12px;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}
.equal-children,
.equal-children > * {
  flex-basis: 100%;
  text-align: center;
}
.top-scroll-banners-section {
  background-color: rgb(223, 227, 237);
  padding: 20px 10px;
  font-size: 20px;
  width: 100%;
  color: black !important;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
}

.home-page-section-body {
  background-color: rgb(223, 227, 237);
  width: 100%;
  min-width: auto;
}
.home-page-section-body p {
  margin: 0;
}

.home-page-section-aside {
  display: flex;
  padding: 0 10px;
  background-color: rgb(223, 227, 237);
  flex-direction: column;
  justify-content: space-around;
}

.DashboardIFD_form-input__3Fx7A {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 10px;
  height: 48px;
  max-width: 400px;
  font-family: "Montserrat";
  margin-bottom: 10px;
  transition: 0.1s;
  border-radius: 10px;
}
.DashboardIFD_form-input__3Fx7A.DashboardIFD_invalid__m25cB {
  border: #ff1200f5 2px solid;
  color: #cc1507f5;
}
.DashboardIFD_form-input__3Fx7A.DashboardIFD_invalid__m25cB::-webkit-input-placeholder {
  color: #cc14078f !important;
}
.DashboardIFD_form-input__3Fx7A.DashboardIFD_invalid__m25cB::placeholder {
  color: #cc14078f !important;
}
.DashboardIFD_form-input__3Fx7A:focus-within {
  outline: none;
}
.DashboardIFD_form-input__3Fx7A::-webkit-input-placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #8d9091;
}
.DashboardIFD_form-input__3Fx7A::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #8d9091;
}
.DashboardIFD_form-input__3Fx7A:disabled {
  cursor: not-allowed;
}

.DashboardIFD_chart-container__2TT23 {
  margin-top: 30px;
}

.DashboardIFD_primary-btn__q4l5s {
  outline: none;
  margin-left: 15px;
  padding: 6px 13px;
  border: none;
  border-radius: 20px;
  background-color: #7336cfb5;
  color: white;
  font-family: "Montserrat";
  font-size: 16px;
}
.DashboardIFD_primary-btn__q4l5s:hover {
  background-color: #7336cfd8;
}
.DashboardIFD_secondary-btn__17yB_ {
  outline: none;
  /* margin-top: 10px; */
  padding: 5px 10px;
  border: none;
  border-radius: 20px;
  background-color: #3692cfbd;
  color: white;
  font-family: "Montserrat";
  font-size: 13px;
}
.DashboardIFD_secondary-btn__17yB_:hover {
  background-color: rgba(54, 174, 207, 0.827);
}

.DashboardIFD_date-picker-container__1YgkY {
  margin-top: 15px;
  display: flex;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
  font-family: "Montserrat";
}
.DashboardIFD_date-picker-element__2UaT5 {
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.DashboardIFD_date-picker-element__2UaT5 h6 {
  font-size: 14px;
  width: 120px;
  margin: 0;
}
.DashboardIFD_date-picker-element__2UaT5 input {
  padding: 3px 8px;
  font-family: "Courier New", Courier, monospace;
  font-weight: 500;
  border-radius: 10px;
}

.DashboardIFD_chart-wrapper__1WRbo {
  max-width: 1200px;
  margin-top: 20px;
}

@media screen and (max-width: 700px) {
  .DashboardIFD_date-picker-container__1YgkY {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-gap: 10px;
    gap: 10px;
  }
}

.edit_Page_Wrapper {
  padding: 0 0 7rem;
}
.profile_edit_form {
  background: #ffffff;
  padding: 1.5rem 0.938rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin: 0 0 1rem;
}

.edit_input_container {
  position: relative;
  width: 100%;
}

.edit_input_container .input-field {
  width: 100%;
  border: 1px solid #dde0e7;
  background: #ffffff;
  border-radius: 0.25rem;
  padding: 1rem;
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.edit_input_label {
  position: absolute;
  top: -8px;
  left: 12px;
  color: #08090a;
  background: #ffffff;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 2px;
  z-index: 110;
}

.edit_input_update {
  border: none;
  background-color: transparent;
  letter-spacing: 0.004em;
  color: #1b325e;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  position: absolute;
  top: 50%;
  right: 13px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  text-decoration: none;
}

.update_Container {
  background: #ffffff;
  margin: 1rem 0 9rem;
}

.update_Wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.update_Info_Details {
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 1rem;
}

.otp_Alternate {
  color: #1b325e;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin: 0.25rem 0 0;
  float: right;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .edit_Page_Wrapper {
    background: #ffffff;
    border-radius: 0.25rem;
    /* margin: 1rem 0 0; */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .update_Page_Wrapper {
    padding: 0;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .update_Container {
    margin: 0.5rem 0 0;
    padding: 0 0 1.5rem;
  }

  .profile_edit_form,
  .edit_Extra_Options {
    border-radius: 0.25rem;
  }

  .profile_edit_form {
    border: 1px solid #dde0e7;
    padding: 2rem 1.5rem;
  }

  .edit_Extra_Options {
    background: #ffffff;
    padding: 0;
    grid-gap: 1rem;
    gap: 1rem;
    border: none;
  }

  .edit_Extra_Options .edit_Profile_Option {
    border: 1px solid #dde0e7;
    padding: 1.125rem 2rem 1.125rem 1.5rem;
    box-shadow: 0px 0.125rem 0.125rem rgba(0, 0, 0, 0.02);
    background: #ffffff;
    margin: 0;
  }

  .edit_Profile_Option:first-child {
    display: none;
  }

  .profile_edit_form .edit_input_container,
  .profile_Submit_Button {
    max-width: 420px;
    margin: 0;
  }

  .update_Footer {
    position: relative;
  }

  .otp_Alternate {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin: 0.5rem 0 0;
  }
}

@media screen and (min-width: 1024px) {
  .profile_edit_form .edit_input_container,
  .profile_Submit_Button {
    max-width: 500px;
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  .profile_edit_form,
  .edit_Extra_Options {
    max-width: 69%;
  }
}

.prod {
  display: flex;
  /* border: 10px solid red; */
}

.products-component {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position:relative;
  /* height: 500px; */
}
.aside {
  background-color: #f2f2f2;
  width: 20%;
  margin-left: 1rem;
}
.aside > img {
  width: 100%;
}
.products {
  width: 80%;
  padding: 0 10px;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.pre-btn,
.next-btn {
    border: none;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width : 500px) {
  .products {
    overflow-x: scroll;
    width: 100%;
  }
  .products::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width :1024px) {
  .products-component {
    margin: 0;
  }
}
.product-card {
    width: 100%;
    display: flex;
    flex-basis: 21%;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    padding: 1rem 0.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
    min-width: 160px;
}

.product-img {
    color: initial;
    text-decoration: none;
}

a.product-img:hover {
    color: initial!important;
}

.tags-container {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    align-items: center;
    font-size: 0.9rem;
    width: 90%;
}

.tags {
    display: flex;
}

.tag-item {
    margin-right: 7px;
    padding: 0.2rem;
    border-radius: 5px;
}

.tag-discount {
    background-color: #1B325E;
    color: white;
    font-size: 8px;
}

.tag-best-seller {
    background-color: #FFCC0A;
    color: white;
}

.tag-fav {
    color: #FFCC0A;
}

.product-img {
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.product-img:not(:has(img)) {
    justify-content: start;
}

.product-img>img {
    width: 100%;
    height: 100%;
    /* aspect-ratio: 3/4; */
    /* object-fit: cover; */
    border: none;
}

.product-info {
    font-family: 'Quicksand', sans-serif;
    height: 100%;
    max-height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    line-height: 90%;
    padding: 10px;
}

.prod-name {
    font-weight: 700;
    margin-top: 5px;
    font-size: 16px;
}

.prod-desc {
    color: #3581C1;
    font-size: 14px;
    font-weight: 600;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}


.prod-discount-price {
    width: 100px;
    font-size: 1.5rem;
    font-weight: bold;
    color: #3581C1;

}

.emi-offer {
    display: flex;
    flex-direction: column;
    color: #3581C1;
    font-size: 0.68rem;
    justify-content: space-between;
    font-weight: bold;
    width: 100px;
    padding: 0.1rem;
    bottom: 70px;

}

#emi-bank-offer {
    margin-left: 4px;
}

.prod-buy {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    grid-gap: 3px;
    gap: 3px;
}

.prod-buy-btn {
    background-color: #FFCC0A;
    padding: 0.5rem;
    font-size: 12px;
    font-weight: 700;
    width: 100%;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: white;
}

.CardShoppingCartIcon {
    background-color: #1B325E;
    color: white;
    border-radius: 5px;
    padding: 6px;
    cursor: pointer;
    font-size: 33px;
}

.card-display-row {
    display: flex;
    flex-direction: row;
    margin-top: 2rem;
    margin-bottom: 2rem;
    overflow-x: scroll;
}

@media screen and (min-width: 580px) {
    .product-card {
        flex-basis: 48%;
    }
}

@media screen and (min-width: 768px) {
    .product-card {
        display: flex;
        flex-direction: column;
        margin: 0rem 0.15rem;
    }

    .product-img>img {
        border-radius: 6px;
    }

    .tags:has(:not(.tag-discount)) .tag-best-seller {
        margin-right: 0;
        padding: 4px 8px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .aside_res {
        min-width: 15vw !important;
    }
}

@media screen and (max-width: 450px) {
    .prod-name {
        font-size: 12px;
        text-overflow: ellipsis;
    }

    .product-card {
        margin: 0;
        margin-bottom: 0.75rem;
        /* width: 44%; */
        justify-content: center;
    }

    .prod-desc {
        font-size: 12px;
    }

    .tags-container {
        width: 95%;
    }

    .emi-offer {
        font-size: 0.6rem;
        font-weight: bold;
        flex-direction: column;
        width: 100%;
        padding: 0.1rem;
        bottom: 70px;

    }

    .prod-buy-btn {
        padding: 0.1rem;
    }

    #emi-bank-offer {
        margin-left: 0;
    }
}

@media screen and (min-width: 350px) and (max-width: 449px) {
    .product-card {
        width: 100%;
    }

    .tag-item {
        margin-right: 2px;
    }

    .Product_Category_Container_res {
        grid-row-gap: 1rem !important;
        row-gap: 1rem !important;
    }
}

@media screen and (max-width: 500px) {
    .tag-best-seller,.tag-discount {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 2px;
    }

    .tag-best-seller span,
    .tag-discount span {
        padding: 0 2px;
        font-size: 7px !important;
        font-weight: 600 !important;
    }
    .prod-discount-price {
        font-size: 1.1rem;
    }
}

@media screen and (min-width: 320px) and (max-width: 400px) {
    .product-card {
        min-width: 148px;
    }
}

@media screen and (min-width: 1000px) {
    .product-card {
        min-width: -webkit-min-content;
        min-width: min-content;
        margin-bottom: 1rem;
    }
}
/* styles.css */
@media (min-width: 600px) {
    .product-box-skeleton .skeleton-item {
      width: 320px;
    }
    .skeleton-item-child-1 {
        width: 280px;
    }
  }
  
  @media (max-width: 599px) {
    .product-box-skeleton {
        height: 250px;
    }
    .product-box-skeleton .skeleton-item {
      width: 200px;
    }
    .skeleton-item-child-1 {
        width: 150px;
    }
  }
  
.skeleton_Wrapper{
  position: relative;
  overflow: hidden;
  width: 100%;
}
.skeleton{
  background: #e4e4e4;
  border-radius: 1rem;
}

.skeleton.productBox {
  width: 150px;
  height: 216px;
}

.skeleton.productBanner {
  width: 100%;
  height: 260px;
}

.skeleton.productBannerSmall {
  width: 328px;
  height: 328px;
}

.skeleton.productTitle{
  width: 50%;
  height: 30px;
  border-radius: 0.5rem;
}

.skeleton.productPrice{
  width: 25%;
  height: 24px;
  border-radius: 0.5rem;
}

.skeleton.productPreviewImage {
  height: 424px;
  width: 100%;
  border-radius: 0;
}

.skeleton.productThumbnail{
  height: 80px;
  width: 80px;
  border-radius: 0;
}

.shimmer_Wrapper{
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-animation: loading 1.2s infinite;
          animation: loading 1.2s infinite;
}

.shimmer{
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0 0 36px 36px rgba(255, 255, 255, 0.1);
}

@-webkit-keyframes loading {
  0% { -webkit-transform: translateX(-150%); transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%);
  }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}

@keyframes loading {
  0% { -webkit-transform: translateX(-150%); transform: translateX(-150%); }
  50% {
    -webkit-transform: translateX(-60%);
            transform: translateX(-60%);
  }
  100% {
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}

@media screen and (min-width: 768px) {
  .skeleton.productBox {
    width: 312px;
    height: 356px;
  }
  
  .skeleton.productBanner {
    width: 100%;
    height: 400px;
  }
  
  .skeleton.productBannerSmall {
    width: 1120px;
    height: 326px;
  }
}

@media screen and (min-width: 1024px) {
  .skeleton.productBox {
    width: 312px;
    height: 356px;
  }
  
  .skeleton.productBanner {
    width: 100%;
    height: 435px;
  }
  
  .skeleton.productBannerSmall {
    width: 1120px;
    height: 326px;
  }
}
.carousel-control-prev, .carousel-control-next{
  display: none !important;
}

.carousel-indicators{
  margin-bottom: 0 !important;
}

.carousel-indicators [data-bs-target]{
  /* max-width: 12px !important;
  max-height: 12px !important; */
  width: 8px !important;
  height: 8px !important;
  border-radius: 50% !important;
  background-color: #DDE0E7 !important;
  opacity: 1 !important;
  margin-right: 8px !important;
  margin-left: 8px !important;
}

.carousel-indicators .active{
  background-color: #1B325E !important;
}

.carousel_image{
  cursor: pointer;
  aspect-ratio: 16 / 7;
}

.carousel-caption{
  left: 50% !important;
  top: 50% !important;
  right: 0 !important;
  width: 50% !important;
}

.img_caption{
  position: relative;
  width: 100%;
  display: none;
  /* visibility: visible; */
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  justify-content: center;
  align-items: center;
  grid-gap: 8px;
  gap: 8px;
  transition: all 0.5s ease-in-out;
}

.img_caption::after{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.active.carousel-item:hover .img_caption{
  display: flex;
  /* visibility: visible; */
}

.img_caption_text{
  margin: 0;
  overflow: hidden;
  font-size: 0.625rem;
  line-height: 1rem;
}

.img_caption_link{
  color: blue;
  cursor: pointer;
  font-size: 0.625rem;
  line-height: 1rem;
}
.carousel_image,
.carousel_video {
  width: 100%;
  object-fit: cover;
  min-height: 190px;
  max-height: 450px;
}

@media screen and (min-width: 768px) {
  .img_caption{
    width: 70%;
  }

  .img_caption_text, .img_caption_link{
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  .video-container {
    position: relative;
    overflow: hidden;
    height: 450px;
  }
  
  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /* .carousel-inner{
    max-height: 400px;
  } */

  .carousel_image {
    max-height: 450px;
    object-fit: cover;
  }
}

@media screen and (max-width: 500px) {
  .video-container{
    height: 190px;
  }
}

@media screen and (min-width: 1024px) {
  .carousel-control-prev, .carousel-control-next{
    display: flex !important;
    max-width: 48px !important;
    max-height: 96px !important;
    width: 100% !important;
    height: 100% !important;
    background: #FFFFFF !important;
    border-radius: 8px !important;
    opacity: 1 !important;
    top: 50% !important;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .carousel-control-prev-icon, .carousel-control-next-icon{
    max-width: 48px !important;
    max-height: 48px !important;
    width: 100% !important;
    height: 100% !important;
  }
  
  .carousel-control-prev-icon{
    background-image: url(/static/media/arrow_left_black.5f2c40d5.svg) !important;
}
.carousel-control-next-icon{
  background-image: url(/static/media/arrow_right_black.89073fd3.svg) !important;
}
.carousel-indicators [data-bs-target]{
  width: 12px !important;
  height: 12px !important;
}
.img_caption_text, .img_caption_link{
    font-size: 1rem;
    line-height: 1.5rem;
  }

}

.banner-component{
    display: flex; 
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%
}
banner{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}
.banner img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 4px;
}
.mobilescreen{
    display: block;
    margin-top: 10px;
}
.mobilescreen img {
    max-height: 58px;
}
.desktop{
    display: none;
}
@media screen and (min-width: 708px) {
    .banner-component{
        display: flex; 
        align-items: center;
        justify-content: center;
        padding: 0 0.5rem;
        width: 100%;
        height: 100%
    }
    .banner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 99%;
        height: 100%
    }
    .banner img{
        width: 32.5%;
        max-height: 400px;
        min-height: 400px;
        object-fit: cover;
        border-radius: 10px;
        
    }
    .mobilescreen{
        display: none;
    }
    .desktop{
        display: flex;
        margin-top: 8px;
    }
}

@media screen and (max-width: 500px) {
    .mobilescreen img {
        height: 120px;
        max-height: 100%;
    }
}
.cards_Container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  margin: 1rem 0;
}

.cart_Add_Items {
  padding: 0 0.75rem;
  background-color: #ffffff;
  margin: 0 0 1rem;
}

.add_Items_Wrapper {
  padding: 1rem 0.25rem;
  border-bottom: 0.063rem solid #ebedf0;
}

.add_Items_Wrapper p {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
  margin: 0;
  max-width: 30ch;
}

.cart_More_Items {
  padding: 0.5rem 0.25rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart_More_Items p {
  letter-spacing: -0.5px;
  /* color: #1B325E; */
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.cart_More_Items img {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.cart_Save_Later {
  margin: 1rem 0;
}

.save_Later_Header {
  margin: 0 0 1rem;
  padding: 0 0 0 1rem;
}

.save_Later_Header p {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

/* Cart Page Footer */
.cart_Footer {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  z-index: 300;
}

.cart_Footer_Left {
  display: flex;
  flex-direction: column;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  width: 50%;
}

.price-item {
  /* width: 75%; */
}

.footer_Price {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.footer_Left_Text {
  letter-spacing: -0.5px;
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1rem;
  margin: 0;
}

.cart_Footer_Right {
  width: 50%;
}

.cart_Subtotal_Section {
  display: none;
}

/* .delivery_Options {
  margin: 1rem 0 23.5rem;
} */

.option_Disabled {
  pointer-events: none;
  opacity: 0.6;
}

.cart_Coupon_Section {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  margin: 1rem 0;
  background: #ffffff;
  padding: 0.5rem 0;
}

.cart_Coupon_Input {
  background: #1b325e;
  padding: 0.5rem 0;
}

.address-detail-con {
  display: flex;
  width: 60%;
  justify-content: center;
  padding: 1rem;
}

.address-cart-box {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  width: 55%;
}

.add-itm1 {
  width: 35%;
}

.aside-total-price {
  width: 23%;
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  border-radius: 10px;
  padding: 2rem 0rem;
}

.final-price-comp{
  font-size: 15px;
  width: 90%;
}

.text-gray{
  color: #707070;
}

#cart-address-detail {
  font-size: 20px;
  font-weight: 500;
}

.myTable th {
  background-color: #f2f2f2;
}

.cart-coupon {
  width: 100%;
  font-family: Quicksand;
  padding: 1rem 0rem 0rem 6rem;
}
.cart-coupon-child1 {
  width: 35%;
  padding: 1rem;
}

.coupon-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head-size {
  font-size: 2rem;
  font-size: 40px;
  font-weight: 700;
  /* border: 1px solid red; */
}
.hr-coupon {
  width: 50%;
  /* height: 10%; */
  border: 1px solid #b2b2b2;
  /* margin-top: 2rem; */
}
.instruct-coupon {
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 18px;
  color: #707070;
  /* width: 100px; */
  /* border: 1px solid red; */
  padding: 0.5rem;
}
.input-coupon {
  /* border: 1px solid black; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}
.inpt {
  margin-top: 1rem;
}
.input-coupon input {
  width: 100%;
  padding: 0.5rem;
  outline: none;
  border: 1px solid rgb(203, 203, 203);
}
.coupon-btn {
  /* border: 1px solid green; */
  width: 30%;
  height: 100%;
}
.apply-coupon-btn {
  border-radius: 0 0 0 1rem;
  background-color: #1b325e;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 200px;
  width: 100%;
  /* height: 10%; */
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  font-weight: 700;
  /* border: 1px solid red; */
}
.cart-coupon-child2 {
  background-color: #f8f8f8;
  /* border: 1px solid black; */
  border-radius: 6px;
}
.coupon-details {
  font-family: Quicksand;
  font-size: 15px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px 0px 0px 0px;
}
.coupon-typography {
  margin-left: 1.5rem;
}
#text2xl {
  font-size: 2rem;
  color: #1b325e;
}
.ml2px {
  margin-left: 10px;
}
.txt-clr-grey {
  color: #707070;
  margin-bottom: 6px;
}

.cart-detail-section2 {
  display: flex;
  /* border: 2px solid greenyellow; */
  background: #fff0bf;
  margin-bottom: 20px;
}

.sub-details {
  display: flex;
  flex-direction: column;
  /* border: 1px solid red; */
  padding: 0px 20px 0px 20px;
  font-size: 15px;
  font-weight: 700;
  justify-content: center;
  /* align-items: center; */
}
.sub-details img {
  width: 70%;
}

.cart-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 10px;
}

.delSave{
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  padding-left: 5px;
  margin-top: 8px;
}

.delSaveContent {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #707070;
  cursor: pointer;
}

.myTable table {
  border-collapse: collapse;
  width: 100%;
}

.myTable td {
  text-align: left;
  padding: 8px;
  border: 1px solid #e0e0e0;
}

.myTable th {
  background-color: #f2f2f2;
}

.myTable td:first-child {
  border-left: 1px solid #ffffff;
}

.myTable td:last-child {
  border-right: 1px solid #ffffff;
}

.myTable tr:first-child td {
  border-top: 1px solid #ffffff;
}

.myTable tr:last-child td {
  border-bottom: 1px solid #ffffff;
}

.myTable td:not(:first-child):not(:last-child) {
  /* border-color: #e0e0e0; */
}

.myTable td:nth-child(2) {
  border-left: 1px solid #ffffff;
  width: 250px;
  border-right: none;
}
.myTable td:nth-child(3) {
  border-left: 1px solid #ffffff;
  width: 360px;
  border-right: none;
}
.myTable td:nth-child(5) {
  padding: 0 30px;
  width: 160px;
}

.cart-combo-offer {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #1B325E;
}

.cart-product-desc {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 520px) {
  .address-detail-con {
    width: 100%;
    padding: 0.4rem;
  }
  .aside-total-price {
    display: none;
  }
  #cart-address-detail {
    font-size: 1.01rem;
    font-weight: 500;
    line-height: 1.3rem;
  }
  .cart-coupon {
    flex-direction: column;
    padding: 0 1.4rem 1rem;
  }
  .hr-coupon {
    display: none;
  }
  .cart-coupon-child1 {
    width: 100%;
  }
  .cart-coupon-child2 {
    width: 100%;
  }
  .suggested-products {
    padding: 0.3rem 0.9rem 0.8rem 0.9rem;
  }

  .input-coupon{
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 991px) {
  .aside-total-price {
    width: 100%;
  }
  .cart-coupon-child1 {
    width: 100%;
  }
  .cart-coupon-child2 {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .delivery_Options {
    margin: 1rem 0 2.5rem;
  }

  .cart_Text {
    display: block;
    color: #08090a;
    padding: 0.875rem 1rem;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
  }

  .cart_Subtotal_Section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.75rem;
    padding: 0.375rem 1.75rem 0.375rem 1rem;
  }

  .cart_Subtotal_Section > p,
  .cart_Subtotal_Section span {
    margin: 0;
    color: #08090a;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
    width: 70%;
  }

  .cart_Subtotal_Section span {
    font-weight: 600;
  }

  .save_Later_Header {
    padding: 0.875rem 1rem;
  }

  .save_Later_Header p {
    letter-spacing: -0.01em;
    color: #08090a;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  .cart_Footer {
    display: none;
  }

  .cart_Footer_Right {
    max-width: 160px;
    width: 100%;
  }

  .delivery_Option_Submit_Button {
    margin: 0 0 15rem;
    text-decoration: none;
  }

  .delivery_Option_Submit_Button button,
  .home_Delivery_Submit button {
    max-width: 100%;
  }

  .cart_Coupon_Section {
    grid-gap: 1.5rem;
    gap: 1.5rem;
    flex-direction: row;
    align-items: center;
    margin: 0 0 0.75rem;
    padding: 1rem 1.5rem;
  }

  .cart_Coupon_Input {
    background: #ffffff;
    padding: 0;
    width: 73%;
  }

  .cart_Coupon_Section .cart_Coupon_Button {
    width: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .cart_Text {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cart_Subtotal_Section {
    padding: 1rem 2rem 1rem 1.5rem;
  }
}

.input-field.coupon-applied {
  color: rgb(2, 173, 2);
  border: 2px solid;
}

.addressModalInput{
  outline: none;
  padding: 2px 10px;
}
.productbox_Container {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  min-height: 190px;
  position: relative;
}

.bg_blue {
  background: #d1f4ff;
}

.bg_pink {
  background: #ffd1d1;
}

.carousel_card {
  min-width: 150px;
  min-height: 250px;
}

.productbox_Container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

.productbox_Container img {
  max-width: 165px;
}

.grid_card {
  max-width: 158px;
  max-height: 158px;
  min-height: 150px;
  min-width: 150px;
}

.carousel_card img {
  min-width: 140px;
  min-height: 140px;
  /* max-width: 140px; */
  max-height: 140px;
}

.productbox_details {
  background: #ffffff;
  border-radius: 0.375rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.375rem;
  grid-gap: 0.125rem;
  gap: 0.125rem;
  max-width: 118px;
  /* max-height: 66px; */
  height: 100%;
  width: 100%;
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  bottom: -10px;
}

/* .carousel_card .productbox_details{
  transform: translate(0, 10px);
} */

.grid_card .productbox_details {
  /* transform: translate(0, -28px); */
  bottom: -46px;
}

.box_heading {
  letter-spacing: 0.015em;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.625rem;
  line-height: 1rem;
  margin: 0;
}

.box_price {
  letter-spacing: -0.015em;
  color: #08090a;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.box_itemName {
  letter-spacing: 0.004em;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0 0.375rem;
  text-align: center;
  max-height: 60px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (min-width: 768px) {
  .productbox_Container {
    min-width: 210px;
    min-height: 260px;
  }
  .carousel_card {
    min-width: 210px;
    min-height: 296px;
  }

  .grid_card {
    max-width: 210px;
    max-height: 230px;
  }

  .carousel_card img {
    /* max-width: 110px; */
    max-height: 168px;
    min-width: 110px;
    min-height: 168px;
  }

  .productbox_details {
    max-width: 178px;
    /* max-height: 92px; */
  }

  .carousel_card .productbox_details {
    -webkit-transform: translate(0, 24px);
            transform: translate(0, 24px);
  }

  .grid_card .productbox_details {
    max-width: 178px;
  }

  .box_heading {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .box_price {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .box_itemName {
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: -0.01em;
  }
}

@media screen and (min-width: 1024px) {
  .productbox_Container {
    padding: 0.75rem 1.25rem 0;
  }

  .carousel_card {
    min-width: 312px;
    min-height: 350px;
  }

  .grid_card {
    max-width: 312px;
    max-height: 284px;
  }

  .carousel_card img {
    /* max-width: 135px; */
    max-height: 224px;
    min-height: 224px;
  }

  .grid_card .productbox_details {
    max-width: 264px;
    /* max-height: 108px; */
    padding: 0.5rem 0;
  }

  .carousel_card .productbox_details {
    /* transform: translate(0, 12px); */
    max-width: 264px;
  }

  .box_heading {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .box_price {
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .box_itemName {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.section2_container {
  padding: 0.75rem 0 0.75rem 1rem;
  width: 100%;
  background-color: #ffffff;
}

.section2_heading {
  color: #000000;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.75rem;
}

.section2_card_container {
  max-width: 100%;
  min-height: 105px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 1000px;
  padding-bottom: 0.625rem;
  cursor: auto;
}

.section2_card_container::-webkit-scrollbar {
  display: none;
}

.section2_card_wrapper {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  white-space: nowrap;
  padding: 0 0 0.625rem;
}

@media screen and (min-width: 768px) {
  .section2_container {
    padding: 18px 0 18px 24px;
  }

  .section2_heading {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 18px;
  }

  .section2_card_wrapper {
    grid-gap: 1.125rem;
    gap: 1.125rem;
    padding: 0 0 1.5rem;
  }
}

@media screen and (min-width: 1400px) {
  .section2_container {
    padding: 24px 0 24px 60px;
  }

  .section2_heading {
    font-size: 24px;
    line-height: 36px;
    margin: 0 0 24px;
  }
}

.desk_Page_Wrapper{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0 0;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.order_Text{
  padding: 0 1rem;
  color: #6D747A;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.order_arriving_section, .order_delivered_section{
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}

.order_delivered_section{
  padding: 1rem 0 0;
}

.order_delivered_section{
  margin: 0 0 1rem;
}

.side_Section{
  display: none;
}


@media screen and (min-width: 768px) {

  .desk_Page_Wrapper{
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .order_Text{
    padding: 0.875rem 1rem;
  }

  .side_Section{
    display: flex;
    flex-direction: column;
    padding: 1rem;
    min-width: 210px;
    max-width: 320px;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
    width: 25vw;
    height: -webkit-max-content;
    height: max-content;
  }

  .side_Section_Heading{
    padding: 0 0 1rem;
    border-bottom: 1px solid #EBEDF0;
    margin: 0 0 1rem;
    color: #08090A;
    letter-spacing: -0.01em;
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  .side_Section_Checkbox:first-of-type, .side_Section_Checkbox:nth-of-type(2){
    margin-bottom: 0.75rem;
  }

  .order_Page_Right{
    width: 70vw;
  }

  .side_Section .cart_Add_Items{
    margin: 1.5rem 0 0;
    padding: 0 1rem;
  }

  .side_Section .cart_Add_Items .add_Items_Wrapper{
    padding: 1rem 0;
  }

  .side_Section .cart_Add_Items .cart_More_Items{
    padding: 0.875rem 0;
  }

  .order_Page_Right_Wrapper{
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 0 0;
  }
}

@media  screen and (min-width: 850px) {
  .desk_Page_Wrapper{
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .side_Section{
    width: 24vw;
  }

  .order_Page_Right{
    width: 69vw;
  }
}

@media screen and (min-width: 1200px) {
  .order_Text{
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: #08090A;
  }
}

.order-Filter-Profile {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  text-align: center;
  background-color: rgb(255 255 255 / 100%);
  cursor: pointer;
  grid-gap: 2px;
  gap: 2px;
}

.order-Filter-Profile > div:first-child {
  border-right: 1px solid #2b3953;
}

.order-Filter-Profile > div {
  padding: 7px 0;
}

.order-Filter-Profile > div:where(:hover, :focus-visible) {
  transition: all 0.2s ease-in-out;
  background-color: #1b325e;
  color: white;
}

.order-container {
  box-shadow: 0px 2px 2px rgb(0 0 0 / 2%);
  border-radius: 0.25rem;
  background: #ffffff;
  border: 1px solid #dde0e7;
  margin: 1em auto;
  display: flex;
  flex-direction: column;
}

.order-body {
  display: flex;
}

.order-body > * {
  flex-basis: 100%;
  padding: 0.875rem 1rem;
}

.order-footer {
  text-align: center;
  width: 92%;
  margin-inline: auto;
  padding-block: 0.8em;
  font-size: 0.8em;
  border-top: 1px solid #EBEDF0;
  cursor: pointer;
  transition: all 300ms ease-in;
  color: #1b325e;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.order-footer:where(:focus, :hover) {
  font-weight: 900;
}
.order-arrival {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order-arrival > * {
  margin-bottom: 0;
}
.order-arrival-text {
  font-size: 1.3em;
  font-weight: 500;
}

.order-name {
  font-weight: 600;
  margin-bottom: 0;
}

.order-color {
  font-size: 0.8em;
  font-weight: 500;
}

.order-arrival-placed {
  font-size: 0.7em;
  font-weight: 500;
}

.order-image > img {
  width: 80%;
  
}

.order-price {
  align-self: flex-start;
  font-weight: 500;
}
.order_Details_Header{
  background: #FFFFFF;
  padding: 1rem;
  margin: 0.75rem 0;
}

.order_Details_Header p{
  margin: 0;
}

.header_Order_Status{
  letter-spacing: -0.01em;
  color: #3F8F00;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 0.375rem;
}

.header_Order_Number{
  letter-spacing: -0.015em;
  color: #6D747A;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.order_Track_Container{
  padding: 1rem;
  background: #FFFFFF;
}

.order_Track_Wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-gap: 2rem;
  gap: 2rem;
}

.order_Track_Step{
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  position: relative;
}

.order_Track_Step::after, .order_Track_Step::before{
  border-left: 2px dashed #CCCCCC;
  width: 100%;
  height: 100%;
  content: '';
  position: absolute;
  left: 8px;
}

.order_Track_Step::before{
  top: -45px;
  height: 75%;

}

.order_Track_Step::after{
  bottom: 30px;
  height: 68%;
}

.order_Track_Step:first-child::before, .order_Track_Step:first-child::after {
  display: none;
}

.order_Track_Step .step_Circle_Icon{
  width: 18px;
  height: 18px;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
}

.order_Track_Step .step_Details{
  display: flex;
  flex-direction: column;
  grid-gap: 0.125rem;
  gap: 0.125rem;
}

.order_Track_Step .step_Details .step_Name{
  margin: 0;
  letter-spacing: -0.01em;
  color: #6D747A;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.order_Track_Step .step_Details .step_Date{
  margin: 0;
  letter-spacing: 0.004em;
  color: #6D747A;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.step_Completed::after, .step_Completed::before{
  border-left: 2px solid #1B325E;
}

.order_Track_Wrapper .step_Completed:last-child{
  z-index: 100;
}

.step_Completed .step_Circle_Icon{
  border: 1px solid #1B325E;
  position: relative;
}

.step_Completed .step_Circle_Icon div{
  display: block;
  background: #1B325E;
  height: 9px;
  width: 9px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.order_Track_Step.step_Completed .step_Details .step_Name{
  color: #000000;
}



.order_Cancel_Button{
  padding: 0.75rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  margin: 0 0 0.75rem;
  text-decoration: none;
}

.order_Cancel_Button:hover p{
  color: #3D5176;
}

.order_Cancel_Button p{
  margin: 0;
  color: #3D5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}


/*Order Cancel CSS */
.order_Cancel_Container{
  padding: 30px 0.875rem;
  display: flex;
  flex-direction: column;
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: auto;
}

.order_Cancel_Container .option-text {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  color: #707070;
}

.order_Cancel_Container .option-wrapper{
  padding: 12px 0.125rem;
}

.order_Cancel_Container div:nth-last-child(2), .order_Cancel_Container div:nth-last-child(1) {
  border: none;
}

.order_Cancel_Submit{
  display: none;
}

.Accor-dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: transparent;
  border: 1.5px solid rgb(69, 68, 68) !important;
  margin-right: 0.5rem;
}

.order_Details_Wrapper{
  -webkit-backdrop-filter: blur(50px);
  backdrop-filter: blur(20px);
  background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0.42) 132.2%);
  box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
  border-radius: 10px;
  margin-right: 14px;
  margin-left: 14px;
}

@media screen and (min-width: 768px) {
  .order_Details_Wrapper{
    max-width: 680px;
    width: 100%;
    margin: 0 auto;
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(20px);
    background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0.42) 132.2%);
    box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
    border-radius: 10px;
  }

  .order_Details_Header{
    margin: 1rem 0;
    padding: 0.875rem 1rem;
  }

  .header_Order_Status{
    margin: 0 0 0.25rem;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }

  .header_Order_Number{
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .order_Track_Container{
    grid-gap: 1.5rem 1.5rem 2.188rem 1.5rem;
    gap: 1.5rem 1.5rem 2.188rem 1.5rem;
    margin-bottom: 2rem;
  }

  .order_Track_Wrapper{
    grid-gap: 3.5rem;
    gap: 3.5rem;
  }

  .step_Details{
    grid-gap: 0.25rem;
    gap: 0.25rem;
  }

  .order_Track_Step{
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .order_Track_Step .step_Circle_Icon{
    width: 24px;
    height: 24px;
  }

  .order_Track_Step .step_Details .step_Name{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .order_Track_Step .step_Details .step_Date{
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .order_Track_Step::after,
  .order_Track_Step::before{
  border-left: 4px dashed #CCCCCC;
  left: 10px;
  }

  .order_Track_Step::before{
    top: -71px;
    height: 80%;
  }

  .order_Track_Step::after{
    bottom: 38px;
    height: 92%;
  }

  .step_Completed::after,
  .step_Completed::before{
    border-left: 4px solid #1B325E;
  }

  .step_Completed .step_Circle_Icon div{
    height: 12px;
    width: 12px;
  }

  .order_Cancel_Button{
    max-width: 100%;
    padding: 0;
    background: transparent;
  }

  .order_Cancel_Button button{
    max-width: 100%;
  }

  .order_Cancel_Button p{
    color: #FFFFFF;
  }

  .order_Cancel_Button:hover p{
    color: #FFFFFF;
  }

  .order_Cancel_Container {
    margin: 1rem auto 2rem;
  }

  .order_Cancel_Submit{
    display: block;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .order_Cancel_Container .option-text {
    font-size: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .order_Details_Wrapper{
    max-width: 885px;
  }

  .order_Details_Header{
    margin: 1.5rem 0 0.75rem;
    padding: 0.75rem 1.5rem;
  }
}
.backIcon {
  position: relative;
  left: 12px;
  bottom: 15px;
}

.backSpan {
  cursor: pointer;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 16px;
  bottom: 12px;
}

.back_logo {
  width: 10px;
}

.checkoutSpan {
  font-family: Quicksand;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 12rem;
  bottom: 12px;
}

.email-edit-btn {
  display: grid;
  place-content: center;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: underline;
  color: var(--primaryDarkBlue);
}

.main_wrapper {
  display: flex;
  width: 95vw;
  justify-content: space-between;
}

.main_container {
  width: 44vw;
  display: flex;
  flex-direction: column;
}

.card_logo_payment {
  width: 210px;
}

.main_heading {
  display: flex;
  justify-content: space-evenly;

  background: #f1f1f1;
  height: 55px;

  border-radius: 0px;
  align-items: center;
}

.main_heading h3 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: -4.7rem;
}

.second_container_main_heading {
  height: 60px;
  width: 52vw;
  background: #f1f1f1;
  display: flex;
  position: relative;
  height: 55px;
  align-items: center;
}

.outline {
  outline: 2px solid gold !important;
}

.order_break {
  width: 90%;
  margin-left: auto;
  /* position: relative; */
  /* left: 15%; */
}

.subtotal {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.subtotall_keys p {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.subtotall_keys span {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.subtotall_value span {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.buy-now {
  background: #ffcc0a !important;
  font-family: Quicksand;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  width: 210px !important;
  margin-bottom: 15px !important;
}

.secondHeading {
  position: relative;
  left: var(--paymentPageLeft);
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.cardDiv {
  display: flex;
  position: relative;
  left: var(--paymentPageLeft);
  margin-top: 15px;
  justify-content: space-between;
  width: 400px;
  height: 150px;
  grid-gap: 20px;
  gap: 20px;
  overflow: hidden;
}

.marginLeft {
  margin-left: var(--paymentPageLeft);
}

.cardDiv h2 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
}

.cardDiv strong {
  font-family: Quicksand;
  font-size: 26px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #241a6b;
  position: relative;
  bottom: 0.9em;
}

.cardDiv p {
  font-family: "Quicksand";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3581c1;
  white-space: pre-line;
}

.card__square {
  height: 138px;
  min-width: 138px;
  left: 1165.525390625px;
  top: 569.5244140625px;
  border-radius: 10px;
  margin-right: 10px;

  border: 1px solid lightgray;
}

.shippment_details {
  margin-left: 5em;
  background: #f8f8f8;
  margin-top: 15px;

  margin-bottom: 15px;
  justify-content: space-between;
  height: 236px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 0 10px;
  border-bottom: 1px solid rgb(201, 199, 199);
  width: 90%;
  align-items: center;
}

.details h5 {
  padding: 0;

  font-weight: 100;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

.second_heading {
  display: flex;
  justify-content: space-around;
  width: 50vw;
  background: #f1f1f1;
  height: 55px;

  border-radius: 0px;
  align-items: center;
}

.second_heading h3 {
  font-size: 18px;
  margin-left: 10.5em;
  font-family: Quicksand;

  font-weight: 700;

  text-align: left;
}

.payment_div {
  margin-left: 8em;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.popover-container {
  position: relative;
  display: inline-block;
  width: 800px;
}

.popover-container.pushed {
  margin-bottom: 10px;
}

.popover {
  transition: all 1s ease;
  display: none;
}

.submit-button {
  position: relative;
  height: 54.54199981689453px;
  width: 272.7939147949219px;
  font-size: 20px;
  border-radius: 0px;
}

.submit-button {
  position: relative;
  height: 54.54199981689453px;
  width: 272.7939147949219px;
  font-size: 20px;
  border-radius: 0px;
}

.showPopover {
  display: block;

  background: #f2f2f2;

  height: 611px;
  position: relative;
  left: 16%;
  border-radius: 10px;
  margin-top: 2.1px;
}

.emi_show {
  height: 700px;
}

.inside {
  outline: 2px solid gray;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  width: 100%;
  position: relative;
  left: 16%;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
}

.inside h4,
small {
  margin-left: 50px;
}

.left_payment_heading {
  display: flex;
  padding-left: 20px;
}

.left_payment_heading h4 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.left_payment_heading div {
  display: flex;

  flex-direction: column;
}

.card_input_logo {
  display: flex;
  padding: 20px;
}

.cards_input {
  padding-left: 20px;
  width: 100%;
}

.cards_input input {
  height: 55px;
  width: 446px;
  left: 264px;
  top: 1836px;
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0 15px 0;
}

.cards_input label {
  display: inline;
}

.expiry,
.cvv {
  width: 100% !important;
}

.flex {
  display: flex;
}

.flex_left,
.flex_right {
  margin-right: 7.5rem;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.cards_input label {
  height: 31px;

  left: 264px;
  top: 1795px;
  border-radius: nullpx;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.emi_top_heading {
  padding-top: 20px;
  padding-bottom: 20px;
}

.emi_top_heading span {
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;

  position: relative;
  padding-top: 2px;

  color: #b7b7b7;
}

.checkBox {
  display: flex;

  align-items: center;
}

.checkBox span {
  color: #0066c0;
}

small {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

input[type="radio"] {
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.shipment_div {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
}

.keys {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #b2b2b2;
  display: inline-block;
}

.values {
  /* position: absolute; */
  color: #262626;
  /* margin-left: 8em; */
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.submit-button-textPop {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-top: 6px;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  color: #ffffff;
}

@media screen and (max-width: 568px) {
  small {
    font-size: 13px;
    font-weight: 500;
  }

  .cards_input label {
    font-size: 17px;
  }

  .inside h4,
  small {
    margin-left: 20px;
  }

  .backIcon {
    display: none;
  }

  .backSpan {
    display: none;
  }

  .checkoutSpan {
    left: 12px;
    font-size: 22px;
  }

  .flex_left,
  .flex_right {
    margin-right: 5.5rem;
    width: 70% !important;
    display: flex;
    flex-direction: column;
  }

  .expiry,
  .cvv {
    width: 104px !important;
  }

  input[type="radio"] {
    width: 39px !important;
    cursor: pointer;
  }

  .payment_logo {
    margin-left: 0;
    left: 0;
  }

  .submit-button {
    font-size: 18px;
  }

  .submit-buttonn {
    font-size: 18px;
  }

  .checkBox span {
    color: #0066c0;
    font-size: 18px;
  }

  .left_payment_heading h4 {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 700;

    text-align: left;
  }

  .shippment_details {
    width: 90vw;
    left: 0;
    margin-left: 15px !important;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: space-between;
    height: 100%;
  }

  .shipment_div {
    display: flex;
    justify-content: flex-start;
  }

  .keys {
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #b2b2b2;
    display: inline-block;
  }

  .values {
    /* position: absolute; */
    color: #262626;
    /* margin-left: 10em; */
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    word-wrap: break-word;
  }

  .marginLeft {
    margin-left: 10px;
  }

  .main_heading {
    /* width: 98.5vw; */
    /* left: -9px; */
    position: relative;
    justify-content: flex-start;
    margin: 0;
  }

  .payment_logo {
    display: none;
  }

  .second_heading {
    /* width: 98.5vw; */
    /* left: -9px; */
    position: relative;

    margin: 0;
    display: flex;
    justify-content: flex-start;

    background: #f1f1f1;

    border-radius: 0px;
    align-items: center;
  }

  .second_heading h3 {
    font-size: 22px;
    margin-left: 0;
    position: relative;
    left: 1em;
  }

  .payment_div {
    left: 0;
    padding: 0;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .main_wrapper {
    flex-direction: column;
  }

  .inside {
    border-radius: 10px;
    padding: 0;
    display: flex;
    width: 92vw;
    position: relative;
    left: 4px;
    justify-content: space-around;
  }

  .showPopover {
    display: block;
    width: 560px;
    background: #f2f2f2;
    width: 89vw;
    height: auto;
    padding-bottom: 10px;
    position: relative;
    left: 14px;
    border-radius: 20px;
    margin-top: 3px;
  }

  .submit-button {
    width: 14em !important;
    font-size: 0.9em;
    height: 2.4em;
    margin-top: 10px !important;
  }

  .fromPayment-checkout-button {
    width: 24em!important;
    padding-inline: 1rem;
  }

  .card_logo {
    width: 178px;
  }

  .second_container_main_heading {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 98.5vw;
    /* left: -9px; */
    position: relative;
  }

  .cardDiv {
    display: flex;
    position: relative;
    left: 0;
    margin-top: 15px;
    width: 100%;
    height: 150px;
    grid-gap: 2px;
    gap: 2px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .cardDiv h2 {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
  }

  .cardDiv strong {
    font-family: Quicksand;
    font-size: 23px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: #241a6b;
    position: relative;
    bottom: 0.9em;
  }

  .cardDiv p {
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3581c1;
    white-space: pre-line;
  }

  .card__square {
    height: 138px;
    width: 138px;
    left: 0;
    top: 569.5244140625px;
    border-radius: 10px;

    border: 1px solid lightgray;
  }

  .subtotal {
    left: 0;
  }

  .buy-now {
    left: 0;
  }

  .page_Margin_Top_Secondary {
    margin-top: 20px;
  }

  .secondHeading {
    position: relative;
    left: 1em;
    font-family: Quicksand;
    font-size: 22px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
  }

  .cards_input input {
    width: 18rem;

    margin-top: 0;
  }

  .card_input_logo {
    padding: 15px 0 15px 0;
  }

  .main_heading h3 {
    font-size: 22px;
    position: relative;
    left: 1em;
  }

  .order_break {
    width: 100%;
    left: 0;
  }
  .email-edit-btn {
    place-content: end;
    margin-top: -1rem;
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 500px) {
  .cardDiv {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width : 991px) {
  .main_wrapper {
    flex-direction: column;
    width: 100%;
  }

  .main_container {
    width: 100%;
  }

  .second_container_main_heading {
    width: 100%;
  }

  .cardDiv {
    width: 100%;
    left: 0;
    justify-content: center;
  }
}

@media screen and (min-width: 501px) and (max-width: 1600px) {

  /* styles for screens between 500px and 1600px wide */
  .buy-now {
    background: #ffcc0a !important;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    width: 210px !important;
    margin-bottom: 15px !important;
  }

  .cardDiv p {
    font-family: "Quicksand";
  }

  .cardDiv p {
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3581c1;
    white-space: pre-line;
  }

  .submit-buttonn {
    position: relative;
    height: 54.54199981689453px;
    width: 372px;
    font-size: 19px;
    border-radius: 0px;
  }

  .submit-button {
    position: relative;
    height: 54.54199981689453px;
    width: 202px;
    font-size: 19px;
    border-radius: 0px;
  }
}

.css-11prve5 .MuiTextField-root {
  width: 39ch !important;
}
.pickup_Search_Bar_Container{
  margin: 0.5rem 0 0.75rem;
  padding: 0.5rem 1rem;
  background: #1B325E;
}

.pickup_Search_Bar{
  position: relative;
  width: 100%;
}

.store_Search_Bar{
  padding: 0.5rem 2.625rem;
}

.store_Search_Bar::-webkit-input-placeholder{
  color: #6D747A;
}

.store_Search_Bar::placeholder{
  color: #6D747A;
}

.pickup_Search_Bar img{
  position: absolute;
  top: 8px;
  left: 12px;
}

.store_Page_Separtor{
  margin: 0 0 0.75rem;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

.hr_Line{
  /* position: absolute; */
  background: #6D747A;
  height: 1px;
  width: 50%;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.store_Page_Separtor p{
  /* background-color: #eeeeec; */
  position: relative;
  margin: 0;
  color: #6D747A;
  letter-spacing: -0.015em;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0 0.25rem;
}

.near_Store_Button{
  padding: 0 1rem;
  margin: 0 0 24.75rem;
}

.near_Store_Button button{
  background: #DDE0E7;
}

.near_Store_Button button{
  max-width: 95%;
}

.near_Store_Button button p{
  color: #1B325E !important;
}

.store_Search_List{
  display: flex;
  flex-direction: column;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0.25rem 0 2rem;
}

.store_Seach_Option{
  background: #FFFFFF;
}

.location_Alert{
  padding: 0.875rem 1rem;
  background: #FFF0BF;
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.location_Alert p{
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.location_Refresh{
  margin: 10rem 0 12rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 0 1rem;
}

.location_Refresh > p{
  margin: 0;
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.location_Refresh_Button{
  max-width: 125px;
  width: 100%;
}

.store_Pick_Loader{
  padding: 1.5rem 1rem;
  background: #FFFFFF;
  border: 1px solid #DDE0E7;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  border-radius: 0.25rem;
}

.store_Pick_Loader > div{
  margin: 0 auto;
}

@media screen and (min-width: 768px) {
  .pickup_Search_Bar_Container{
    padding: 0.375rem 0.75rem;
    background: #FFFFFF;
    border-radius: 4px;
    -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
  }

  .store_Page_Separtor, .near_Store_Button{
    padding: 0;
  }

  .store_Page_Separtor p{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .hr_Line{
    width: 100%;
  }

  .location_Refresh{
    padding: 0;
    margin: 7.5rem 0 18rem;
  }
}

@media screen and (min-width: 1200px) {
  .location_Refresh{
    margin: 3.75rem 0 8.25rem;
  }
}

.storebox_Container{
  padding: 1rem;
}

.storebox_Wrapper{
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}

.open_Store_button{
  text-decoration: none;
}

.store_Name, .store_Address, .store_Timing p, .store_Contact a, .store_Distance p, .store_Status p, .store_Map_Link p, .open_Store_button p{
  margin: 0;
  color: #08090A;
}

.store_Contact a{
  text-transform: none;
  text-decoration: none;
}

.store_Name{
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.store_Address, .store_Timing p, .store_Contact a, .store_Distance p, .store_Map_Link p, .open_Store_button p{
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #08090A;
}

.store_Address{
  max-width: 585px;
}

.store_Contact > a{
  color: #3D5176;
  border-bottom: 1px solid #1B325E;
}

.store_Contact > a:hover{
  color: #3D5176;
  border-bottom: 1px solid #1B325E;
}

.store_Map_Link p{
  color: #1B325E;
  border-bottom: 1px solid #1B325E;
}

.store_Map_Link{
  text-decoration: none;
}

.store_Timing, .store_Contact, .store_Distance, .store_Map_Link{
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.store_Status{
  padding: 0.125rem 0.375rem;
  border: 1px solid #3F8F00;
  border-radius: 0.25rem;
}

.store_Status p{
  color: #3F8F00;
  letter-spacing: 0.004em;
  font-weight: 600;
  font-size: 0.63rem;
  line-height: 0.875rem;
  text-transform: uppercase;
}

.open_Store_button{
  /* margin: 0 0.75rem; */
  padding: 1rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  border-top: 1px solid #EBEDF0;
}

@media screen and (min-width: 768px) {
  .tab_Border{
    border-radius: 0.25rem;
    border: 1px solid #DDE0E7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  }

  .store_Name{
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .store_Address, 
  .store_Timing p 
  .store_Contact a,
  .store_Distance p,
  .store_Map_Link p,
  .open_Store_button p{
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
.header_Sort_Container {
  background-color: white;
  margin: 0.375rem 0 0;
}

.Product_Category_Container_res {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 0.75rem;
  gap: 0.75rem;
}

.bottom_Sheet_Backdrop {
  background: transparent;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
}

.bottom_Sheet_Backdrop.active {
  position: fixed;
  pointer-events: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(8, 9, 10, 0.6);
  z-index: 100;
}

.bottom_Sheet {
  position: absolute;
  display: none;
  bottom: -500vh;
  left: 0;
  width: 100%;
  z-index: 200;
  background: #fff;
  height: auto;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.bottom_Sheet.active {
  display: block;
  bottom: 0;
}

.bottom_Sheet_Header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ced4da;
}

.bottom_Sheet_Heading {
  letter-spacing: -0.01em;
  color: #000000;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0 !important;
}

.bottom_Sheet_Close_Btn {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.bottom_Sheet_Body {
  padding: 1rem;
}

.price_Sort_Labels {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.price_Sort_Labels {
  margin: 0 0 0.7rem;
}

.price_Sort_Labels input {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.header_Filter_Button {
  text-decoration: none;
}

.filter_Container {
  display: flex;
  align-items: center;
  justify-self: center;
  height: 60vh;
}

.filter_Left {
  max-width: 140px;
  width: 100%;
  background: #ffffff;
  box-shadow: 2px 0px 10px rgba(0, 0, 0, 0.03);
  height: 100%;
}

.filter_Right {
  background: #ffffff;
  width: 100%;
  height: 100%;
  padding: 0 0.25rem;
}

.filter_Left_Item {
  padding: 0.875rem 1rem;
  background: #ebedf0;
  margin-bottom: 1px;
  cursor: pointer;
}

.filter_Left_Item:has(:hover, :focus) {
  font-weight: bolder;
  letter-spacing: 0.5px;
  background-color: hsl(216, 14%, 88%);
  transition: all ease-in 50ms;
}

.filter_Left_Item.filter_Heading_Selected {
  background: #ffffff;
}

.filter_Left_Item p {
  color: #6d747a;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.filter_Left_Item.filter_Heading_Selected p {
  color: #08090a;
}

.filter_Right_Item {
  padding: 0.875rem 0.75rem 0.875rem 0.25rem;
  background: #ffffff;
  border-bottom: 1px solid #ced4da;
}

.filter_Right_Item:last-child {
  border: none;
}

.offers_Toggle_Header {
  max-height: 48px;
  width: 100%;
  margin: 0.5rem 0 0;
}

.offers_Toggle_Container {
  border: 1px solid #1b325e;
  background: #ebedf0;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
}

.offers_Toggle_Container p {
  padding: 0.75rem 2.25rem;
  margin: 0;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  transition: all 0.2s ease-in-out;
  color: #939ca3;
  cursor: pointer;
  width: 50%;
  text-align: center;
}

.offers_Toggle_Container p.offer_Selected {
  background: #1b325e;
  border-radius: 0 0.25rem 0.25rem 0;
  color: #fff;
}

.offers_Toggle_Container p:nth-child(2).offer_Selected {
  border-radius: 0.25rem 0 0 0.25rem;
}

.side_Section_Body .accordion .accordion-item .accordion-collapse {
  background: transparent;
}

.new_Filter_Container {
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
  transition: all 0.4s ease-in-out;
}

.new_Filter_Container.new_Filter_Show {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  height: 100%;
  width: 100%;
}

.main_Content_Show {
  scroll-margin-top: 4rem;
  display: block;
  transition: all 0.5s ease-in-out;
}

.main_Content_Hide {
  display: none;
}

@media screen and (min-width: 568px) {
   .head2xl,
   .aside-items span {
    font-size: 0.8rem;
   }
   .price-range span {
    font-size: 0.5rem;
    margin-bottom: 8px;
   }
   .price-range + div > button {
    font-size: 0.6rem;
    padding: 5px 0;
   }
   .aside-items:first-child span {
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 768px) {
  .Product_Category_Container_res {
    display: grid!important;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    grid-gap: 1.5rem 0.5rem;
    gap: 1.5rem 0.5rem;
    justify-items: flex-end;
  }

  .side_Section_Body .accordion .accordion-item:not(:last-of-type) {
    border-bottom: 1px solid #ebedf0;
  }

  .side_Section_Body .accordion .accordion-item {
    border: none;
  }

  .side_Section_Body
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: #08090a;
    letter-spacing: -0.01em;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    padding: 0.75rem 0;
  }

  .side_Section_Body
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button::after {
    background-image: url(/static/media/add_outline_grey.75b4d6f0.svg);
  }

  .side_Section_Body
    .accordion
    .accordion-item
    .accordion-header
    .accordion-button:not(.collapsed):after {
    background-image: url(/static/media/remove_line_grey.0d241db5.svg);
    -webkit-transform: none;
            transform: none;
  }

  .side_Section_Body
    .accordion
    .accordion-item
    .accordion-collapse
    .accordion-body {
    padding: 0.75rem 0;
    display: flex;
    flex-direction: column;
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }

  .filter_Sort_Header {
    margin: 1rem 0 0;
    display: flex;
    justify-content: space-between;
  }

  .filter_Section_Header {
    max-width: 600px;
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .sort_Section_Header {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: auto;
  }

  .offers_Toggle_Header {
    max-height: 32px;
    min-width: 256px;
    max-width: 256px;
    width: 100%;
    margin: 0;
  }

  .offers_Sort_Header {
    display: flex;
    justify-content: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .offers_Toggle_Container {
    border: 1px solid #1b325e;
    background: #ebedf0;
    border-radius: 0.25rem;
    display: flex;
    width: 100%;
    max-width: 256px;
  }

  .offers_Toggle_Container p {
    padding: 0.375rem 1rem;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.25rem;
    transition: all 0.2s ease-in-out;
    color: #939ca3;
    cursor: pointer;
  }

  .offers_Toggle_Container p.offer_Selected {
    background: #1b325e;
    border-radius: 0 0.25rem 0.25rem 0;
    color: #fff;
  }

  .sort_Section_Header .dropdown .dropdown-toggle {
    background: transparent !important;
    border: 1px solid #ced4da !important;
    box-shadow: none !important;
  }

  .sort_Section_Header .dropdown .dropdown-toggle::after {
    border: none;
    display: none;
  }

  .sort_Section_Header .dropdown .dropdown-toggle:hover {
    background: transparent;
    border: 1px solid #ced4da;
  }

  .sort_Section_Header .show > .btn.btn-primary.dropdown-toggle:focus {
    box-shadow: none;
    background: transparent;
    border: 1px solid #ced4da;
  }
  .sort_Section_Header .show > .dropdown-toggle.btn.btn-primary:focus,
  .sort_Section_Header .show > .dropdown-toggle.btn.btn-primary:focus-visible {
    box-shadow: none;
    background: transparent;
    border: 1px solid #ced4da;
  }

  .sort_Container {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    justify-content: space-between;
    align-items: center;
  }

  .sort_Container span,
  .sort_Container p {
    color: #6d747a;
    margin: 0;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .sort_Container p {
    color: #08090a;
  }

  .sort_Container img {
    max-width: 20px;
    max-height: 20px;
    width: 100%;
    height: 100%;
  }

  .sort_Section_Header .dropdown-menu {
    width: 100%;
  }

  .sort_Section_Header .dropdown .dropdown-menu .dropdown-iten:active {
    background: #e9ecef;
  }

  .price-range span {
    font-size: 0.8rem;
    margin-bottom: 8px;
   }

   .price-range + div > button {
    font-size: 0.7rem;
   }
   .tag-discount {
    margin-right: 4px;
   }
}

@media screen and (min-width: 850px) {
  .Product_Category_Container_res {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

@media screen and (min-width: 1200px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

  .all-offer-comp {
    flex-wrap: nowrap !important;
  }
}

.loader {
  margin: auto;
  border: 4px solid hsl(219, 90%, 40%);
  border-radius: 50%;
  border-top: 3px solid hsl(219, 90%, 70%);
  width: 25px;
  height: 25px;
  -webkit-animation: spinner 2s ease-in infinite;
          animation: spinner 2s ease-in infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}
.desk_Page_Wrapper_res {
  display: flex;
}
.all-categories-banner {
  margin-top: var(--pageMarginTop);
  width: 100%;
}
.all-categories-banner img {
  width: 100%;
  cursor: pointer;
}

.all-categories-devices {
  display: flex;
  justify-content: space-around;
  padding: 0px 6rem;
  flex-wrap: wrap;
}
.all-cat-items {
  margin: 0 10px;
  text-align: center;
  /* width: 100%; */
  height: 100%;
  min-width: 120px;
  /* overflow: hidden; */
}
.all-cat-items img {
  width: 120px;
  height: 120px;
}
.all-cat-items {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#all-mid-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 35px;
  font-weight: 700;
  font-family: "Quicksand";
  margin-bottom: 10px;
  margin-top: 10px;
}

.all-cat-logos {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.logo-con-1 {
  /* width: 40%; */
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.logo-con-2 {
  /* width: 40%; */
  height: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.logo-con-1 img {
  width: 20%;
  height: 100%;
  min-width: 120px;
  margin-right: 10px;
}
.logo-con-2 img {
  width: 22.5%;
  height: 100%;
  margin-left: 10px;
  min-width: 120px;
}
.prod-brand-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}
.brand-logo-cont {
  display: flex;
  width: 80%;
  grid-gap: 15px;
  gap: 15px;
}
.brand-logo-img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
:where(.logo-img, .brand-logo-img, .all-cat-items):where(:hover, :focus-visible) {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.brand-logo-img[data-selected="selected"] {
  scale: 1.5;
}
.all-cat-items[data-selected="selected"],
.logo-img[data-selected="selected"] {
  scale: 1.15;
}
.brand-logo-img img {
  width: 60%;
  height: 60%;
  max-width: 70px;
  max-height: 70px;
}

.all-offer-comp {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;
}
.all-offer-items {
  display: flex;
  flex-direction: column;
  border: 5px solid #ffcc0a;
  justify-content: center;
  align-items: center;
  margin: 0.4rem 0.4rem;
  width: 30%;
  height: 88px;
  border-radius: 10px;
}
.all-offer-items:where(:hover, :focus-visible, [data-priceselected="priceselected"]) {
  cursor: pointer;
  box-shadow: #f4de9d 0px 10px 30px -12px inset,
    #ebd789 0px 18px 18px -10px inset;
  transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.all-offer-items span {
  font-size: 25px;
  font-weight: 600;
  font-style: italic;
}
.all-cat-banners {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
}
.all-cat-banner-item1 {
  width: 39%;
  height: 100%;
  margin-right: 10px;
}
.all-cat-banner-item2 {
  width: 70%;
  height: 100%;
}
.all-cat-banner-item1 img {
  width: 100%;
  height: 100%;
}
.all-cat-banner-item2 img {
  width: 94%;
  height: 80%;
  min-height: 272px;
}
.Product_Category_Container_res {
  width: 100%;
  display: flex;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
  grid-row-gap: 2.5rem;
  row-gap: 2.5rem;
}
.right-wrapper {
  width: 100%;
  height: 100%;
}

.SpotLight_res {
  display: flex;
  justify-content: space-between;
  padding: 2px 2rem;
  align-items: center;
  background: #f5f5f5;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 1.2rem;
  border-radius: 10px;
}
.pagination-con {
  display: flex;
  justify-content: center;
  align-items: center;
}
.desk_Page_Wrapper_res {
  padding: 10px 15px 30px 10px;
  width: 100%;
  position: relative;
}
.head2xl {
  text-transform: capitalize;
  font-size: 1rem;
}
.aside_res {
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 2.5rem 1.2rem;
  font-family: "Quicksand";
  font-weight: 700;
  font-size: 20px;
  background: #f8f8f8;
  border-radius: 10px;
}
.aside-items hr {
  width: 80%;
  color: black;
}
.aside_container_res {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.aside-items {
  width: 100%;
}
.Accordian_res {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  box-shadow: none;
  border: none;
  outline: none;
}
.accordian-itm {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 15px;
}
.Accor-dot {
  width: 15px;
  height: 15px;
  border-radius: 100%;
  background-color: transparent;
  border: 1.5px solid rgb(69, 68, 68);
  margin-right: 0.5rem;
}
.accordian-section1 {
  display: flex;
  flex-direction: column;
  margin: 0rem 0rem;
  padding: 0  0.5rem;
  color: #707070;
  font-size: 14px;
}
#color-orn {
  background-color: #ffa500;
}
.acoor-sec-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}
.acoor-sec-item:last-child{
  margin-bottom: 0;
}
.aside-price-range {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}
.price-range {
  display: flex;
  justify-content: space-between;
}
.acit {
  margin-right: 15px;
}

.order_Page_Right_res {
  width: 100%;
  padding: 0px 22px 30px 35px;
  height: 100%;
}

.empty_order_sec {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  padding: 80px 0;
  margin: 0 0 1rem;
}

.empty_order_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 580px) {
  .all-categories-devices {
    display: flex;
    justify-content: center;
    padding: 0px;
  }
  .all-cat-banners {
    display: none;
  }
  .logo-con-1,
  .logo-con-2 {
    flex-wrap: wrap;
    width: 30%;
    grid-gap: 5px;
    gap: 5px;
  }
  .logo-con-1 img {
    min-width: 50px;
  }
  .logo-con-2 img {
    min-width: 55.8px;
  }
  .brand-logo-img img {
    width: 100%;
    height: 100%;
    max-width: 33px;
  }
  #all-mid-heading {
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  .all-offer-comp {
    padding: 0.4rem;
    flex-wrap: wrap;
  }
  .all-offer-items {
    margin: 0.4rem;
    width: 25%;
    height: 38px;
  }
  .all-offer-comp.secondRow {
    width: 100%;
  }
  .all-offer-items.secondItem {
    width: 30%;
  }
  .all-offer-items span {
    font-size: 0.75rem;
  }
  .aside_res {
    width: 100%;
  }
  .Product_Category_Container_res {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .order_Page_Right_res {
    padding: 0px 0px 23px 0px;
  }
  .head2xl {
    font-size: 1.3rem;
  }
  .accorItemName{
    font-size: 1.1rem;
  }
  .main_Content_Show  {
    scroll-margin-top: 8rem;
  }

}

.backBtnDrawer {
  cursor: pointer;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  color: #262626;
}

.doneBtnDrawer {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  color: #262626;
}

.css-1t2f62z-MuiPaper-root-MuiAccordion-root:before {
  display: none;
}
.css-kr5692::before {
  display: none;
}

[data-filterselected="selected"] {
  background-color: var(--primaryYellow);
}

/* Highlight the header of the selected filter */
.Accordian_res:has([data-filterselected="selected"]) {
  color: var(--primaryYellow);
}

.MuiAccordionSummary-content {
  text-transform: capitalize;
}

.acoor-sec-item:where(:hover, :focus-visible) {
  cursor: pointer;
}

.order_Page_Right_res {
  display: flex;
  min-height: 22vh;
}

.empty_order_sec_res {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SpotLight_res-items {
  cursor: pointer;
}

.offer-banners img:hover,
.offer-banners img:focus-visible {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-filter: brightness(1.11);
            filter: brightness(1.11);
    scale: 1.02;
    cursor: pointer;
}

@media screen and (min-width: 569px) and (max-width: 630px) {
  .price-range + div {
    right: 12px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
    margin-left: 1rem;
   }
}

@media screen and (min-width: 569px) and (max-width: 1200px) {
  .accordian-itm {
    width: 114%!important;
  }
}

@media screen and (min-width: 1400px) {
  .Product_Category_Container_res {
    grid-template-columns: repeat(5, 1fr);
    grid-row-gap: 1rem;
    row-gap: 1rem;
  }

}

.product_Section_1 {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.product_Section_1 .product_Name {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.product_Section_1 .product_Preview_Section {
  margin: 1rem 1.5rem 2.5rem;
}

.product_Section_1 .product_Preview_Section .product_Carousel_Image {
  max-width: 280px;
  max-height: 280px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.product_Section_1 .product_Preview_Section .carousel .carousel-indicators {
  bottom: -30px;
}

.product_Delivery_Section {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.product_Alternate_Section {
  margin: 0 0 0.5rem;
  background: #ffffff;
}

.product_Section_1 .product_Price_Desc {
  display: flex;
  grid-gap: 0.625rem;
  gap: 0.625rem;
  align-items: center;
  margin: 0 0 0.375rem;
}

.product_Section_1 .product_Price_Desc .product_Discount_Price {
  color: #08090a;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2.25rem;
  margin: 0;
}

.product_Section_1 .product_Price_Desc .product_Original_Price {
  color: #6d747a;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  text-decoration: line-through;
  margin: 0;
}

.product_Section_1 .product_Price_Desc .product_Availability,
.product_Section_1 .product_Offer_Counter p,
.product_Discount {
  letter-spacing: -0.015em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin: 0;
}

.product_Alternate_Section .accordion .accordion-item {
  border: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button {
  padding: 1rem 0.75rem 1rem 1rem;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:focus {
  border: none;
  box-shadow: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button p {
  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  color: #08090a;
  letter-spacing: -0.01em;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  padding: 0;
  margin: 0;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button p span {
  font-weight: 600;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: #ffffff;
  box-shadow: none;
}

.product_Alternate_Section .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed):after {
  -webkit-filter: invert(1) contrast(0) saturate(0) brightness(0.5);
          filter: invert(1) contrast(0) saturate(0) brightness(0.5);
}

.product_Delivery_Details {
  margin: 0 0 1rem;
}

.product_Delivery_Details p {
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: -0.01em;
  font-weight: 500;
  color: #000000;
  margin: 0;
}

.product_Delivery_Details p span {
  font-weight: 600;
}

.product_Page_Availability {
  margin: 0 0 1rem;
}

.product_Page_Availability p {
  font-size: 1.125rem;
  line-height: 1.75rem;
  margin: 0;
}

.product_Delivery_Footer {
  padding: 1.5rem 0 0;
  border-top: 1px solid #ebedf0;
}

.product_Section_Heading {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.key_feature_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  color: #000000;
}

.KeyStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 25px;
  color: #000000;
}

.product_Offer_Section {
  padding: 1rem;
  background: #ffffff;
  height: auto;
  margin: 0 0 0.5rem;
}

.product_Offer_Header {
  display: flex;
  justify-content: flex-start;
  grid-gap: 0.375rem;
  gap: 0.375rem;
}

.product_Offer_Cards_Container {
  max-width: 100%;
  min-height: 105px;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  scroll-padding: 0 1000px;
  padding-bottom: 0.625rem;
  cursor: auto;
}

.product_Offer_Cards_Wrapper {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  white-space: nowrap;
  padding: 0 0 10px;
}

.product_Offer_Header img {
  max-width: 24px;
  max-height: 24px;
  width: 100%;
  height: 100%;
}

.product_Description_Section {
  padding: 1rem 0;
  background: #ffffff;
  height: auto;
  margin: 0 0 0.5rem;
}

.product_Description_Header {
  margin: 0 1rem 0.75rem;
}

.description_See_Less,
.description_See_More {
  letter-spacing: -0.015em;
  color: var(--primaryDarkBlue);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.5rem;
  cursor: pointer;
  margin: 0;
}

.description_See_More {
  text-align: right;
  margin: 0 1rem 0 0;
}

.description_See_More.description_Expanded {
  visibility: hidden;
}

.product_Description_Wrapper {
  grid-gap: 0.5rem;
  gap: 0.5rem;
  margin: 0;
  padding: 0 1rem 0 2rem;
  max-height: 100px;
  height: 100%;
  overflow-y: hidden;
}

.product_Description_Wrapper.description_Expanded {
  max-height: -webkit-max-content;
  max-height: max-content;
}

.product_Description {
  color: #6d747a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.productPage_Table {
  padding: 1rem;
  background: #ffffff;
  margin: 0 0 0.5rem;
}

.productPage_Table h5 {
  margin: 0 0 1rem;
}

.productPage_Image_Gallery {
  background: #ffffff;
  padding: 1rem;
}

.productPage_Image_Gallery h5 {
  margin: 0 0 0.75rem;
}

.image_Gallery_Wrapper {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
}

.product_Gallery_Image {
  width: 100%;
  height: 100%;
}

.product_Page_Floating_Wrapper {
  justify-content: center;
}

.product_Page_Floating_Wrapper .floating_Footer_Left {
  text-align: center;
}

.floater_Add_Cart {
  margin: 0 auto;
  text-align: center;
  letter-spacing: -0.01em;
  color: var(--primaryDarkBlue) !important;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  cursor: pointer;
  text-decoration: none;
  width: 100%;
}

.floater_Add_Cart:hover {
  color: var(--primaryDarkBlue) !important;
}

.slug-URL {
  /* font-family: Quicksand; */
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.tagList {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-res-style,
.btn-res-style:hover {
  background: #FFCC0A;
  /* border: 1px solid #FFCC0A; */
  font-size: 10px;
}

.product-child-1 {
  /* width: 100% !important; */
  order: 2;
}

.product-child-2 {
  order: 1;
  width: 100% !important;
}

.product-child-3 {
  order: 3;
  width: 100% !important;
}

.add_to_wishlist_icon {
  color: #5A99CD;
  background: #F8F8F8;
  height: 30px !important;
  width: 30px !important;
  padding: 2%;
  border-radius: 10px;
}

.product_share_icon {
  color: #5A99CD;
  background: #F8F8F8;
  height: 30px !important;
  width: 30px !important;
  padding: 6%;
  border-radius: 10px;
}

.productTag {
  width: 48%;
  height: 40px;
  background: rgb(248, 249, 250);
  border: 1px solid rgb(202, 204, 209);
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  border-radius: 10px;
  color: var(--primaryDarkBlue);
}

.product-flex-container {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  grid-gap: 3.5rem;
  gap: 3.5rem;
  align-items: center;
}

.discountOffer {
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 20px;
  color: #3581c1;
}

.netPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #241a6b;
}

.realPrice {
  font-weight: 700;
  font-size: 1.01rem;
  line-height: 40px;
  color: #000000;
}

.Condition {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.reviewHead {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  color: #000000;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  scroll-margin-top: 9rem;
  margin-right: 1rem;
}

.review-child-1 {
  width: 100%;
  order: 1;
}

.review-child-2 {
  width: 100%;
  order: 2;
}

.product-desc {
  font-family: Quicksand;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}

.desc-readMore {
  color: #000000;
  font-size: 0.7rem;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.product-desc span:where(:hover, :focus-visible) {
  color: var(--primaryDarkBlue);
  font-weight: bolder;
}

.offername {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
  align-items: center;
  display: flex;
  max-width: 118px;
}

.bank_image {
  width: 80px;
  height: 24px;
}

.services-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  text-align: center;

  color: #000000;
}

.deal-end-text {
  padding-left: 14px;
  font-weight: 700;
  font-size: 16px;
}

.combo-offer-title {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  color: #241A6B;
  text-transform: uppercase;
}

.combo-image-wrapper {
  position: relative;
  max-width: 146px;
  max-height: 146px;
  height: 100%;
  width: 100%;
}

.combo-image-wrapper img {
  max-width: 146px;
  max-height: 146px;
  height: 100%;
  width: 100%;
}

.combo-checkbox {
  position: absolute;
  top: 8px;
  width: auto;
  right: 8px;
}

.combo-product-name {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.combo-free-text {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;

  color: #241A6B;
}

.combo-product-price {
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;

  color: #000000;
}

.offer-lasts {
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  color: #241A6B;
}

.offer-time-wrapper {
  background-color: #FFF0BF;
  ;
}

.offer-time-number {
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: var(--primaryDarkBlue);
}

.offer-time-text {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  color: var(--primaryDarkBlue);
}

.pincode-submit-btn {
  background-color: var(--primaryDarkBlue) !important;
}

.color-product-box {
  width: 16%;
  /* height: 69px; */
  border: 1px solid #CACCD1;
  border-radius: 10px;
}

.color-product-box:hover,
.color-product-box:focus-visible {
  scale: 1.1;
  border: 3px solid #29292b;
  transition: scale 300ms ease;
}

.specs-product-box {
  font-size: 0.85rem;
  border-width: 2px!important;
  border-color: var(--primaryDarkBlue)!important;
  padding: 0.5rem 1.5rem;
  width: unset;
  height: unset;
}

.specs-product-box:hover,
.specs-product-box:focus-visible {
  scale: 1.1;
  border: 1.25px solid var(--primaryYellow);
  background-color: #ffce0a42;
  transition: scale 300ms ease;
}

@media screen and (max-width: 768px) {
  .slug-URL-Container span {
    font-size: 10px;
  }

  .specs-section-wrapper {
    margin-top: 2%!important;
  }

  .color-product-box {
    height: auto;
  }

  .specs-product-box {
    padding: 0.15rem 0.75rem;
    font-size: 0.75rem;
  }

}

.slug-URL-Container {
  width: 100%;
}

@media screen and (max-width: 500px) {
  .discountOffer {
    font-size: 34px;
  }

  .netPrice {
    font-size: 30px;
  }

  .realPrice {
    font-size: 1rem;
  }

  .key_feature_heading {
    font-size: 1.4rem;
    line-height: 1.3;
  }

  .product_Section_Heading_empty_div {
    width: 45% !important;
  }

  .sub-head-cart1 {
    margin-right: 0 !important;
    width: auto !important;
  }

}

@media screen and (min-width: 768px) {
  .product_Section_1 .product_Preview_Section {
    display: none;
  }

  .product_Side_Section {
    padding: 1rem;
    background: transparent;
    width: 36vw;
    max-width: none;
    grid-gap: 1.5rem;
    gap: 1.5rem;
    border: none;
    box-shadow: none;
  }

  .product_Side_Section .image_Preview_Side_Section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }

  .product_Side_Section .image_Preview_Selected {
    max-width: 327px;
    height: 100%;
    width: 100%;
    border-radius: 0.25rem;
    -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
    cursor: pointer;
  }

  .product_Side_Section .image_Preview_Side_Section .image_Preview_Selected img {
    width: 100%;
    height: 100%;
    /* max-width: 327px; */
    /* max-height: 327px; */
    object-fit: contain;
  }

  .cursor-zoom-in {
    cursor: zoom-in !important;
  }

  .image_Preview_Side_Section .product_Thumbnails {
    display: flex;
    grid-gap: 0.375rem;
    gap: 0.375rem;
    justify-content: center;
    align-items: center;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail {
    max-width: 72px;
    max-height: 72px;
    height: 100%;
    width: 100%;
    border: 1px solid #dde0e7;
    -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
            filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.02));
    border-radius: 0.25rem;
    cursor: pointer;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail img {
    width: 100%;
    height: 100%;
    max-height: 72px;
    max-height: 72px;
    object-fit: contain;
  }

  .product_Side_Section_Buttons {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .button_Set_2 {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .button_Set_2 div {
    width: 50%;
  }

  .button_Set_2 .submit_Button_1 {
    width: 100%;
  }

  .product_Page_Right {
    width: 56vw;
  }

  .product_Section_1 .product_Name {
    margin: 0 0 2rem;
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 500;
  }

  .product_Section_1 .product_Price_Desc {
    grid-gap: 1.125rem;
    gap: 1.125rem;
    margin: 0 0 0.75rem;
  }

  .product_Section_1 .product_Price_Desc .product_Original_Price,
  .product_Section_1 .product_Price_Desc .product_Availability,
  .product_Discount {
    font-size: 1.5rem;
    line-height: 2.25rem;
    font-weight: 500;
  }

  .product_Section_1 .product_Price_Desc .product_Original_Price,
  .product_Discount {
    font-weight: 600;
  }

  .product_Section_1 .product_Offer_Counter p {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .product_Section_1 .product_Offer_Section {
    padding: 1rem 0;
    margin: 1rem 0 0;
  }

  .product_Section_1 .product_Offer_Section .product_Offer_Header {
    margin: 0 0 1rem;
  }

  .product_Description_Section {
    margin: 0 0 0.75rem;
    border-radius: 0.25rem;
  }

  .product_Description_Header {
    margin: 0 1rem 1rem;
  }

  .productPage_Table {
    margin: 0 0 1rem;
  }

  .productPage_Image_Gallery h5 {
    margin: 0 0 1rem;
  }

  .image_Gallery_Wrapper {
    grid-gap: 0.5rem;
    gap: 0.5rem;
  }

  .product_Delivery_Section {
    padding: 0.875rem 1rem;
    border-radius: 0.25rem;
  }

  .product_Delivery_Details {
    margin: 0;
  }

  .product_Delivery_Details .product_Delivery_Pin-form {
    display: flex;
    grid-gap: 6px;
    gap: 6px;
    align-items: center;
    width: 100%;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Pincode_Input {
    width: 65%;
    margin: 0 30px 0 0;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Submit_Btn {
    width: 100%;
    max-width: 160px;
  }

  .product_Delivery_Details .product_Delivery_Pin-form .product_Delivery_Submit_Btn p {
    color: #ffffff;
  }

  .product_Alternate_Section {
    padding: 1rem;
    border-radius: 0.25rem;
  }

  .product_Alternate_Section_Header {
    margin: 0 0 0.75rem;
  }

  .product_Alternate_Section_Header p,
  .product_Alternate_Section p span {
    margin: 0;
    color: #08090a;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .product_Alternate_Section p span {
    font-weight: 600;
    letter-spacing: -0.01em;
  }

  .product_Alternate_Section_Body {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    flex-flow: wrap;
    margin: 0 0 2rem;
  }

  .product_Alternate_Section_Footer {
    display: flex;
    flex-direction: column;
    grid-gap: 0.75rem;
    gap: 0.75rem;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 0.75rem;
  }

  .product_Alternate_Section_Footer p {
    color: #08090a;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .product_Alternate_Section_Footer p span {
    letter-spacing: -0.01em;
    font-weight: 600;
    margin: 0;
  }

  .product_Alternate_Footer_Cards {
    display: flex;
    grid-gap: 0.75rem;
    gap: 0.75rem;
  }
}

@media screen and (min-width: 1200px) {
  .product_Side_Section {
    padding: 1.2rem;
    grid-gap: 1rem;
    gap: 1rem;
  }

  .product_Side_Section .image_Preview_Selected {
    max-width: 650px;
    max-height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .product_Side_Section .image_Preview_Side_Section .image_Preview_Selected img {
    /* max-width: 650px; */
    /* max-height: 650px; */
    margin: 0 auto;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail {
    max-width: 60px;
  }

  .image_Preview_Side_Section .product_Thumbnails .thumbnail img {
    max-width: 144px;
    max-height: 144px;
    width: 100%;
    height: 100%;
  }

  .product_Description_Section {
    padding: 1.5rem 0;
    margin: 0 0 0.5rem;
  }

  .product_Description {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .description_See_More,
  .description_See_Less {
    font-size: 1rem;
  }

  .productPage_Table {
    padding: 1.5rem;
  }

  .productPage_Image_Gallery {
    padding: 1.5rem;
  }

  .product_Delivery_Section {
    padding: 1.5rem;
  }

  .product_Alternate_Section {
    padding: 1.5rem;
  }

  .product_Alternate_Section_Body {
    margin: 0 0 1.5rem;
    grid-gap: 1.125rem;
    gap: 1.125rem;
  }

  .product-child-1 {
    order: 1;
    /* width: 30% !important; */
  }

  .product-child-2 {
    order: 2;
    width: 30% !important;
  }

  .product-child-3 {
    order: 3;
    width: 33% !important;
  }

  .product-flex-container {
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: row;
    align-items: flex-start;
  }

  .review-child-2 {
    width: 49%;
    order: 2;
  }
}

.slug-URL {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.rws-container {
  min-width: auto;
}

.tabular-data {
  display: flex;
  flex-wrap: wrap;
}

.column {
  flex-basis: 33.33%;
  display: flex;
}

.column:not(:last-child)::after {
  content: "";
  background-color: #000;
  width: 1px;
  height: 100px;
  display: block;
  margin: auto 20px;
}

.column:not(:first-child) {
  padding-left: 16px;
}

.column .column-inner:first-child {
  flex-basis: auto;
  flex-wrap: nowrap;
}

.column .column-inner:last-child {
  flex-grow: 1;
  padding-left: 0.5rem;
}

.modalImageContainer {
  display: flex;
  padding: 20px 10px;
}

.modalImage {
  width: 100%;
  height: 100%;
}
.profile_User_Details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0 1rem;
  background: #f8f9fa;
}

.user_Profile_Pic_Container {
  margin: 0 0 0.5rem;
  position: relative;
}

.user_Profile_Pic {
  max-height: 120px;
  max-width: 120px;
  aspect-ratio: 1;
}

.user_Profile_Pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  min-width: 120px;
  object-fit: cover;
}

.user_Camera_Icon {
  max-width: 2rem;
  max-height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.profile_Image {
  max-width: 2rem;
  max-height: 2rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}

.user_Phone,
.user_Mail {
  margin: 0.25rem 0 0;
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.user_Name {
  margin: 0.25rem 0 0;
  letter-spacing: -0.01em;
  color: #1b325e;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
}

.profile_Options {
  margin: 0 0 1rem;
  padding: 1.5rem 0.75rem;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.profile_Option {
  display: flex;
  justify-content: space-between;
  padding: 0 0.25rem 1.125rem;
  margin: 0 0 1.125rem;
  border-bottom: 1px solid #dde0e7;
  cursor: pointer;
  text-decoration: none;
  color: transparent;
}

.profile_Option div {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
  justify-content: center;
}

.profile_Option p {
  margin: 0;
  color: #1b325e;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
}

.profile_Option:last-child {
  border: none;
  margin: 0;
  padding: 0 0.25rem;
}

.logout_Styles {
  border: none;
  margin: 0;
  padding: 0 0.25rem;
}

.logout_Styles p {
  color: #c5292a;
}

.logout_Styles .profile_arrow {
  display: none;
}

@media screen and (min-width: 768px) {
  .user_Details_Mob {
    display: none;
  }
  .profile_Page_Wrapper {
    padding: 1.75rem 1.5rem;
    display: flex;
    background: #ffffff;
  }

  .profile_User_Details {
    border-radius: 0.5rem;
    border: 1px solid #dde0e7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  }

  .profile_Side_Section {
    padding: 0;
    grid-gap: 2rem;
    gap: 2rem;
  }

  .profile_Options_Desk {
    background: #f8f9fa;
    border: 1px solid #dde0e7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
  }

  .profile_Option {
    opacity: 0.6;
  }

  .profile_Option.--selected{
    opacity: 1;
  }
}

@media screen and (min-width: 1024px) {
  .profile_Page_Wrapper {
    padding: 1.75rem 2rem;
  }
}

@media screen and (min-width: 1400px) {
  .profile_Page_Wrapper {
    padding: 1.75rem 3.75rem;
  }
}


.wishlist_page_container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.newWishListContainer {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Quicksand";
}

.newWishListDetails {
  padding: 10px 54px 5px 118px;
  margin-bottom: 19px;
}

.newWishListTitle {
  margin-top: 5px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.newWishListTitle h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  color: #000000;
}

.newWishListUserContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.newWishListHeading,
.newWishListUserName {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 40px;
  color: #000000;
}

.newWishListHeading {
  margin-bottom: 34px;
}

.newWishListImage {
  display: flex;
  align-items: center;
  grid-gap: 18px;
  gap: 18px;
}

.newWishListImage img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

/* Seactin Ends */

.newWishListCart {
  display: flex;
  width: 100%;
  background-color: #f3f2f3;
  padding: 34px 30px 20px 110px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
  grid-gap: 20px;
  gap: 20px;
}

.newWishListDetailed {
  width: 55%;
  display: flex;
}

.newWishListDetailed:where(:focus-visible, :hover) {
  font-weight: bolder!important;
  cursor: pointer;
}

.newWishListDetailed2 {
  padding: 10px 0px 0px 15px;
  margin-right: 40px;
  display: flex;
  justify-content: space-between;
}
.newWishListDetailed2 div .newWishListname {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
}

.newWishListDetailed2 .newWishListDetailed2Div p {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #707070;
  margin-bottom: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.newWishListDetailed3 {
  padding-top: 10px;
}

.newWishListDetailed3 h3 {
  text-align: right;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #7f7f7f;
}

/* Buttons */

.newWishListButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 35%;
  padding: 16px 30px;
}

.newWishListAddButton {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.newWishListPrice {
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 47px;
  color: #241a6b;
}

.newWishListAddToCartButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 15px 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 340px;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.newWishListAddToCartButton p {
  font-size: 21px;
  line-height: 16px;
  font-weight: 550;
  letter-spacing: -0.5px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.newWishListAddedDate {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 9px;
  color: #7f7f7f;
}

.newWishListdeleteButton {
  outline: none;
  border: none;
  display: flex;
  align-items: center;
}

.newWishListdeleteButton:has(:hover, :focus-visible) svg,
.newWishListdeleteButton:has(:hover, :focus-visible) span  {
  fill: red!important;
  color: red!important;
  transition: fill 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

.newWishListdeleteButton span {
  font-style: normal;
  font-weight: 550;
  font-size: 18px;
  line-height: 25px;
  color: #707070;
}

.newWishListDetailed1 {
  max-width: 161px;
  max-height: 161px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 500px) {
  .newWishListdeleteButton span {
    font-size: 14px;
    line-height: 20px;

  }
  
  .newWishListContainer {
    padding: 0;
  }
  .newWishListDetails {
    padding: 5px 20px;
  }
  .newWishListTitle {
    margin-bottom: 10px;
  }
  .newWishListTitle h1 {
    font-size: 14px;
  }
  .newWishListHeading,
  .newWishListUserName {
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    color: #000000;
  }
  .newWishListUserName {
    font-size: 22px;
  }
  .newWishListStart {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .newWishListHeading {
    margin-bottom: 10px;
  }
  .newWishListImage {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
  }

  .newWishListImage img {
    width: 66px;
    height: 66px;
    border-radius: 50%;
  }

  /* Section Ends */

  .newWishListAddToCartButton {
    padding: 12px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    cursor: pointer;
    width: 160px;
    border: none;
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .newWishListAddToCartButton p {
    font-size: 14px;
    line-height: 16px;
    font-weight: 550;
    letter-spacing: -0.5px;
    display: inline-block;
    -webkit-transform: skew(20deg);
            transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }

  .newWishListCart {
    padding: 16px 0px 25px 10px;
    margin-bottom: 20px;
    flex-direction: column;
    border-radius: 0;
    grid-gap: 0;
    gap: 0;
  }
  .newWishListDetailed {
    width: 100%;
  }
  .newWishListDetailed2 {
    flex-direction: column;
    padding: 10px 0px 0px 13px;
  }
  .newWishListDetailed2 div .newWishListname {
    font-size: 11px;
    line-height: 13px;
    color: #000000;
  }

  .newWishListDetailed1 img {
    width: 100px;
    height: 100px;
  }
  .newWishListDetailed3 {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    padding-top: 0;
    padding-right: 40px;
    grid-gap: 10px;
    gap: 10px;
  }
  .newWishListDetailed2 .newWishListDetailed2Div p {
    font-size: 13px;
    line-height: 14px;
    margin-bottom: 0px;
  }
  .newWishListDetailed3 h3,
  .newWishListPrice {
    display: inline-block;
  }
  .newWishListPrice {
    font-size: 1.45rem;
    line-height: 37px;
    color: #241a6b;
  }

  .newWishListDetailed3 h3 {
    font-size: 1rem;
    line-height: 20px;
  }

  .newWishListDetailed2{
    margin-right: 0px;
  }

  /* Buttons */

  .newWishListButtonSection {
    width: 100%;
    flex-direction: column;
    padding: 2px 0px;
    text-align: left;
    margin-left: -26px;
  }
  .newWishListAddedDate{
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (max-width:991px) {
  .newWishListCart {
    padding-left: 16px;
    flex-direction: column;
  }

  .newWishListDetailed {
    width: 100% !important;
  }

  .newWishListButtonSection {
    width: 100% !important;
  }

  .newWishListDetailed2 {
    margin-right: 0;
  }

  .newWishListPrice {
    font-size: 26px !important;
  }
}

@media screen and (min-width: 501px) and (max-width: 1600px) {
  /* styles for screens between 500px and 1600px wide */
  .newWishListDetailed {
    width: 65%;
  }
  .newWishListButtonSection {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 45%;
    padding: 16px 30px;
  }
  .newWishListAddToCartButton {
    padding: 12px 39px;
    width: 240px;
  }
  
  .newWishListAddToCartButton p {
    font-size: 16px;
    line-height: 16px;
    font-weight: 550;
    letter-spacing: -0.5px;
    display: inline-block;
    -webkit-transform: skew(20deg);
            transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }
  .newWishListdeleteButton span {
    font-style: normal;
    font-weight: 550;
    font-size: 14px;
    line-height: 25px;
    color: #707070;
  }
}
.thankyou_container {
  display: flex;
  flex-direction: column;
  /* border: 10px solid red; */
  /* width: 100%; */
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  /* width: 80vw; */
  /* height: 90vh; */
  margin-top: 44px;
  padding: 20px;
}
/* .thankyou-sec-1 {
  display: none;

} */
.thankyou-sec-1 {
  display: flex;
  width: 100%;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  flex-direction: column;
}
.thk-child {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thk-price {
  display: flex;
  flex-direction: column;
  border: 2px solid red;
}
.final_product_details {
  display: flex;
  /* width: 50%; */
  height: 100%;
  /* justify-content: space-between; */
  /* align-items: flex-start; */
}
.product_final-img {
  width: 100%;
  display: flex;
  /* justify-content:  center; */
  align-items: center;
  /* height: 100%; */
}
.product_final-img img {
  /* border:1px solid black; */
  width: 150px;
  height: 100px;
  /* width: 200px; */
  /* height: 260px; */
}
.product_final__details {
  display: flex;
  flex-direction: column;
  /* width: 80%; */
}

.product_final__details__desc{
  max-width: 350px;
  width: 100%;
  min-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.icn-color-thnk {
  color: #fda829;
}
.thk2 {
  /* border: 1px solid royalblue; */
  display: flex;
  flex-direction: column;
}
.thk2-sub {
  /* border: 1px solid sienna; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
}
.tick-thk {
  width: 100%;
  display: flex;
  justify-content: center;
}
.tick-thk img {
  width: 30%;
  /* height: 70%; */
  margin-top: 17px;
  /* margin-left: 2rem; */
  margin-bottom: 1rem;
}
.thk-sub-content {
  padding: 0;
}
.thk2 {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 500px; */
  margin-top: 30px;
}
.color-font {
  font-size: 30px;
  color: #1b325e;
}
.color-font2 {
  color: #1b325e;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 42px;
  margin-bottom: 10px;
}
.thk-sub-content {
  width: 100%;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#price-thk {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  /* identical to box height */

  -webkit-text-decoration-line: line-through;

          text-decoration-line: line-through;

  color: #7f7f7f;
}
#price-thk2 {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 62px;
  /* identical to box height */

  color: #241a6b;
}
/* .thk-price {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

} */
.mobileCard {
  display: flex;
  flex-direction: row;
  grid-gap: 20px;
  gap: 20px;
  width: 100%;
  border-radius: 10px;
  /* background-color: rgb(214, 174, 174); */
}
.mobilecheckoutStyle {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
}
.pay2 {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  /* line-height: 62px; */
  /* identical to box height */

  color: #241a6b;
}

@media screen and (min-width: 600px) {
  .mobileCard {
    display: none;
  }
  /* .thankyou-sec-1 {
    display: flex;
    width: 100%;
    height: 150px;
    justify-content: center;
    gap:20px;
    align-items: center;
    height: 188.33%;
    padding: 5rem 0rem 5rem 0rem;
    border: 1px solid fuchsia;
  } */
  .color-font2 {
    font-size: 27px;
    color: #1b325e;
  }
  .tick-thk img {
    width: 10%;
    /* height: 70%; */
    /* margin-left: 2rem; */
    margin-bottom: 1rem;
  }
  .thk-sub-content {
    padding: 0px 5rem 0px 5rem;
  }
  .thk-sub-content {
    width: 65%;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.product_finall{
  display: flex;
  justify-content: center;
  align-items: center;
  /* gap: 90px; */
  max-width: 900px;
}
.product_final__details__name{
  font-size: 15px;
  font-weight: 700;
  color: #000000;
}

@media screen and (max-width: 500px) {
  .thankyou_container {
    padding: 0px 0px;
  }
  .hrThankyou {
    display: none;
  }
  .thankyou-sec-2{
    padding: 0px;
  }
  .product_finall{
    /* flex-direction: column; */
    flex-direction: row;
    grid-gap: 2px;
    gap: 2px;
  }
  .thankyou-sec-1{
    padding: 0 10px;
    margin-bottom: 10px;
  }
  #price-thk {
    font-size: 18px;
    line-height: 20px;
  }
  #price-thk2 {
    line-height: 32px;
  }
  .product_final__details__name{
    line-height: 2px;
  }

  .product_final__details__desc{
    min-width: auto;
  }
}

.order_proessing {
  height: 400px;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
}

.order_proessing img {
  width: 100px;
}

.parentClassTC {
    width: 100vw;
    padding: 20px;
    margin-top: var(--pageMarginTop);
}

ol {
    padding-left: 0 !important;
}

.firstsection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.firstsection img {
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.headerSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    font-weight: 700;
    padding-left: 20px
}

.secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 10px auto;
    grid-gap: 20px;
    gap: 20px;
    text-align: justify !important;
    color: #707070 !important;
}

.secondSectionHeader {
    font-weight: 700;
    color: #000000;
}

@media screen and (min-width: 501px) {
    .firstsection {
        margin: auto;
        min-width: 22%;
    }

    .firstsection img {
        width: 45%;
        margin: auto;
    }

    .headerSection {
        flex-grow: 1;
        font-size: 1.5rem !important;
        align-items: center !important;
    }
}

@media screen and (min-width: 800px) {
    .headerSection {
        align-items: flex-start !important;
    }

    .firstsection img {
        width: 33%;
    }

    .secondSection {
        width: 70%;
    }

    .firstsection {
        width: 50%;
    }
}



@media screen and (max-width: 768px) {
    .secondSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        width: 100%;
        margin: 20px 10px;
        grid-gap: 20px;
        gap: 20px;
        width: 100%;
    }

    .headerSection {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        font-weight: 700;
        width: 100%;
    }

    .imageStyle {
        width: 100%;
        height: 100%;
    }
}

#parent_container ol {
    counter-reset: custom-counter !important;
}

#parent_container li {
    display: block !important;
}

#parent_container li::before {
    content: counters(custom-counter, ".") " " !important;
    counter-increment: custom-counter !important;
}

#parent_container .container-li:has(.secondSectionHeader)::before {
    content: counters(custom-counter, ".") ". " !important;
    counter-increment: custom-counter !important;
}

#parent_container .secondSection li::before {
    font-weight: bold !important;
    letter-spacing: 0.2px !important;
}

#parent_container .container-li:has(.secondSectionHeader) {
    font-weight: bold !important;
    color: black !important;
    text-align: center !important;
}

@media screen and (max-width: 500px) {
    .secondSection {
        margin: 0;
        width: 100%;
    }

    .container-li {
        margin-bottom: 0.6rem;
    }

    .imageStyle {
        width: 33%;
    }

    .firstsection {
        flex-wrap: nowrap;
        flex-direction: column;
    }

    .headerSection {
        line-height: 1.2 !important;
        grid-gap: 0 !important;
        gap: 0 !important;
        align-items: center !important;
        line-height: 1.2 !important;
        margin-top: 5px !important;
    }

    .headerSection>span:first-child {
        font-size: 1.4rem;
    }
}
.parentClassCustomerSupport{
    width: 100vw;
    padding: 19px;
  }

.header{
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 31px;

  color: #000000;
}
.HeadingText{
    font-size: 25px;
    margin-bottom: 20px;
}
.contactusMain{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.contactusMainsub{
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0) 132.2%);
    border-radius: 10px;
    padding: 25px;
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 62px;
    color: #000000;
    border: 1px solid rgb(240, 231, 231);
}
.contactphonesection{
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 35px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    color: #0066C0;
}

@media screen and (min-width: 768px) {
    .parentClassCustomerSupport{
      width: 100vw;
      padding: 19px 153px;
    }
    .HeadingText{
        font-size: 25px;
    }
    .contactusMain{
      margin: 0 30%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .contactusMainsub{
      width: 90%;
      align-items: center;
      display: flex;
      flex-direction: column;
      background: linear-gradient(180.48deg, #F3F6FF 0.41%, rgba(243, 246, 255, 0) 132.2%);
      border-radius: 10px;
      padding: 25px;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 62px;
      color: #000000;
      border: 1px solid rgb(240, 231, 231);
    }
}
.myRewardsWrapper {
  padding: 16px 20px;
  font-family: "Quicksand";
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.myRewardsContainer {
  padding-left: 150px;
  padding-right: 150px;
}

.myRewardsTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 40px;
}

.myRewardsHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #000000;
}

.myRewardsBannerContainer {
  width: 100%;

}

.myRewardsBanner {
  display: flex;
}

.myRewardsBannerImage {
  width: 50%;
  /* height: 220px; */
  margin-right: 10px;
  margin-bottom: 10px;
}

.myRewardsPersonImageContainer{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* margin-left: -55px; */
}

.myRewardsPersonImage {
  width: 470px;
  height: 630px;
}



@media screen and (max-width : 500px) {
  .myRewardsPersonImage {
    width: 370px;
    height: 440px;
  }
  .myRewardsContainer {
    padding-left: 9px;
    padding-right: 9px;
  }
  .myRewardsWrapper {
    padding: 0px;
  }
  .myRewardsBannerImage {
    margin-right: 5px;
    margin-bottom: 5px;
  }
  .myRewardsTitle {
    font-size: 16px;
    margin-top: 3px;
    margin-bottom: 35px;
  }
  
  .myRewardsHeading {
    font-size: 26px;
    line-height: 34px;
  }

}
@media screen and (max-width : 380px) {
  .myRewardsPersonImage {
    width: 300px;
    height: 400px;
  }
}
.parentClass {
    padding: 20px;
    width: 100vw;
    margin-top: var(--pageMarginTop);
}

.userHeader {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-left: 9%;
    grid-gap: 30px;
    gap: 30px;
}

.profileImage {
    border-radius: 50%;
    height: 140px;
    width: 130px;
}

.userName {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 50px;
    /* identical to box height */


    color: #000000;
}

.divider {
    width: 0px;
    height: 100px;
    margin-top: 2%;
    border: 1px solid #000000;
}

.mobileCard {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    background-color: #F8F8F8;
    border-radius: 10px;
    padding: 20px 0;
    margin: 2%;
}

/* .cart-banner {
    display: flex;
    justify-content: space-evenly;
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #0066c0;
    margin-top: 2rem;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
  }

.cart-banner-item {
    display: flex;
    padding: 1rem;
    justify-content: center;
    align-items: center;
} */

#sub-banner1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: unset;
}


.icn-sz {
    width: 35px;
    height: 35px;
    /* padding: 1rem; */
    margin-right: 10px;
}

.itm-child {
    display: flex;
    flex-direction: column;
}

.user_Profile_Pic_Container {
    margin: 0 0 0.5rem;
    position: relative;
}


.user_Profile_Pic {
    max-height: 130px;
    max-width: 130px;
    aspect-ratio: 1;
}

.user_Profile_Pic img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    min-width: 120px;
    object-fit: cover;
}

.user_Camera_Icon {
    max-width: 2rem;
    max-height: 2rem;
    position: absolute;
    top: 0;
    right: 6px;
    cursor: pointer;
}

.user_Camera_Icon:where(:hover, :focus-visible) {
    scale: 1.2;
    cursor: pointer;
}


@media screen and (max-width: 520px) {
    .userName {
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        color: #000000;
    }

    .user_Profile_Pic img {
        min-width: 60px;
    }

    .divider {
        width: 0px;
        height: 70px;
        margin-top: 5%;
        border: 1px solid #000000;
    }
}
.your_Order_Container {
  width: 100%;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 16px;
  font-family: "Quicksand";
  margin-top: var(--pageMarginTop);
}

:root {
  --downloadBtnMarginTop: -3.5rem;
}

.your_order_details {
  padding: 10px 54px 5px 118px;
}

.your_order_placed {
  margin-bottom: 45px;
  display: flex;
  justify-content: space-between;
}

.your_order_placed h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}

.your_order_div {
  display: flex;
}

.your_order_tracks {
  width: 70%;
}
.combo-product-text {
  border-radius: 5px;
}

.your_order_tracks_inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 40px;
  gap: 40px;
}

.your_order_header h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 37px;
  color: #000000;
}

.your_order_header p {
  font-size: 15px;
}

.your_order_id p {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  color: #707070;
  margin-bottom: -38px;
}

.your_order_search {
  display: flex;
  border: 1px solid #bebebe;
  padding: 5px 10px;
  border-radius: 6px;
}

.your_order_searchbar {
  outline: none;
  border: none;
  width: 300px;
}

.your_ordersearch_icon {
  margin-right: 10px;
  font-weight: 300;
  font-size: 1rem;
}

.your_order_searchbar::-webkit-input-placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #7f7f7f;
}

.your_order_searchbar::placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  color: #7f7f7f;
}

.your_order_image {
  width: 30%;
}

.your_order_total_price {
  z-index: 1;
}

.your_order-_image img {
  width: 260.86px;
  height: 200px;
}

.your_order_tracks_second {
  display: flex;
  grid-gap: 237px;
  gap: 237px;
}

.your_order_title,
.your_order_placed_date {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 24px;
  color: #3581c1;
}

.your_order_placed_date {
  font-size: 19px;
}

.your_order_total_price h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 24px;
  color: #241a6b;
}

/* Section-1 Ends  */

.your_order_cart {
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  background-color: #f3f2f3;
  padding: 34px 30px 20px 110px;
  margin-top: 10px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.your_order_cart p {
  font-weight: 600;
  margin-bottom: 0;
}

.your_order_cart>div {
  display: flex;
}


.your_order_cart_detailed {
  display: flex;
}

.your_order_cart_detailed_2 {
  padding: 0px 0px 0px 2.3rem;
  margin-right: 40px;
  width: 43%;
  text-decoration: none;
}

.your_order_cart_detailed_2:hover,
.your_order_cart_detailed_2:focus-visible {
  text-decoration: underline;
  -webkit-text-decoration-color: black;
          text-decoration-color: black;
  cursor: pointer;
}

.your_order_cart_detailed_2 h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 22px;
  color: #000000;
}

.your_order_cart_detailed_2 .your_order_cart_detailed_2_div h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #707070;
}

.your_order_cart_detailed_3 {
  padding-top: 10px;
}

.your_order_cart_detailed_3 h3 {
  text-align: right;

  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 29px;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  color: #7f7f7f;
}

.your_order_cart_detailed_3 h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 47px;
  color: #241a6b;
}

.your_order_cart_buttons {
  width: 45%;
  display: flex;
}

.package-canceled {
  font-size: 1.2rem;
  flex-grow: 1;
  text-align: center;
}

.your_order_cart_buttons_1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  grid-gap: 10px;
  gap: 10px;
}

.your_order_submit_button {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 14px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.your_order_submit_button p {
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.your_order_cancel_button {
  outline: none;
  border: none;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.your_order_cart_buttons_1_cancel {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #707070;
}

.your_order_cart_buttons_2 {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

.your_cart_downloadicon_container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.your_cart_downloadicon {
  font-size: 45px !important;
  font-weight: 700 !important;
}

.cart_texts {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #707070;
}

.card-display-row::-webkit-scrollbar {
  display: none;
}

.your_order_cart_detailed_1 {
  aspect-ratio: 3/4;
}

.your_order_cart_detailed_1 img {
  width: 100%;
  border-radius: 5px;
}

.order_total_price {
  display: flex;
  justify-content: flex-end;
  font-style: normal;
  font-weight: 700;
  line-height: 47px;
  color: #241a6b;
  margin-right: 2rem;
}

.review_btn {
  color: #3581c1;
  font-weight: 700;
  font-weight: 700;
}

.review_btn:where(:hover, :focus-visible) {
  cursor: pointer;
  text-decoration: underline;
  color: #1b325e;
}

.original-price {
  font-size: 0.9rem;
  text-align: end;
  margin-bottom: -13px!important;
}

.order-cancel-tick {
  max-width: 205px;
  max-height: 205px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.order-cancel-tick img {
  /* width: 30%; */
  margin-top: 17px;
  margin-bottom: 1rem;
}

@media screen and (max-width: 500px) {
  .your_Order_Container {
    padding: 16px 0 0px;
  }
  .onsitego-heading, 
  .onsitego-text {
    font-size: 14px;
  }

  .your_order_details {
    padding: 5px 10px;
  }

  .your_order_placed h1 {
    font-size: 16px;
  }

  .your_order_header h1 {
    font-weight: 700;
    font-size: 17px;
    line-height: 14px;
    margin-bottom: 2px;
    margin-top: -2px;
    color: #000000;
  }

  .your_order_header p {
    font-size: 10px;
  }

  .your_order_search {
    padding: 1px 6px;
  }

  .delivered-text {
    flex-grow: 1;
    text-align: center;
  }

  .invoice-not-generated-text {
    font-size: 13px;
  }

  .combo-product-text {
    font-size: 10px!important;
    line-height: 16px!important;
    font-weight: 500!important;
    padding: 6px!important;
  }
  .your_order_searchbar {
    width: 180px;
  }

  .your_order-id p {
    font-size: 10px;
    line-height: 28px;
  }

  .your_order_tracks {
    width: 100%;
  }

  .your_ordersearch_icon {
    margin-right: 5px;
    font-weight: 300;
    font-size: 0.4rem;
  }

  .your_order_tracks_inside {
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px;
  }

  .your_order_searchbar::-webkit-input-placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }

  .your_order_searchbar::placeholder {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }

  .your_order_image,
  .your_order_total_price {
    width: 33%;
  }

  .your_order_date {
    width: 34%;
  }

  .your_order_image img {
    width: 110px;
    height: 80px;
  }

  .your_order_tracks_second {
    grid-gap: 0px;
    gap: 0px;
  }

  .your_order_total_price {
    padding-left: 20px;
  }

  .your_order_total_price h1 {
    font-size: 20px;
    line-height: 18px;
  }

  /* Section Ends  */

  .your_order_cart {
    padding: 10px 10px 10px 15px;
    margin-bottom: 20px;
    flex-direction: column;
  }

  /* .your_order_cart_detailed_1 {
    width: 150px;
  } */

  .your_order_cart_detailed_1 img {
    width: 90px;
    height: 90px;
  }

  .your_order_cart_detailed {
    width: 100%;
    display: flex;

  }

  .your_order_cart_detailed_2 {
    padding: 0px;
    margin:  0 14px;
  }

  .your_order_cart_buttons {
    width: 100%;
    display: flex;
    margin-top: 5px;
  }

  .your_order_cart_detailed_2 .your_order_name {
    font-size: 12px;
    line-height: 15px;
    color: #000000;
  }

  .your_order_cart_detailed_2 .your_order_cart_detailed_2_div h1 {
    font-size: 12px;
    line-height: 13px;
  }

  .your_order_cart_buttons_1 {
    flex-direction: row;
  }

  .your_order_submit_button {
    padding: 8px 15px;
    display: flex;
    margin-top: 0;
    justify-content: center;
    align-items: center;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
    box-shadow: 0px 4px 4px 0px #00000040;
  }

  .your_order_submit_button p {
    font-size: 10px;
    line-height: 16px;
    font-weight: 500;
    letter-spacing: -0.5px;
    display: inline-block;
    -webkit-transform: skew(20deg);
            transform: skew(20deg);
    margin: 0;
    color: #ffffff;
  }

  .your_order_cancel_button {
    outline: none;
    border: none;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .your_order_cart_buttons_1_cancel {
    font-size: 10px;
    line-height: 20px;
  }

  .your_order_cart_buttons_2 {
    width: 20%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .your_cart_downloadicon {
    font-size: 24px !important;
  }

  .your_order_cart_buttons_2_text {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 3px;
    gap: 3px;
    padding: 0 12px 0px 0px;
  }

  .cart_texts {
    font-size: 10px;
    line-height: 3px;
  }

  .your_order_cart_detailed_2_container {
    display: flex;
    justify-content: space-between;
  }

  .your_order_cart_detailed_3 {
    padding-top: 0px;
  }

  .your_order_cart_detailed_3 h3 {
    font-size: 20px;
    line-height: 17px;
  }

  .your_order_cart_detailed_3 h1 {
    font-size: 15px;
  }

  .your_order_cart_detailed_2 {
    width: 100%;
  }

  .your_order_title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }

  .your_order_placed_date {
    font-size: 15px;
  }

  .your_order_cart>div {
    flex-direction: column;
    margin-right: 0;
  }

  .order-cancel-tick img {
    width: 50%;
    margin-bottom: 1rem;
  }

  .orderCancelledImage {
    width: 80%;
  }
}


@media screen and (min-width: 501px) and (max-width: 1600px) {

  /* styles for screens between 500px and 1600px wide */
  .your_order_submit_button p {
    font-size: 17px;
  }

  .your_order_tracks_second {
    display: flex;
    grid-gap: 57px;
    gap: 57px;
  }

  .order_total_price {
   /** margin-top: var(--downloadBtnMarginTop); */
  }

  .your_order_cart_buttons {
    align-items: center;
    margin-top: calc(2 * -3.5rem);
    margin-top: calc(2 * var(--downloadBtnMarginTop));
  }
}
.trackingOrderWrapper {
  padding: 16px 18px;
  padding-right: 10px;
  font-family: "Quicksand";
  width: 100%;
  margin-top: var(--pageMarginTop);
}

.trackingOrderContent {
  display: flex;
  padding-bottom: 30px;
}

.trackingOrderLeft {
  width: 60%;
  padding: 0px 100px;
}

.trackingOrderTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
  margin-bottom: 35px;
}

.trackingOrderHeaderContainer {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
}

.trackingOrderHeader {
  width: 50%;
}

.trackingOrderAbout1 {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 0;
}

.trackingOrderAbout2 {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #707070;
}

.trackingOrderImage {
  width: 400px;
  height: 300px;
}

.trackingOrderTrack {
  width: 40%;
  padding-top: 85px;
}

.trackingOrderDate,
.trackingOrderNames {
  font-style: normal;
  font-weight: 650;
  font-size: 25px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 20px;
}

.trackingOrderNames {
  margin-bottom: 0;
  font-size: 24px;
}

.orderedTodayContainer {
  display: flex;
  align-items: center;
}

.OrderTodayCheckbox {
  width: 24px;
  height: 24px;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-right: 28px;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
  position: relative;
}
.OrderTodayCheckbox::before {
  content: "\2713";
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #1b325e;
  opacity: 0;
  font-size: 1.2rem;
}
.OrderTodayCheckbox:checked::before {
  opacity: 1;
}

.trackingOrderline {
  margin-left: 12px;
  width: 1px;
  height: 100px;
  background-image: linear-gradient(to bottom, #1b325e, #d3d0d0);
}

.trackingOrderNotCompleted {
  margin-left: 12px;
  width: 1px;
  height: 100px;
  background-image: linear-gradient(to bottom, #d3d0d0, #d3d0d0);
}

.notDone {
  color: #d3d0d0;
}

@media screen and (max-width: 500px) {
  .trackingOrderWrapper {
    padding-right: 0;
  }

  .trackingOrderContent {
    flex-direction: column;
  }

  .trackingOrderLeft {
    width: 100%;
    padding: 0px;
  }

  .trackingOrderTitle {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
  }

  .trackingOrderAbout1 {
    font-size: 20px;
    line-height: 24px;
  }

  .trackingOrderAbout2 {
    font-size: 10px;
    line-height: 20px;
  }

  .trackingOrderImageContainer {
    margin-top: -27px;
  }

  .trackingOrderImage {
    width: 170px;
    height: 130px;
  }

  .trackingOrderTrack {
    width: 100%;
    padding-top: 0px;
    margin-top: -35px;
  }

  .trackingOrderDate,
  .trackingOrderNames {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }

  .trackingOrderline {
    height: 70px;
  }

  .trackingOrderNotCompleted {
    height: 70px;
  }

  .OrderTodayCheckbox {
    margin-right: 15px;
  }
}

@media screen and (max-width: 370px) {
  .trackingOrderImageContainer {
    margin-top: -13px;
  }

  .trackingOrderImage {
    width: 130px;
    height: 100px;
  }
}

.parentAddAddress {
    width: 100vw;
    padding: 19px;
    margin-top: var(--pageMarginTop);
    position: relative;
}

.headerSection {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 31px;

    color: #000000;
}

.breadcrumbs {
    font-size: 0.7rem;
}

.HeadingSent {
    font-size: 25px;
    margin-bottom: 20px;
}

.bodySection {
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}

.addAddreesRightimg {
    width: 100%;
}

.inputAddress {
    width: 100%;
    cursor: pointer;
}

.instructionCss {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    color: #0066C0;
}

.deleteIcon:hover, 
.deleteIcon:focus-visible {
    -webkit-animation: ring 3.5s ease-in-out;
    -webkit-transform-origin: 50% 4px;
    -moz-animation: ring 3.5s ease-in-out;
    -moz-transform-origin: 50% 4px;
    animation: ring 3.5s ease-in-out;
    transform-origin: 50% 4px;
    -webkit-animation-iteration-count: 3;
            animation-iteration-count: 3;
}

.no-address-found-text {
    font-size: 1rem;
}

.fromPayment-checkout-button {
    background-color: var(--primaryYellow)!important;
    padding: 1rem;
}

.fromPayment-checkout-text {
    font-size: 1.3rem;   
    font-weight: bold;
}

.fromPayment-checkout-button svg {
    margin-left: 0.5rem;
}

.fromPayment-section {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

@-webkit-keyframes ring {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }

    1% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }

    3% {
        -webkit-transform: rotate(-28deg);
                transform: rotate(-28deg);
    }

    5% {
        -webkit-transform: rotate(34deg);
                transform: rotate(34deg);
    }

    7% {
        -webkit-transform: rotate(-32deg);
                transform: rotate(-32deg);
    }

    9% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }

    11% {
        -webkit-transform: rotate(-28deg);
                transform: rotate(-28deg);
    }

    13% {
        -webkit-transform: rotate(26deg);
                transform: rotate(26deg);
    }

    15% {
        -webkit-transform: rotate(-24deg);
                transform: rotate(-24deg);
    }

    17% {
        -webkit-transform: rotate(22deg);
                transform: rotate(22deg);
    }

    19% {
        -webkit-transform: rotate(-20deg);
                transform: rotate(-20deg);
    }

    21% {
        -webkit-transform: rotate(18deg);
                transform: rotate(18deg);
    }

    23% {
        -webkit-transform: rotate(-16deg);
                transform: rotate(-16deg);
    }

    25% {
        -webkit-transform: rotate(14deg);
                transform: rotate(14deg);
    }

    27% {
        -webkit-transform: rotate(-12deg);
                transform: rotate(-12deg);
    }

    29% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }

    31% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg);
    }

    33% {
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }

    35% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }

    37% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }

    39% {
        -webkit-transform: rotate(-1deg);
                transform: rotate(-1deg);
    }

    41% {
        -webkit-transform: rotate(1deg);
                transform: rotate(1deg);
    }

    43% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}

@keyframes ring {
    0% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }

    1% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }

    3% {
        -webkit-transform: rotate(-28deg);
                transform: rotate(-28deg);
    }

    5% {
        -webkit-transform: rotate(34deg);
                transform: rotate(34deg);
    }

    7% {
        -webkit-transform: rotate(-32deg);
                transform: rotate(-32deg);
    }

    9% {
        -webkit-transform: rotate(30deg);
                transform: rotate(30deg);
    }

    11% {
        -webkit-transform: rotate(-28deg);
                transform: rotate(-28deg);
    }

    13% {
        -webkit-transform: rotate(26deg);
                transform: rotate(26deg);
    }

    15% {
        -webkit-transform: rotate(-24deg);
                transform: rotate(-24deg);
    }

    17% {
        -webkit-transform: rotate(22deg);
                transform: rotate(22deg);
    }

    19% {
        -webkit-transform: rotate(-20deg);
                transform: rotate(-20deg);
    }

    21% {
        -webkit-transform: rotate(18deg);
                transform: rotate(18deg);
    }

    23% {
        -webkit-transform: rotate(-16deg);
                transform: rotate(-16deg);
    }

    25% {
        -webkit-transform: rotate(14deg);
                transform: rotate(14deg);
    }

    27% {
        -webkit-transform: rotate(-12deg);
                transform: rotate(-12deg);
    }

    29% {
        -webkit-transform: rotate(10deg);
                transform: rotate(10deg);
    }

    31% {
        -webkit-transform: rotate(-8deg);
                transform: rotate(-8deg);
    }

    33% {
        -webkit-transform: rotate(6deg);
                transform: rotate(6deg);
    }

    35% {
        -webkit-transform: rotate(-4deg);
                transform: rotate(-4deg);
    }

    37% {
        -webkit-transform: rotate(2deg);
                transform: rotate(2deg);
    }

    39% {
        -webkit-transform: rotate(-1deg);
                transform: rotate(-1deg);
    }

    41% {
        -webkit-transform: rotate(1deg);
                transform: rotate(1deg);
    }

    43% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }

    100% {
        -webkit-transform: rotate(0);
                transform: rotate(0);
    }
}

@media screen and (max-width: 500px) {
    .address-breadcrumbs {
        font-size: 0.9rem!important;
        margin-bottom: 1rem;
    }
    .address_Footer_Edit {
        margin-top: -10px;
    }
}

@media screen and (min-width: 768px) {
    .parentAddAddress {
        width: 100vw;
        padding: 19px 153px;
    }

    .HeadingSent {
        font-size: 30px;
    }

    .bodySection {
        display: flex;
        flex-direction: row;
        grid-gap: 10px;
        gap: 10px;
    }

    .addAddreesRightimg {
        width: 100%;
        height: 450px;
    }

    .inputAddress {
        width: 95%;
        cursor: pointer;
    }
}
.loginSecWrapper {
  padding: 10px 90px;
  font-family: "Quicksand";
  margin-top: var(--pageMarginTop);
}

.loginSecLeft{
    width: 65%;
}

.loginSecDetails{
    background-color: #F8F8F8;
    padding: 50px 60px 130px 75px;
}
.breadcrumbs {
  font-size: 16px;
}

.loginSecPageName {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 17px;
  padding-left: 75px;
}

.loginSecTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: #000000;
  margin-bottom: 35px;
}

.loginSecDetailName {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 39px;
  color: #000000;
}

.loginSecUserName {
  font-style: normal;
  font-weight: 500;
  font-size: 27px;
  line-height: 36px;
  color: #000000;
}


.loginSecPara {
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.3;
  color: #000000;
}

.loginSecRightIcon img {
  width: 32px;
  height: 32px;
}

.loginSecRightIcon img:where(:hover, :focus-visible) {
  cursor: pointer;
  scale: 1.1;
  transition: all 0.1s ease-in-out;
}

.loginSecImageContainer{
    width: 35%;
    padding: 80px 140px 0 70px;
}

.loginSecImage {
  width: 333px;
  height: 338px;
}

.loginSecContent,
.loginSecDetailDiv {
  display: flex;
}

.loginSecContent{
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 50px;
}

.loginSecDetailDiv{
    justify-content: space-between;
}


.loginSecUserNameContainer{
    display: flex;
    align-items: center;
    grid-gap: 19px;
    gap: 19px;
    margin-bottom: 50px;
}

@media screen and (max-width : 500px) {
  .loginSecPageName {
  padding-left: 10px;
}

  .loginSecWrapper {
  padding: 10px 0px;
  }
  .loginSecLeft{
    width: 100%;
  }
  .loginSecDetails{
    background-color: #F8F8F8;
    padding: 20px 15px 80px 15px;
  }
  .loginSecDetailName {
  font-size: 24px;
  line-height: 30px;
}
.loginSecUserName {
  font-size: 22px;
  line-height: 20px;
}
.loginSecPara {
  font-size: 14px;
  line-height: 17px;
}
.loginSecMobileImageContainer{
  padding: 0 20px 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.loginSecImage {
  width: 143px;
  height: 148px;
  margin-top: -30px;
}
.loginSecTitle {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 10px;
}

.loginSecUserNameContainer,
.loginSecPara {
  margin-bottom: 1.2rem!important;
}

.loginSecRightIcon img {
  width: 1.4rem;
  height: 1.4rem;
}
.loginSecRightIcon {
  margin-right: 13px;
}

}
.changeNameContainer {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  font-family: "Quicksand";
  margin-top: var(--pageMarginTop);
}

.changeNameContent {
  padding: 10px 54px 5px 118px;
  display: flex;
  width: 100%;
}

.changeNameFormContainer {
  width: 60%;
}

.changeNameForm {
  margin-bottom: 35px;
}

/* Image Side  */

.changeNameImageContainer {
  width: 40%;
  padding-top: 50px;
}

.changeNameImageContainer img {
  width: 430px;
  height: 430px;
}

/* Form Side */

.changeNameHeading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 31px;
  color: #000000;
  margin-bottom: 40px;
}

.changeNameFormLabelTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 35px;
  line-height: 45px;
  color: #000000;
}

.changeNameInputDiv {
  padding: 10px;
  border: 1px solid #a6a6a6;
  width: 530px;
  margin-bottom: 13px;
  border-radius: 7px;
}

.changeNameInputField {
  border: none;
  outline: none;
  padding: 3px;
  width: 100%;
  font-size: 20px;
}

.changeNameInputField::-webkit-input-placeholder {
  font-size: 20px;
  color: black;
}

.changeNameInputField::placeholder {
  font-size: 20px;
  color: black;
}

.changeNamecheckBoxDiv {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  margin-top: 7px;
  margin-bottom: 17px;
}

.changeNameCheckbox {
  display: inline-block;
  width: 20px;
  border: 2px solid #0066c0;
}

.changeNameConfirmCheckbox {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #0066c0;
}

.changeNameButtonSection {
  display: flex;
  grid-gap: 60px;
  gap: 60px;
}

.changeNamesubmitButton {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
}

.changeNamesubmitButton p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.changeNamecancelButton {
  outline: none;
  border: none;
  width: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.changeNamecartButtons1Cancel {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #707070;
}

.changeNameFormLabelSubTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
}

.expandable-hide {
  background: #fff;
  overflow: hidden;
  padding: 0;
  margin-bottom: 0;
  border: none;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  height: 0;
}

.expandable-show {
  margin-bottom: 13px;
  padding: 10px;
  border: 1px solid #a6a6a6;
  height: auto;
  color: #000;
  overflow: auto;
  transition: all .3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@media screen and (max-width: 500px) {

  .changeNameContainer {
    position: relative;
  }

  .changeNameImageContainer {
    position: absolute;
    top: 0;
    right: 10px;
    z-index: -100;
    padding-top: 10px;
  }

  .changeNameImageContainer img {
    width: 150px;
    height: 150px;
  }

  .changeNameContent {
    padding: 10px 14px 5px 10px;
  }

  .changeNameFormContainer {
    width: 100%;
  }

  .changeNameInputDiv,
  .expandable-show {
    padding: 5px;
    max-width: 340px;
    width: 90%;
    margin-bottom: 10px;
    background-color: white;
  }

  .changeNameHeading {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 30px;
  }

  .changeNameFormLabelTitle {
    font-size: 25px;
    line-height: 33px;
  }

  .changeNameInputField::-webkit-input-placeholder {
    font-size: 15px;
  }

  .changeNameInputField::placeholder {
    font-size: 15px;
  }

  .changeNameFormLabelSubTitle {
    font-size: 17px;
    line-height: 22px;
  }

  .changeNameButtonSection {
    grid-gap: 30px;
    gap: 30px;
  }

}
/* .App {
  display: flex;
  width: 100%;
  align-items: stretch;
} */

.DashboardWrapper {
  display: flex !important;
  width: 100%;
  align-items: stretch;
}

.dashboard_Whole_Wrapper {
  /*font-family: 'Poppins', sans-serif;*/
  font-family: "Nunito Sans", sans-serif;
  /*background: #fafafa;*/
  background-color: #f5f9fc;
  min-width: 100%;
}

p.ml-3 {
  margin-left: 1rem !important;
}

/* a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
} */

/* a tag */
.dash_sidebar_a,
.dash_sidebar_a:hover {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

.dash_sidebar_a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.2s;
}

/* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #354052;
  color: #adb5bd;
  margin-left: -250px;
  transition: all 0.5s;
  position: fixed !important;

  left: 0;
  height: -webkit-fill-available;
  z-index: 1;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.sidebar.is-open {
  margin-left: 0;
  transition: 0.5s;
  position: relative;
  overflow-y: auto;
}

.sidebar-header {
  background: #1b325e;
  color: #adb5bd;
}

.sidebar-header h3 {
  color: #fff;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.collapse.show,
.collapsing {
  background: #313b4c;
}

.nav-item {
  /*color: #ced4da;*/
  color: #adb5bd;
  font-weight: 300;
}

.nav-item.open {
  color: #e9ecef;
  background: #313b4c;
}

.nav-item.active {
  color: #e9ecef;
  background: #2d3646 !important;
}

.nav-item:hover {
  background: #313b4c;
  color: #fff;
  transition: 0.2s;
}

.nav-item .svg-inline--fa {
  color: inherit;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%;
}

.sidebar-header>button {
  position: relative;
  float: right;
  margin: 0.5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}

/* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
.content {
  padding: 20px;
  min-height: 100vh;
}

.content.is-open {
  margin-left: 250px;
}

@media only screen and (max-width: 575.98px) {
  .DashboardWrapper {
    overflow: hidden;
  }

  .content.is-open {
    /*margin-left: 100%;*/
    display: none;
    transition: all 0.5s;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
    height: 100vh !important;
    position: relative !important;
  }

  .sidebar.is-open>.sidebar-header button {
    display: block;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}

.container a {
  text-decoration: none;
}

.dropdown-btn {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
}

.dropdown-btn p {
  margin-bottom: 0;
}

.content:where(.is-open)>.container {
  width: 950px;
}

#response-success:not(:empty) {
  background-color: rgb(125, 235, 125);
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
}

#response-error:not(:empty) {
  background-color: rgb(236, 142, 142);
  font-weight: bold;
  padding: 10px 0;
  margin: auto;
  text-align: center;
}

.voucher-ids {
  text-decoration: underline;
  color: black;
  cursor: pointer;
}

.voucher-ids:hover,
.voucher-ids:active {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.702);
}
.findStoreContainer {
    width: 100%;
    padding: 20px 100px;
    font-family: "Quicksand";
    font-style: normal;
    margin-top: var(--pageMarginTop);
  }
  
  .findStoreTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }
  
  /* Section Ends  */
  
  .findStoreMapSearchContainer {
    width: 410px;
    margin-bottom: 40px;
  }
  
  .findStoreMapTitle {
    font-weight: 700;
    font-size: 37px;
    line-height: 43px;
    color: #000000;
    margin-top: 40px;
  }

  .findStoreMapTitle span {
    text-transform: capitalize;
  }
  
  .findStoreMapInputTitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #707070;
    margin-top: 20px;
  }
  
  .findStoreMapSearch {
    display: flex;
    border: 1px solid #bebebe;
    padding: 5px 10px;
    border-radius: 6px;
  }
  
  .findStoreMapSearchIcon {
    margin-right: 10px;
    font-weight: 300;
    font-size: 1rem;
    color: #7F7F7F;
  }
  
  .findStoreMapSearchbar {
    outline: none;
    border: none;
    width: 390px;
  }
  .findStoreMapSearchbar::-webkit-input-placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }
  .findStoreMapSearchbar::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #7f7f7f;
  }
  
  /* Section Ends  */
  
  .findStoreContactHeader {
    margin-top: 20px;
    display: flex;
  
    grid-gap: 20px;
  
    gap: 20px;
    flex-direction: column;
  }
  
  .findStoreContactBurgerIcon {
    height: 32px;
    width: 32px;
  }
  
  .findStoreContactHeaderTitle,
  .findStoreContactPersonName {
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    margin-top: 2px;
    color: #000000;
  }
  
  .findStoreContactPersonAddress {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #707070;
    width: 500px;
  }
  
  .findStoreContactPersonIcon {
    height: 26px;
    width: 26px;
  }
  .findStoreContactPersonNumber {
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: #0066c0;
  }
  
  .findStoreContactPersonPhone {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 10px;
  }
  
  @media screen and (max-width: 500px) {
    .findStoreContainer {
      padding: 20px 20px;
    }
  
    .findStoreTitle {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      color: #000000;
    }
  
    /* Section Ends  */
  
    .findStoreMapSearch {
      padding: 1px 6px;
    }
    .findStoreMapSearchIcon {
      margin-right: 5px;
      font-weight: 300;
      font-size: 0.4rem;
    }
    .findStoreMapSearchContainer {
      width: 300px;
    }
    .findStoreMapSearchbar {
      width: 290px;
    }
    .findStoreMapSearchbar::-webkit-input-placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #7f7f7f;
    }
    .findStoreMapSearchbar::placeholder {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #7f7f7f;
    }
  
    /* Section Ends  */
    .findStoreContactHeaderTitle,
    .findStoreContactPersonName {
      font-size: 25px;
      line-height: 50px;
    }
  
    .findStoreContactPersonAddress {
      font-weight: 600;
      font-size: 16px;
      width: 330px;
    }
  
    .findStoreContactPersonIcon {
      height: 21px;
      width: 21px;
    }
    .findStoreContactPersonNumber {
      font-size: 18px;
      line-height: 28px;
    }
  
    .findStoreContactPersonPhone {
      display: flex;
      grid-gap: 10px;
      gap: 10px;
      margin-top: 10px;
    }
  }
.mapComponent{
  height: 100%;
}

.hand{
  cursor: pointer;
}
/* Banner  */

.retailBanner-component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
retailBanner {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  width: 100%;
}
.retailBanner img {
  width: 100%;
  max-height: 170px;
  min-height: 150px;
  object-fit: cover;
  margin-bottom: 10px;
}
.mobilescreen {
  display: block;
  margin-top: 10px;
}
.desktop {
  display: none;
}
@media screen and (min-width: 708px) {
  .retailBanner-component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    grid-gap: 10px;
    gap: 10px;
  }
  .retailBanner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .retailBanner img {
    width: 49.5%;
    max-height: 450px;
    min-height: 400px;
    object-fit: cover;
  }
  .mobilescreen {
    display: none;
  }
  .desktop {
    display: flex;
    margin-top: 8px;
  }
}

/* GetInTouch  */

.gtpContainer {
  display: flex;
  width: 100%;
}

.gtpContent,
.gtpImage {
  width: 50%;
}

.gtpImage {
  background-position: center;
  background-size: cover;
  border-radius: none;
  -webkit-transform: translateX(5px);
          transform: translateX(5px);
}

.gtpContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 80px;
}

.gtpSubContent {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.gtpSubContentImage {
  width: 60px;
  height: 60px;
  background-position: center;
  background-size: cover;
}

.gtpSubContentText {
  display: flex;
  flex-direction: column;
  width: 380px;
}

.gtpSubContentTextH1 {
  font-size: 22px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 3px;
}

/* Google Maps  */

.googleMapsContainer {
  width: 80%;
  padding: 40px;
  margin: 0 auto;
  border-radius: 10px;
  overflow: hidden;
}

.googleMapsContainerHeader {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-bottom: 50px;
}

.googleMapsDirectionHeading {
  font-weight: 700;
  font-size: 40px;
  line-height: 80px;
  color: #1b325e;
}

.northIcon {
  width: 65px;
  height: 65px;
  background-position: center;
  background-size: cover;
}

@media screen and (max-width: 500px) {
  .gtpContainer {
    flex-direction: column;
  }

  .gtpContent,
  .gtpImage {
    width: 100%;
  }

  .gtpImage {
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
  }

  .gtpContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 3px;
  }

  .gtpSubContent {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
  }

  .gtpSubContentImage {
    width: 60px;
    height: 60px;
    background-position: center;
    background-size: cover;
  }

  .gtpSubContentText {
    display: flex;
    flex-direction: column;
    width: 200px;
  }

  .gtpSubContentTextAddress {
    width: 260px;
  }

  .gtpSubContentTextH1 {
    font-size: 22px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 3px;
  }

  .googleMapsContainer {
    width: 90%;
    padding: 10px;
  }

  .googleMapsContainerHeader {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .googleMapsDirectionHeading {
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    color: #1b325e;
  }

  .northIcon {
    width: 35px;
    height: 35px;
  }
}

.privacy-policy-container {
    width: 80vw;
    margin-top: var(--pageMarginTop);
    margin-bottom: 2rem;
}
.privacy-policy-container p {
    margin-bottom: 0!important;
}

.privacy-policy-container p > span {
    font-weight: bold;
    color: black;
}
.privacy-policy-container ol {
    list-style-position: inside;
}
.privacy-policy-container ol li::marker {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.756);
}

.secondSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    margin: 10px auto;
    width: 70%;
    grid-gap: 20px;
    gap: 20px;
    text-align: justify !important;
    color: #707070 !important;
}

.secondSectionHeader {
    font-weight: 700;
    color: #000000;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    .privacy-policy-container {
        width: 90vw;
    }
    .secondSection {
        width: 100%;
    }
}


.your-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .your-form {
    width: 300px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    display: grid;
    place-content: center;
  }
  
  .your-form .MuiTextField-root {
    margin-bottom: 20px;
  }
  
  .your-form .submit-button {
    width: 100%;
    margin-top: 20px;
  }
.IFD_ifd-wrapper__1eL2h {
  background: #4804ae89;
  width: 100%;
}
.IFD_container__16VnJ {
  position: relative;
  height: 100vh;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 0 15px 2px #381867c4;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  background: radial-gradient(65.76% 65.76% at 52.92% 34.24%, #492e72 43.01%, #381867 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}
.IFD_poster-container__2z-Fd {
  opacity: 0;
}
.IFD_poster-container__2z-Fd::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.IFD_thumbnail-container__3TnSN {
  position: absolute;
  bottom: 30px;
  left: 15px;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}
.IFD_thumbnail__HvinC {
  width: 45px;
  height: 45px;
  border: 2px solid #ffffff;
  background-color: #381867;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}
.IFD_thumbnail__HvinC img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.IFD_primary-floating-button__VmSzD {
  position: absolute;
  bottom: 20px;
  left: 50%;
  border-radius: 0 0 0 1rem;
  background: linear-gradient(180deg, #efc255 50.31%, #dd9e2c 100%);
  padding: 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 178px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg) translateX(-50%);
          transform: skew(-20deg) translateX(-50%);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 10px auto 0;
  z-index: 1;
  scale: 1;
  -webkit-transform-origin: left;
          transform-origin: left;
}

.IFD_primary-floating-button__VmSzD p {
  font-size: 16px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0.1px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  font-family: NeoSansPro;
  text-align: center;
  color: #41197b;
  cursor: pointer;
}
.IFD_primary-floating-button__VmSzD:hover {
  scale: 1.06;
}
.IFD_primary-floating-button__VmSzD:active {
  scale: 0.92;
}
/* ---------------------------------------------- */

.IFD_steps-counter__1ndv6 {
  /* width: 100%; */
  /* position: absolute; */
  /* bottom: 0; */
  padding-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  background: #381867d6;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  opacity: 0;
  height: 75px;
  min-width: 350px;
  width: 100%;
  max-width: 400px;
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.IFD_steps-counter__1ndv6.IFD_absolute__2FQLg {
  position: absolute;
}

.IFD_steps-counter-text__3E3zC {
  display: flex;
  width: 100%;
  max-width: 300px;
  justify-content: space-around;
  margin-top: 5px;
}
.IFD_steps-counter-text__3E3zC p {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 1px;

  color: #ffffff;
}

.IFD_ifd-from-wrapper__1_nsk {
  max-height: 100%;
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  padding-bottom: 75px;
}
.IFD_ifd-from-wrapper__1_nsk::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.IFD_form-screen__2Y7NJ {
  background: radial-gradient(65.76% 65.76% at 52.92% 34.24%, #553586 43.01%, #451a84 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  /* transition: all 0.3s ease; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  height: -webkit-max-content;
  height: max-content;
  min-height: 100%;
}

/* @media screen and (min-width: 768px) {
  .container {
    height: calc(100vh - 68px);
  }
} */

._IFD_preloader__1_War {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}

/* section {
  padding: 15px;
  min-width: 100%;
} */

._IFD_header__3PMdH {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  margin-top: 0px;
  margin-bottom: 25px;
}
._IFD_header__3PMdH svg {
  margin-top: 7px;
}
._IFD_header-msg__3AWar {
  font-family: "Montserrat";
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #ffffff;
}

._IFD_form-container__DMPhF {
  margin: 0 auto;
  text-align: center;
}
._IFD_form-heading__1Rf11 {
  font-family: NeoSansPro, Geneva, Verdana, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 20px;
  text-align: left;
  padding: 0 15px;
}

._IFD_form-input__1B-B9 {
  background: #ffffff;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 10px 10px;
  height: 48px;
  max-width: 328px;
  font-family: "Montserrat";
  margin-bottom: 20px;
  transition: 0.1s;
}
._IFD_form-input__1B-B9._IFD_invalid__36XsK {
  border: #ff1200f5 2px solid;
  color: #cc1507f5;
}
._IFD_form-input__1B-B9._IFD_invalid__36XsK::-webkit-input-placeholder {
  color: #cc14078f !important;
}
._IFD_form-input__1B-B9._IFD_invalid__36XsK::placeholder {
  color: #cc14078f !important;
}
._IFD_form-input__1B-B9:focus-within {
  outline: none;
}

._IFD_form-input__1B-B9::-webkit-input-placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #8d9091;
}

._IFD_form-input__1B-B9::placeholder {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  color: #8d9091;
}

._IFD_primary-button__JZuy1 {
  border-radius: 0 0 0 1rem;
  background: linear-gradient(180deg, #efc255 50.31%, #dd9e2c 100%);
  /* padding: 16px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 308px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 10px auto 0;
  height: 50px;
}

._IFD_primary-button__JZuy1 p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  font-family: NeoSansPro;
  text-align: center;
  color: #ffffff;
}

._IFD_primary-button__JZuy1 svg {
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
}

._IFD_secondary-button__2KIj0 {
  margin: 0 auto;
  cursor: pointer;
}

._IFD_form-divider__keszD {
  border: 1px solid rgba(255, 255, 255, 0.386);
  -webkit-filter: blur(0.5px);
          filter: blur(0.5px);
  margin: 50px 20px;
}

/* -----> Step 2 <------  */
._IFD_btn-send-otp__3R4Tk {
  position: absolute;
  right: 30px;
  top: 16%;
  /* transform: translateY(-50%); */
  width: 79px;
  height: 27px;
  border: none;
  background: #efc255;
  border-radius: 4px;

  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
}
._IFD_store-name-heading__1ogks {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  /* line-height: 20px; */
  text-align: center;
  color: #ffffff;
  position: relative;
  margin: 10px auto 20px;
  color: #b27e00;
}

._IFD_store-name-heading__1ogks::before {
  content: attr(data-after);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  color: white;
  margin: 0 auto;
  -webkit-transform: translateY(-2px);
          transform: translateY(-2px);
}

._IFD_otp-input-container__3zIwG {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 328px;
  margin: 10px auto 20px;
}

/* -----> Step 3 <------  */
._IFD_product-price-info__3PsH8 {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  padding: 0 20px;
  text-align: left;
  letter-spacing: -0.01em;

  color: #ffffff;
}
._IFD_react-select-container__3TR-A {
  max-width: 326px;
  max-height: 48px;
  margin: 0 auto 20px;
  text-align: initial;
}
._IFD_ask-otp__3g3Tq {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  width: 70%;
  margin: 0px auto 15px;
  color: #ffffff;
}
._IFD_btn-send-otp-manager__3U3_V {
  width: 79px;
  height: 27px;
  border: none;
  background: #efc255;
  border-radius: 4px;

  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  /* margin-top: 30px;
  margin-bottom: 20px; */
}
._IFD_rate-experience-text__1OW-a {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #ffffff;
  margin: 50px auto 10px;
  width: 85%;
}

._IFD_rate-experience-svg__2NELw path {
  cursor: pointer;
}

._IFD_emoji-group__1vhwO:hover ._IFD_emoji-circle__1kBkZ {
  fill: #e3d1fe;
}
/* -----> Spin Wheel Page <------  */

._IFD_spin-wheel-container__30f59 {
  max-width: 100%;
  width: 100%;
  overflow-x: hidden;
}

._IFD_spin-wheel-container__30f59 #_IFD_spin-wheel__3xf5G {
  width: 100%;
  scale: 1.1;
}

._IFD_product-card-section__8kv1E {
  background-color: white;
  margin: 30px 0 10px;
}

._IFD_product-card-top-heading__1ftpD {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  padding: 15px 0;
  margin: 0;
  color: #41236d;
}

._IFD_product-card-container__1C0Nt {
  /* width: max-content; */
  padding: 0px 16px 35px 16px;

  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: nowrap;
  overflow: auto;
}

._IFD_product-card__1W8cA {
  min-width: 150px;
  height: 193px;
  position: relative;
  background: #b5b5b5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
}
._IFD_product-card__1W8cA img {
  object-fit: contain;
}

._IFD_product-card-inner__2oAIk {
  position: absolute;
  width: 118px;
  height: 66px;
  left: 16px;
  top: 150px;

  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(157, 154, 154, 0.59);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 5px;
}

._IFD_product-card-inner__2oAIk ._IFD_mrp__2wqXx {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 5px;
  letter-spacing: 0.015em;
  -webkit-font-feature-settings: "smcp";
          font-feature-settings: "smcp";
  font-variant: small-caps;
  text-decoration: line-through;
  color: #6d747a;
}
._IFD_product-card-inner__2oAIk ._IFD_mop__WN7FH {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #08090a;
}
._IFD_product-card-inner__2oAIk ._IFD_name__1xo6h {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.004em;
  text-transform: capitalize;
  margin: 0;
  text-align: center;

  color: #6d747a;
}

._IFD_spin-wheel-spinner-heading__xFvPA {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  position: relative;
  margin: 35px auto 0px auto;
  color: #fff;
  width: 80%;

  text-shadow: 0px 2px 4px rgba(237, 162, 93, 0.8);
}
._IFD_center-spin-btn__2whUk {
  cursor: pointer;
}

._IFD_spin-circle__94N0Q {
  -webkit-transform-origin: center;
          transform-origin: center;
}

@-webkit-keyframes _IFD_spin__2D3Tp {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(6000deg);
            transform: rotate(6000deg);
  }
}

@keyframes _IFD_spin__2D3Tp {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(6000deg);
            transform: rotate(6000deg);
  }
}

._IFD_product-card-container__1C0Nt::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* ---------- */

._IFD_product-reveal-container__3QvV6 {
  max-width: 100%;
  width: 100%;
  background-color: #41236d;
}
._IFD_product-reveal-heading__1htsX {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 35px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 40px;

  text-shadow: 0px 2px 4px rgba(237, 162, 93, 0.8);
}

._IFD_product-img-box__1BkSd {
  position: relative;
  width: 100%;
  text-align: center;
}
._IFD_product-img-box__1BkSd img {
  width: auto;
  max-height: 200px;
}
._IFD_product-reveal-thankyou-text__3G-Ip {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #ffffff;

  text-shadow: 0px 2px 4px rgba(237, 162, 93, 0.8);
  width: 70%;
  margin: 70px auto 20px;
}

._IFD_coupon-card__1UOh0 {
  margin: auto;
  width: 300px;
  height: 240px;
  position: relative;
  background: #ffffff;
  border-radius: 10px;
  padding: 10px 0;
  background-image: url(/static/media/coupon-code-bg.5d39d684.png);
  background-repeat: no-repeat;
  background-position: bottom;
}
._IFD_coupon-card-code-heading__IYunJ {
  margin: auto;
  width: 153px;
  height: 30px;
  background: #ffd36d;
  border-radius: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: NeoSansPro;
  text-shadow: 0px 1px 9px rgba(255, 255, 255, 0.35);
}
._IFD_coupon-card-code-number__1nJV_ {
  margin-top: 12px;
  font-family: NeoSansPro;
  font-size: 25px;
  line-height: 28px;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.01em;
  color: #dd9e2c;
  /* background: linear-gradient(180deg, #efc255 50.31%, #dd9e2c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */

  text-shadow: 0px 1px 9px rgba(255, 255, 255, 0.35);
}
._IFD_terms-conditions-box__5VdD2 {
  padding: 17px 15px;
}
._IFD_terms-conditions-box__5VdD2 h4 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  margin: 0;
}
._IFD_terms-conditions-box__5VdD2 p {
  font-family: "Montserrat";
  font-style: normal;
  /* font-weight: 600; */
  font-size: 10px;
  line-height: 13px;
  margin-bottom: 7px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #ffffff;
}
._IFD_terms-conditions-box__5VdD2 button {
  margin: auto;
  width: 115px;
  height: 28px;
  outline: none;
  border: none;
  background: #ffffff;
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}
._IFD_terms-conditions-box__5VdD2 button span {
  font-family: NeoSansPro;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 1px;
  color: #dd9e2c;
  /* background: linear-gradient(180deg, #efc255 50.31%, #dd9e2c 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */

  text-shadow: 0px 0.2px 0px rgba(0, 0, 0, 0.41);
}

._IFD_social-share-buttons__nG_0E {
  display: flex;
  justify-content: space-between;
  max-width: 250px;
  margin: 20px auto;
}

@media screen and (max-width: 400px) {
  ._IFD_store-name-heading__1ogks {
    font-size: 25px;
  }
  ._IFD_btn-send-otp__3R4Tk {
    right: 25px;
  }
}

.Main {
  background: radial-conic-gradient(#E16F91 0 15deg, #E0517F 15deg, 30deg);
  height: -webkit-max-content;
  height: max-content;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 100vh;
}

.final_bg {
  background: url(/static/media/final_bg.47505508.png);
  height: -webkit-max-content;
  height: max-content;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  min-height: 750px;
  background-position: center;
}

.offers {
  width: 350px;
  top: 24%;
  left: 14%;
}

.big-gift-image {
  max-width: 350px;
  width: 100%;
}

/* .Main2 {
  height: max-content;
  background-image: url(../../assets/smartBandhan/SmartBandhanBG.png);
  background-size: cover;
  background-position: center center;
  color: white;
  min-height: 100vh;
} */

.main__dom {
  width: 100%;
  min-width: 320px;
}

.main__container {
  margin: auto 0px;
  margin-bottom: -32px;
  position: relative;
  z-index: 1;
}

.sub-container {
  /* max-width: 600px; */
  margin: auto;
}

.fourthAnniversary-img {
  max-width: 400px;
  width: 100%;
}

.mainlogo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.border-control {
  margin-left: 0;
  max-height: 100%;
}

.border-control2 {
  float: right;
  max-height: 100%;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.logo-control {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0%;
}

.flower-bottom {
  position: absolute;
  bottom: 0;
  width: 93vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 320px) {
  .main__dom {
    width: 320px !important;
  }
}

@media (max-width: 480px) {
  .offers {
    left: 2%;
  }
}

@media (max-width: 380px) {
  .final_bg {
    background-size: contain;
    color: white;
  }

  .offers {
    width: 150px;
    top: 40%;
    left: 22%;
  }
}

/* Media Query for low resolution  Tablets, Ipads */
@media (min-width: 481px) and (max-width: 767px) {}

/* Media Query for Tablets Ipads portrait mode */
@media (min-width: 768px) and (max-width: 1024px) {
  .main__dom {
    width: 320px !important;
  }
}

/* Media Query for Laptops and Desktops */
@media (min-width: 1025px) and (max-width: 1280px) {}

/* Media Query for Large screens */
@media (min-width: 1281px) {}

.tet {
  margin: 0px;
  padding: 0px;
  width: 100%;
  outline: none;
  height: 30px;
  border-radius: 5px;
}

.butuun {
  position: absolute;
  top: 0;
  border-radius: 5px;
  right: 0px;
  z-index: 2;
  border: none;
  top: 2px;
  height: 30px;
  cursor: pointer;
  color: white;
  background-color: #1e90ff;
  -webkit-transform: translateX(2px);
          transform: translateX(2px);
}

.ig {
  /* adjust the below to control the 3D effect */
  --x: 10px;
  --y: 20px;
  -webkit-transform: perspective(1000px) rotateX(40deg);
          transform: perspective(1000px) rotateX(40deg);
  -webkit-transform: perspective(1000px) rotateX(var(--_a, 40deg));
          transform: perspective(1000px) rotateX(var(--_a, 40deg));
  /* */
  -webkit-clip-path: polygon(var(--y) var(--y),
      calc(100% - var(--y)) var(--y),
      calc(100% - var(--y)) calc(100% - var(--y)),
      calc(100% - var(--y) - var(--x)) 100%,
      calc(var(--x) + var(--y)) 100%,
      var(--y) calc(100% - var(--y)));
          clip-path: polygon(var(--y) var(--y),
      calc(100% - var(--y)) var(--y),
      calc(100% - var(--y)) calc(100% - var(--y)),
      calc(100% - var(--y) - var(--x)) 100%,
      calc(var(--x) + var(--y)) 100%,
      var(--y) calc(100% - var(--y)));
  -webkit-clip-path: polygon(var(--y) var(--y),
      calc(100% - var(--y)) var(--y),
      calc(100% - var(--y)) calc(100% - var(--y)),
      calc(100% - var(--y) - var(--x)) var(--_c, 100%),
      calc(var(--x) + var(--y)) var(--_c, 100%),
      var(--y) calc(100% - var(--y)));
          clip-path: polygon(var(--y) var(--y),
      calc(100% - var(--y)) var(--y),
      calc(100% - var(--y)) calc(100% - var(--y)),
      calc(100% - var(--y) - var(--x)) var(--_c, 100%),
      calc(var(--x) + var(--y)) var(--_c, 100%),
      var(--y) calc(100% - var(--y)));
  outline: var(--y) solid rgba(0, 0, 0, 0.4);
  outline-offset: calc(-1 * var(--y));
  padding: var(--y) var(--y) 0 var(--y);
  transition: 1s;
}

.rt {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
  position: fixed;
  bottom: 90px !important;
  left: 10px !important;
}

.bts {
  /* Firefox */
  -webkit-animation: scale 1s;
  /* Safari and Chrome */
  /* Opera */
  animation: scale 1s;
}

@keyframes scale {
  from {
    width: 0px;
    height: 0px;
  }

  to {
    width: 200px;
    height: 200px;
  }
}

@-webkit-keyframes scale {

  /* Safari and Chrome */
  from {
    width: 200px;
    height: 200px;
  }

  to {
    width: 0px;
    height: 0px;
  }
}

/* .outer-2{
  position: relative;
  left: 80%;
  top: 20%;
  margin-top: 0rem;
} */

.outer-2>img {
  position: absolute;
  z-index: 1;
  left: 20px;
  top: 80px;
}

.rating-icon {
  transition: 350ms ease-in;
}

.logo-img {
  margin: 20px 0;
  width: 400px;
}

.offer-date {
  background: linear-gradient(90deg,
      #dd9f47 1.34%,
      #f6ebaf 52.31%,
      #dd9f47 101.29%);
  max-width: -webkit-max-content;
  max-width: max-content;
  margin: auto;
  color: #102250;
  font-size: 18px;
  border-radius: 4px;
  padding: 4px 8px;
}

.checkout-offer-text {
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 700;
  font-size: 1.15rem;
  color: #ffffff;
  text-transform: uppercase;
}

.outer-3>img {
  position: absolute;
  z-index: 1;
  right: 80px;
  top: 200px;
}

.outer-2>.pt {
  position: relative;
  z-index: 2;
}

.outer-1 {
  position: relative;
  left: 20%;
  margin-top: 0rem;
  /* width: 100px;
  height: 100px; */
}

.outer-1>img {
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;
}

.outer-1>.pt {
  position: relative;
  z-index: 2;
}

.footer__main {
  margin-top: 400px;
}

.ring__main {
  /* margin-top: -106px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 4px solid red; */
  width: 320px;
  flex-direction: column;
}

.ring__main__float {
  /* margin-top: -506px !important; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  flex-direction: column;
  display: flex !important;
  justify-content: center !important;
  /* border: 4px solid blue; */
  touch-action: none;
  pointer-events: none;
}

.ring__main__float>h6 {
  display: none !important;
}

.curtains {
  /* Firefox */
  -webkit-animation: curtain-animation infinite ease-in 4s alternate-reverse;
  /* Safari and Chrome */
  /* Opera */
  animation: curtain-animation infinite ease-in 4s alternate-reverse;
}

#stage-container {
  width: 70%;
  margin: 0 auto;
}

@keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@-webkit-keyframes curtain-animation {
  0% {
    width: 33px;
  }

  33% {
    width: 37px;
  }

  66% {
    width: 40px;
  }

  100% {
    width: 43px;
  }
}

@keyframes brightness-animation {
  0% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }

  33% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  66% {
    -webkit-filter: brightness(1.4);
            filter: brightness(1.4);
  }

  100% {
    -webkit-filter: brightness(1.9);
            filter: brightness(1.9);
  }
}

@-webkit-keyframes brightness-animation {
  0% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }

  33% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  66% {
    -webkit-filter: brightness(1.4);
            filter: brightness(1.4);
  }

  100% {
    -webkit-filter: brightness(1.9);
            filter: brightness(1.9);
  }
}

.main-prize {
  display: grid;
  place-content: center;
}

.btn-box-shadow {
  box-shadow: 0px 0px 22px 5px #e7a500a6;
}

#fifth-page-heading {
  display: grid;
  place-content: center;
  font-size: 1.3rem;
  padding: 20px 0;
  font-weight: bold;
  text-transform: capitalize;
  width: 60%;
  text-align: center;
  margin: auto;
}

.fifth-image-animation-start {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-filter: brightness(1.3);
          filter: brightness(1.3);
  transition: all 200ms ease-in;
}

.fifth-image-animation-end {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-filter: brightness(0.9);
          filter: brightness(0.9);
  transition: all 400ms ease-in;
}

@keyframes brightness-animation-game {

  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
}

@-webkit-keyframes brightness-animation-game {

  /* Safari and Chrome */
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
  }

  60% {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@-webkit-keyframes rotation {

  /* Safari and Chrome */
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

.start-to-play-text {
  color: yellow;
  font-weight: bold;
  letter-spacing: 2px;
  /* Firefox */
  -webkit-animation: brightness-animation infinite ease-in 1s alternate-reverse;
  /* Safari and Chrome */
  /* Opera */
  animation: brightness-animation infinite ease-in 1s alternate-reverse;
  margin-bottom: -15px;
  font-size: 1.5rem;
}

input[type="date"]::before {
  color: #495057;
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

@-webkit-keyframes background-change {
  0% {
    background-color: initial;
    color: rgb(131, 128, 128);
  }

  33% {
    background-color: #f1b708;
  }

  66% {
    background-color: #e6ae08;
  }

  100% {
    background-color: #d19e06;
    color: black;
  }
}

@keyframes background-change {
  0% {
    background-color: initial;
    color: rgb(131, 128, 128);
  }

  33% {
    background-color: #f1b708;
  }

  66% {
    background-color: #e6ae08;
  }

  100% {
    background-color: #d19e06;
    color: black;
  }
}

.viewOffer-btn {
  background: #401011;
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

/* .sub-container {
background-color: #d19e06;
} */

.border-container img {
  position: absolute;
  animation: prize-animation 4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.border-container img[data-position="right"] {
  right: 0;
  height: 100vh;
  width: 25px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  /* min-height: 825px; */
  z-index: 1;
}

.border-container img[data-position="left"] {
  left: 0;
  height: 100vh;
  width: 25px;
  /* min-height: 825px; */
  z-index: 1;
}

.border-container img[data-position="bottom"] {
  bottom: 0;
  width: 100vw;
  height: 25px;
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  z-index: 1;
}

.border-container img[data-position="top"] {
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 25px;
}

.main-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  animation: prize-animation 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.main-logo img[alt="main-logo"] {
  width: 290px;
}

.main-logo img[alt="hand_logo"] {
  width: 150px;
  margin-top: -13px;
  margin-left: 4px;
}

.main-logo img[alt="flower_logo"] {
  width: 300px;
  margin-top: -43px;
  margin-left: 4px;
  z-index: -1;
}

img[alt^="baloon"] {
  position: absolute;
}

img[alt="baloon_1"] {
  width: 190px;
  right: 15%;
  top: 10%;
  -webkit-animation: baloon-animation-one 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite forwards;
          animation: baloon-animation-one 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite forwards;
}

img[alt="baloon_2"] {
  width: 190px;
  left: 45%;
  top: 30%;
  -webkit-animation: baloon-animation-two 45s cubic-bezier(0.215, 0.610, 0.355, 1) 4s infinite forwards;
          animation: baloon-animation-two 45s cubic-bezier(0.215, 0.610, 0.355, 1) 4s infinite forwards;
}

img[alt="baloon_3"] {
  width: 190px;
  right: 5%;
  top: 45%;
  -webkit-animation: baloon-animation-three 45s cubic-bezier(0.6, -0.28, 0.735, 0.045) 10s infinite forwards;
          animation: baloon-animation-three 45s cubic-bezier(0.6, -0.28, 0.735, 0.045) 10s infinite forwards;
}

img[alt="baloon_4"] {
  width: 190px;
  left: 10%;
  top: 52%;
  -webkit-animation: baloon-animation-four 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 12s infinite forwards;
          animation: baloon-animation-four 30s cubic-bezier(0.455, 0.03, 0.515, 0.955) 12s infinite forwards;
}

img[alt="baloon_5"] {
  width: 190px;
  left: 0%;
  bottom: 0%;
  -webkit-animation: baloon-animation-five 35s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite forwards;
          animation: baloon-animation-five 35s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite forwards;
}

img[alt^="man"] {
  position: absolute;
  bottom: 0;
  width: 140px;
}

img[alt="man_right"] {
  right: 0;
}

img[alt="man_left"] {
  left: 0;
}

img[alt="flower_left"] {
  position: relative;
  left: 0;
  width: 140px;
  z-index: 1;
}

.watch {
  width: 89%;
  margin-left: -114px;
  scale: 1.2;
}

img[alt="flower_right"] {
  position: relative;
  width: 140px;
  z-index: 2;
}


input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

.getOffer-btn {
  border-radius: 12px;
  margin: auto;
}

.page_3_img_main {
  position: absolute;
  height: 485px;
  left: 0;
  margin-top: -96px;
  margin-left: -21px;
}

.prize-img {
  position: absolute;
  animation: prize-animation 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.prize-img[data-identifier="image"] {
  width: 158px;
  left: 28%;
  top: 17px;
}

.prize-img[data-identifier="cashback9"] {
  width: 174px;
  left: 27%;
  top: 79px;
}

.prize-img[data-identifier="cashback8"] {
  width: 174px;
  left: 27%;
  top: 62px;
}

.prize-img[data-identifier="cashback7"] {
  width: 187px;
  left: 24%;
  top: 46px;
}

.prize-img[data-identifier="cashback6"] {
  width: 164px;
  left: 27%;
  top: 46px;
}

.prize-img[data-identifier="cashback5"] {
  width: 164px;
  left: 27%;
  top: 46px;
}

.prize-img[data-identifier="cashback4"] {
  width: 149px;
  left: 30%;
  top: 38px;
}

.prize-img[data-identifier^="percent"] {
  width: 198px;
  left: 25%;
  top: 89px;
}

.prize-img[data-identifier="percent1"] {
  width: 198px;
  left: 24%;
  top: 82px;
}

img[alt^="Hand2"] {
  position: absolute;
  bottom: 8px;
  width: 372px;
  left: 11px;
}

img[alt="taj_background"] {
  position: absolute;
  width: 343px;
  left: 0px;
  bottom: 0px;
}

img[alt^="flowerleft"] {
  position: absolute;
  bottom: -2px;
  width: 120px;
  left: -7px;
  z-index: 3;
}

img[alt^="flowerright"] {
  position: absolute;
  bottom: 0px;
  width: 120px;
  right: -10px;
  z-index: 3;
}

@-webkit-keyframes prize-animation {
  0% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    -webkit-filter: contrast(0.6);
            filter: contrast(0.6);
  }

  25% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    -webkit-filter: contrast(1);
            filter: contrast(1);
  }

  50% {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85);
    -webkit-filter: contrast(1.1);
            filter: contrast(1.1);
  }

  75% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    -webkit-filter: contrast(2);
            filter: contrast(2);
  }

  100% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-filter: contrast(1);
            filter: contrast(1);
  }
}

@keyframes prize-animation {
  0% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    -webkit-filter: contrast(0.6);
            filter: contrast(0.6);
  }

  25% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    -webkit-filter: contrast(1);
            filter: contrast(1);
  }

  50% {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85);
    -webkit-filter: contrast(1.1);
            filter: contrast(1.1);
  }

  75% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    -webkit-filter: contrast(2);
            filter: contrast(2);
  }

  100% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-filter: contrast(1);
            filter: contrast(1);
  }
}

.page_1_submit_btn,
.page_3_btn {
  border: none;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25098039215686274);
  background: #1b325e;
  text-transform: uppercase;
}

:where(.page_3_btn, .page_1_submit_btn) span {
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
  display: inline-block;
}

.conatiner-parent {
  z-index: 3;
  width: 75%;
}

.Main2 {
  overflow: hidden;
}

@media (max-width: 500px) {
  .main-logo img[alt="main-logo"] {
    width: 240px;
  }

  .main-logo img[alt="hand_logo"] {
    width: 185px;
    margin-top: -9px;
  }

  img[alt="baloon_1"] {
    width: 111px;
    right: 5%;
    top: 4%;
  }

  img[alt="baloon_2"] {
    width: 80px;
    left: 8%;
    top: 15%;
  }

  img[alt="baloon_3"] {
    width: 65px;
    right: 4%;
    top: 30%;
  }

  img[alt="baloon_4"] {
    width: 48px;
    left: 5%;
    top: 46%;
  }

  img[alt="baloon_5"] {
    width: 160px;
    left: -3%;
    bottom: 4%;
  }

  .input-group {
    margin: auto;
    margin-top: 0 !important;
  }

  .user-otp-text {
    font-size: 0.9rem;
  }

  .user-otp-sentBtn {
    font-size: 0.8rem;
  }

  .Main2 .otp-input {
    padding: 0.2rem 0.75rem;
    border-radius: 2px;
  }

  img[alt^="man"] {
    width: 92px;
  }

  /* form[data-pagenumber="first"] {
    min-height: 87ch;
  } */
  /* PAGE 2 */
  /* form[data-pagenumber="second"] {
    min-height: 75ch;
  } */


  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    position: absolute;
    width: 400px;
    left: 20px;
    bottom: 18px;
  }

  .store-input {
    width: 85%;
  }

  .rating-text {
    font-size: 1rem;
    font-weight: 700;
    width: 85%;
    margin: auto;
  }

  .rating-icon {
    width: 50px;
    height: 50px;
  }

  /* PAGE 3 */
  form[data-pagenumber="third"] {
    min-height: 70ch;
  }

  .Main2:has(form[data-pagenumber="third"]) img[alt="taj_background"] {
    width: 403px;
    left: -2px;
    bottom: 19px;
  }

  /* PAGE 4 */
  form[data-pagenumber="fourth"] {
    min-height: 70ch;
  }

}

@media (max-width: 400px) {
  .conatiner-parent {
    width: 82%;
  }

  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    width: 367px;
  }

  img[alt="flower_right"] {
    right: 67px !important;
  }
}

@media (max-width: 360px) {
  img[alt="flower_right"] {
    right: 85px !important;
  }
}

@media (min-width: 500px) {
  .Main2:has(form[data-pagenumber="second"]) img[alt="taj_background"] {
    width: 100vw;
  }

  .page_3_img_main {
    margin-left: 15%;
  }

  .Main2:has(form[data-pagenumber="third"]) #submit-otp-btn {
    top: 244px !important;
  }

  .Main2:has(form[data-pagenumber="third"]) img[alt="taj_background"] {
    width: 100vw;
  }

  .Main2:has(form[data-pagenumber="third"]) .container {
    height: 70ch;
  }
}

@media (max-width: 370px) {
  .page_3_img_main {
    height: 424px;
  }
}

@media screen and (width: 320px) and (height: 480px) {
  .page_3_img_main {
    height: 391px;
  }

  .congo-img {
    width: 248px;
    position: relative;
    top: 193px;
    margin-right: 10px;
  }

  .page_3_btn {
    top: 230px !important;
  }
}


@-webkit-keyframes baloon-animation-one {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}


@keyframes baloon-animation-one {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}

@-webkit-keyframes baloon-animation-two {
  0% {
    top: 15%;
    left: 8%;
  }

  14% {
    top: 30%;
    left: 24%;
  }

  28% {
    top: 45%;
    left: 38%;
  }

  42% {
    top: 65%;
    left: 48%;
  }

  56% {
    top: 45%;
    left: 38%;
  }

  74% {
    top: 30%;
    left: 24%;
  }

  100% {
    top: 15%;
    left: 8%;
  }
}

@keyframes baloon-animation-two {
  0% {
    top: 15%;
    left: 8%;
  }

  14% {
    top: 30%;
    left: 24%;
  }

  28% {
    top: 45%;
    left: 38%;
  }

  42% {
    top: 65%;
    left: 48%;
  }

  56% {
    top: 45%;
    left: 38%;
  }

  74% {
    top: 30%;
    left: 24%;
  }

  100% {
    top: 15%;
    left: 8%;
  }
}

@-webkit-keyframes baloon-animation-three {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}

@keyframes baloon-animation-three {
  0% {
    top: 0%;
    right: 5%;
  }

  14% {
    top: 14%;
    right: 15%;
  }

  28% {
    top: 28%;
    right: 25%;
  }

  42% {
    top: 42%;
    right: 35%;
  }

  56% {
    top: 56%;
    right: 25%;
  }

  74% {
    top: 74%;
    right: 15%;
  }

  100% {
    top: 85%;
    right: 5%;
  }
}

@-webkit-keyframes baloon-animation-four {
  0% {
    left: 5%;
    top: 46%;
  }

  14% {
    left: 25%;
    top: 66%;
  }

  28% {
    left: 45%;
    top: 86%;
  }

  42% {
    left: 65%;
    top: 6%;
  }

  56% {
    left: 85%;
    top: 36%;
  }

  74% {
    left: 45%;
    top: 66%;
  }

  100% {
    left: 5%;
    top: 46%;
  }
}

@keyframes baloon-animation-four {
  0% {
    left: 5%;
    top: 46%;
  }

  14% {
    left: 25%;
    top: 66%;
  }

  28% {
    left: 45%;
    top: 86%;
  }

  42% {
    left: 65%;
    top: 6%;
  }

  56% {
    left: 85%;
    top: 36%;
  }

  74% {
    left: 45%;
    top: 66%;
  }

  100% {
    left: 5%;
    top: 46%;
  }
}

@-webkit-keyframes baloon-animation-five {
  0% {
    bottom: 4%;
  }

  14% {
    bottom: 22%;
  }

  28% {
    bottom: 40%;
  }

  42% {
    bottom: 70%;
  }

  56% {
    bottom: 40%;
  }

  74% {
    bottom: 22%;
  }

  100% {
    bottom: 4%;
  }
}

@keyframes baloon-animation-five {
  0% {
    bottom: 4%;
  }

  14% {
    bottom: 22%;
  }

  28% {
    bottom: 40%;
  }

  42% {
    bottom: 70%;
  }

  56% {
    bottom: 40%;
  }

  74% {
    bottom: 22%;
  }

  100% {
    bottom: 4%;
  }
}

.thankYouImageMainPage {
  width: 400px;
}

@media screen and (max-width: 420px) {
  .thankYouImageMainPage {
    width: 320px;
  }
}

/* IFD */
.Main2 {
  height: -webkit-max-content;
  height: max-content;
  background-image: url(/static/media/main-bg.f3367747.png);
  background-size: cover;
  background-position: center center;
  color: white;
  min-height: 100vh;
  position: relative;
}

.main-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  animation: prize-animation 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s infinite alternate-reverse;
}

.main-logo img[alt="main-logo"] {
  width: 240px;
}

.Main2 .MuiTextField-root>label {
  font-weight: bold;
}

.Main2 .MuiTextField-root>.MuiFormLabel-root.Mui-focused {
  font-weight: bold;
}

.btn.btn-warning {
  background-color: #150065;
  color: white;
  font-weight: bolder;
}

.page3_submit_btn {
  background-color: var(--primaryYellow);
}

.bottom-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
}

.verify-img {
  width: 32px;
  height: 32px;
}

.page_1_submit_btn,
.page_3_btn {
  border: none;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .25098039215686274);
  background: #1b325e;
}

:where(.page_3_btn, .page_1_submit_btn) :where(span, img) {
  -webkit-transform: skew(20deg);
  transform: skew(20deg);
  display: inline-block;
}

.custom-arrow-icon {
  margin-right: 15px;
}

.Main2 .css-1fwnb9c .MuiTextField-root {
  width: 300px;

}

.Main2:has(form[data-pagenumber="third"]) .css-1fwnb9c .MuiTextField-root {
  margin: 8px 0;
}

.Main2:has(form[data-pagenumber="third"]) .input-group svg {
  transition: all cubic-bezier(0.175, 0.885, 0.32, 1.275) 200ms;
}

.tnc-apply {
  font-size: 9px;
  font-weight: bold;
  position: absolute;
  bottom: 300px;
  right: -14px;
  color: white;
  z-index: 100;
  rotate: -90deg;
  -webkit-writing-mode: horizontal-tb;
          writing-mode: horizontal-tb;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.form-control {
  text-transform: uppercase;
}

.invoice-group>.form-control:first-child {
  width: 130px;
  margin-right: 10px !important;
}

.invoice-group>.form-control[data-value="invoice-value"] {
  width: 150px;
}

.spinWheelInner {
  position: absolute;
  top: -54px;
  left: -31px;
  width: 453px;
  --new-rotate-value: 0deg;
  -webkit-transform: rotate(var(--new-rotate-value));
          transform: rotate(var(--new-rotate-value));
  transition: -webkit-transform 10s cubic-bezier(0.25, 0.1, 0.15, 1);
  transition: transform 10s cubic-bezier(0.25, 0.1, 0.15, 1);
  transition: transform 10s cubic-bezier(0.25, 0.1, 0.15, 1), -webkit-transform 10s cubic-bezier(0.25, 0.1, 0.15, 1);
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

.spinWheelOuter {
  position: absolute;
  width: 351px;
  left: 20px;
}

.spinArrow {
  position: absolute;
  z-index: 2;
  width: 30px;
  -webkit-transform: translate(-13px, 0px);
          transform: translate(-13px, 0px);
}

.prize-container {
  position: relative;
  z-index: 2;
}

.Main2:has(form[data-pagenumber="fourth"]) #submit-otp-btn {
  position: fixed;
  bottom: 32px;
  -webkit-transform: translate(-57px, 0px) skew(-20deg);
          transform: translate(-57px, 0px) skew(-20deg);
  background-color: var(--primaryYellow);
  color: black;
}

.fourth-page-btn {
  position: fixed;
  bottom: 32px;
  -webkit-transform: translate(-57px, 0px) skew(-20deg);
          transform: translate(-57px, 0px) skew(-20deg);
}

.bottom-cashback-img {
  width: 222px;
}

.congo-img {
  max-width: 398px;
  -webkit-animation: animate-Orange-Light-scale 1s linear infinite both;
          animation: animate-Orange-Light-scale 1s linear infinite both;
}

@-webkit-keyframes congo-animation {
  0% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    height: 70px;
    -webkit-transform: scale(0.5) rotateX(20deg);
            transform: scale(0.5) rotateX(20deg);
  }

  20% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    height: 80px;
    -webkit-transform: scale(0.6) rotateX(50deg);
            transform: scale(0.6) rotateX(50deg);
  }

  40% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    height: 100px;
    -webkit-transform: scale(0.7) rotateX(100deg);
            transform: scale(0.7) rotateX(100deg);
  }

  60% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    height: 120px;
    -webkit-transform: scale(0.8) rotateX(50deg);
            transform: scale(0.8) rotateX(50deg);
  }

  80% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
    height: 130px;
    -webkit-transform: scale(0.9) rotateX(30deg);
            transform: scale(0.9) rotateX(30deg);
  }

  100% {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    height: 140px;
    -webkit-transform: scale(1) rotateX(20deg);
            transform: scale(1) rotateX(20deg);
  }
}

@keyframes congo-animation {
  0% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    height: 70px;
    -webkit-transform: scale(0.5) rotateX(20deg);
            transform: scale(0.5) rotateX(20deg);
  }

  20% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    height: 80px;
    -webkit-transform: scale(0.6) rotateX(50deg);
            transform: scale(0.6) rotateX(50deg);
  }

  40% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    height: 100px;
    -webkit-transform: scale(0.7) rotateX(100deg);
            transform: scale(0.7) rotateX(100deg);
  }

  60% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    height: 120px;
    -webkit-transform: scale(0.8) rotateX(50deg);
            transform: scale(0.8) rotateX(50deg);
  }

  80% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
    height: 130px;
    -webkit-transform: scale(0.9) rotateX(30deg);
            transform: scale(0.9) rotateX(30deg);
  }

  100% {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    height: 140px;
    -webkit-transform: scale(1) rotateX(20deg);
            transform: scale(1) rotateX(20deg);
  }
}

.stage-img {
  width: 427px;
  margin-top: 202px;

  position: absolute;
  left: -38px;
}

[data-custom="customStyle"]+.stage-img {
  width: 339px;
  margin-top: 190px;
}

.cracker-bottom {
  position: absolute;
  bottom: 0;
  width: 100px;
  left: 10px;
  bottom: 14px;
  -webkit-animation: crackersAnimation 5s linear 1s infinite backwards;
          animation: crackersAnimation 5s linear 1s infinite backwards;
}

.cracker-top-left {
  position: absolute;
  top: 143px;
  left: 21px;
  width: 90px;
  -webkit-animation: crackersAnimation 5s linear 2s infinite backwards;
          animation: crackersAnimation 5s linear 2s infinite backwards;
}

.cracker-top-right {
  position: absolute;
  top: 165px;
  right: 21px;
  width: 60px;
  -webkit-animation: crackersAnimation 5s linear 3s infinite backwards;
          animation: crackersAnimation 5s linear 3s infinite backwards;
}

.cracker-top-middle {
  position: absolute;
  top: 8px;
  right: 67px;
  width: 60px;
  -webkit-animation: crackersAnimation 5s linear 4s infinite backwards;
          animation: crackersAnimation 5s linear 4s infinite backwards;
}

@-webkit-keyframes crackersAnimation {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }

  20% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
  }

  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  80% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
  }

  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
}

@keyframes crackersAnimation {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
  }

  20% {
    -webkit-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
  }

  40% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  60% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-filter: brightness(1);
            filter: brightness(1);
  }

  80% {
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
  }

  100% {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
  }
}


.light-left .cls-1 {
  fill: none;
  stroke: #000;
  stroke-miterlimit: 10;
}

.light-left image {
  animation: animate-Orange-Light 1s linear infinite alternate-reverse;
}

.light-right .cls-1 {
  isolation: isolate;
}

:where(.light-right, .light-left) .cls-2 {
  fill: #d26f27;
  -webkit-animation: animate-Red-Light 1.5s linear infinite backwards;
          animation: animate-Red-Light 1.5s linear infinite backwards;
}

:where(.light-right, .light-left) .cls-2,
.light-right .cls-3 {
  mix-blend-mode: screen;
}

.light-right .cls-3 {
  fill: #f3ac1b;
  -webkit-animation: animate-Orange-Light 1s linear infinite backwards;
          animation: animate-Orange-Light 1s linear infinite backwards;
}

.light-right .cls-4 {
  fill: #fff9d2;
}

@-webkit-keyframes animate-Orange-Light {
  0% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  10% {
    -webkit-filter: brightness(1.05);
            filter: brightness(1.05);
  }

  20% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
  }

  30% {
    -webkit-filter: brightness(1.15);
            filter: brightness(1.15);
  }

  40% {
    -webkit-filter: brightness(1.20);
            filter: brightness(1.20);
  }

  50% {
    -webkit-filter: brightness(1.25);
            filter: brightness(1.25);
  }

  60% {
    -webkit-filter: brightness(1.3);
            filter: brightness(1.3);
  }

  70% {
    -webkit-filter: brightness(1.35);
            filter: brightness(1.35);
  }

  80% {
    -webkit-filter: brightness(1.40);
            filter: brightness(1.40);
  }

  90% {
    -webkit-filter: brightness(1.45);
            filter: brightness(1.45);
  }

  100% {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
  }
}

@keyframes animate-Orange-Light {
  0% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
  }

  10% {
    -webkit-filter: brightness(1.05);
            filter: brightness(1.05);
  }

  20% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
  }

  30% {
    -webkit-filter: brightness(1.15);
            filter: brightness(1.15);
  }

  40% {
    -webkit-filter: brightness(1.20);
            filter: brightness(1.20);
  }

  50% {
    -webkit-filter: brightness(1.25);
            filter: brightness(1.25);
  }

  60% {
    -webkit-filter: brightness(1.3);
            filter: brightness(1.3);
  }

  70% {
    -webkit-filter: brightness(1.35);
            filter: brightness(1.35);
  }

  80% {
    -webkit-filter: brightness(1.40);
            filter: brightness(1.40);
  }

  90% {
    -webkit-filter: brightness(1.45);
            filter: brightness(1.45);
  }

  100% {
    -webkit-filter: brightness(1.5);
            filter: brightness(1.5);
  }
}

@-webkit-keyframes animate-Red-Light {
  0% {
    opacity: 0.1;
  }

  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.3;
  }

  30% {
    opacity: 0.4;
  }

  40% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.6;
  }

  60% {
    opacity: 0.7;
  }

  70% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

@keyframes animate-Red-Light {
  0% {
    opacity: 0.1;
  }

  10% {
    opacity: 0.2;
  }

  20% {
    opacity: 0.3;
  }

  30% {
    opacity: 0.4;
  }

  40% {
    opacity: 0.5;
  }

  50% {
    opacity: 0.6;
  }

  60% {
    opacity: 0.7;
  }

  70% {
    opacity: 0.8;
  }

  80% {
    opacity: 0.9;
  }

  100% {
    opacity: 1;
  }
}

.lights-container {
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.light-left {
  -webkit-transform: translate(0px, -31px);
          transform: translate(0px, -31px);
  scale: 1.3;
}

.lamp .cls-1 {
  fill: url(#linear-gradient);
}

.lamp .cls-2 {
  fill: url(#linear-gradient-2);
}

.lamp .cls-3 {
  fill: url(#linear-gradient-3);
}

.lamp .cls-4 {
  fill: url(#linear-gradient-4);
}

.lamp .cls-5 {
  fill: url(#linear-gradient-5);
}

.lamp .cls-6 {
  fill: url(#linear-gradient-6);
}

.lamp .cls-7 {
  fill: url(#linear-gradient-7);
}

.lamp .cls-8 {
  fill: url(#linear-gradient-8);
}

.lamp .cls-9 {
  fill: url(#linear-gradient-9);
}

.lamp .cls-10 {
  fill: url(#linear-gradient-10);
}

.lamp .cls-11 {
  fill: url(#linear-gradient-11);
  animation: animate-Orange-Light 1s linear infinite alternate-reverse;
}

:where(.lamp, .oil-lamp) .diya-main-light {
  animation: animate-Orange-Light 1s linear infinite alternate-reverse;
}

.lamp-light-left .diya-main-light {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.lamp-light-right .diya-main-light {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
}

.oil-lamp-1 .diya-main-light {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}

.oil-lamp-2 .diya-main-light {
  -webkit-animation-delay: 800ms;
          animation-delay: 800ms;
}

.lamp-container .lamp {
  width: 200px;
  height: 40vh;
  position: absolute;
  top: 0;
  z-index: 5;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.lamp-light-left {
  left: -74px;
}

.lamp-light-right {
  right: -74px;
}

.oil-lamp-1 .cls-1 {
  fill: url(#linear-gradient);
}

.oil-lamp-1 .cls-2 {
  fill: url(#linear-gradient-2);
}

.oil-lamp-1 .cls-3 {
  fill: url(#linear-gradient-3);
}

.oil-lamp-1 .cls-4 {
  fill: url(#linear-gradient-4);
}

.oil-lamp-1 .cls-5 {
  fill: url(#linear-gradient-5);
}

.oil-lamp-1 .cls-6 {
  fill: url(#linear-gradient-6);
}

.oil-lamp-1 .cls-7 {
  fill: url(#light-orange-1);
  -webkit-animation: animate-Red-Light 3s cubic-bezier(0.075, 0.82, 0.165, 1) infinite forwards;
          animation: animate-Red-Light 3s cubic-bezier(0.075, 0.82, 0.165, 1) infinite forwards;
}

.lamp-container .oil-lamp {
  width: 120px;
  height: 25vh;
  position: absolute;
  top: 0;
  z-index: 5;
}

.oil-lamp-1 {
  left: 20px;
}

.oil-lamp-2 {
  right: 20px;
}

.oil-lamp-2 .cls-1 {
  fill: url(#linear-gradient);
}

.oil-lamp-2 .cls-2 {
  fill: url(#linear-gradient-2);
}

.oil-lamp-2 .cls-3 {
  fill: url(#linear-gradient-3);
}

.oil-lamp-2 .cls-4 {
  fill: url(#linear-gradient-4);
}

.oil-lamp-2 .cls-5 {
  fill: url(#linear-gradient-5);
}

.oil-lamp-2 .cls-6 {
  fill: url(#linear-gradient-6);
}

.oil-lamp-2 .cls-7 {
  fill: url(#light-orange-2);
  -webkit-animation: animate-Red-Light 3s cubic-bezier(0.075, 0.82, 0.165, 1) infinite forwards;
          animation: animate-Red-Light 3s cubic-bezier(0.075, 0.82, 0.165, 1) infinite forwards;
}

#demo-simple-select-1,
#demo-simple-select-2 {
  margin-top: 10px;
  margin-bottom: -7px;
}


div:has(#demo-simple-select-1 + input[value=""])>#demo-simple-select-1 {
  margin-bottom: -1rem;
}

div:has(#demo-simple-select-2 + input[value=""])>#demo-simple-select-2 {
  margin-bottom: -1rem;
}

.svg-wrapper {
  height: 20px;
  width: 20px;
  margin-right: 1rem;
}

.svg-wrapper svg {
  -webkit-user-select: all;
          user-select: all;
}

.svg-dropdown-wrapper svg {
  margin-bottom: -6px;
}

.Main2:has(form[data-pagenumber="third"])>div {
  width: 85%;
}

@-webkit-keyframes animate-Orange-Light-scale {
  0% {
    -webkit-filter: brightness(0.4);
            filter: brightness(0.4);
    scale: 1;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  10% {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    scale: 1.01;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  20% {
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
    scale: 0.9;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  30% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  40% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  50% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  60% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  70% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  80% {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    scale: 0.9;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  90% {
    -webkit-filter: brightness(1.3);
            filter: brightness(1.3);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  100% {
    -webkit-filter: brightness(1.4);
            filter: brightness(1.4);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }
}

@keyframes animate-Orange-Light-scale {
  0% {
    -webkit-filter: brightness(0.4);
            filter: brightness(0.4);
    scale: 1;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  10% {
    -webkit-filter: brightness(0.5);
            filter: brightness(0.5);
    scale: 1.01;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  20% {
    -webkit-filter: brightness(0.6);
            filter: brightness(0.6);
    scale: 0.9;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  30% {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  40% {
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  50% {
    -webkit-filter: brightness(0.9);
            filter: brightness(0.9);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  60% {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  70% {
    -webkit-filter: brightness(1.1);
            filter: brightness(1.1);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  80% {
    -webkit-filter: brightness(1.2);
            filter: brightness(1.2);
    scale: 0.9;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }

  90% {
    -webkit-filter: brightness(1.3);
            filter: brightness(1.3);
    scale: 1;
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
  }

  100% {
    -webkit-filter: brightness(1.4);
            filter: brightness(1.4);
    scale: 1.01;
    -webkit-transform: rotateX(10deg);
            transform: rotateX(10deg);
  }
}

.Main2:not(:has(form[data-pagenumber="first"])) .main-logo {
  margin-top: 2rem;
}

.Main2:has(form[data-pagenumber="fourth"]) .main-logo,
.Main2:has(form[data-pagenumber="fifth"]) .main-logo {
  margin-top: 0;
}

.text-darkBlue {
  color: #150065;
}

.page_1_submit_btn:is(:disabled) {
  background-color: #150065;
  --bs-btn-disabled-opacity: 0.75;
}

.Main2:has(form[data-pagenumber="fourth"]) .lights-container {
  margin-top: 18rem;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.page_3_btn {
  bottom: -6rem;
}

.offer-reveal-wrapper {
position: relative;
}

.stage-circle {
  width: 280px;
  height: 280px;

  position: absolute;
}

.prize-img {
  z-index: 2;
  width: 408px;
  top: 23px;
  left: -4px;
}

@media (min-width: 442px) and (max-width: 459px) {
  .spinWheelInner {
    left: -14px;
  }
  .spinWheelOuter {
    left: 35px;
  }
}

@media (min-width: 459px) and (max-width: 495px) {
  .spinWheelInner {
    left: -1px;
  }
  .spinWheelOuter {
    left: 47px;
  }
}

@media (min-width: 495px) and (max-width: 519px) {
  .spinWheelInner {
    left: 9px;
  }
  .spinWheelOuter {
    left: 58px;
  }
}

@media (min-width: 519px) and (max-width: 559px) {
  .spinWheelInner {
    left: 30px;
  }
  .spinWheelOuter {
    left: 78px;
  }
}

@media (min-width: 559px) and (max-width: 575px) {
  .spinWheelInner {
    left: 45px;
  }
  .spinWheelOuter {
    left: 93px;
  }
}

@media (min-width: 575px) and (max-width: 768px) {
  .spinWheelInner {
    left: 30px;
  }
  .spinWheelOuter {
    left: 78px;
  }
}
.side-by-side-offer-banner {
    object-fit: contain;
}

.side-by-side-offer-banner img:first-child {
    width: 49%;
}

.side-by-side-offer-banner img:last-child {
    width: 49.5%;
}

.bottom-section-offer img.find-store {
    z-index: 2;
}

.bottom-section-offer img.bottom-two-image {
    z-index: 1;
    margin-top: -2rem;
}

.last-section {
    background: linear-gradient(90deg, #AB2731 0%, #D16F31 53.33%, #CF6B31 63.49%, #C86032 74.43%, #BD4D32 85.74%, #AF3132 97.23%, #AB2731 100.29%);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-block: 1.5rem;
}

.last-section>* {
    color: white;
    font-weight: bolder;
}

.web-icon,
.phone-icon {
    width: 20px;
    margin-right: 10px;
}

.visit-page {
    font-style: italic;
}

.last-section>a:hover {
    color: rgba(255, 255, 255, 0.7);
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
    text-decoration: underline;
}

.offer-page-hover-banner,
.social-wrapper-bottom img {
    cursor: pointer;
}

.offer-page-hover-banner:hover,
.offer-page-hover-banner:focus-visible {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-filter: brightness(1.10);
            filter: brightness(1.10);
    scale: 1.01;
}

.social-wrapper-bottom img:hover,
.social-wrapper-bottom img:focus-visible {
    transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-filter: brightness(1.11);
            filter: brightness(1.11);
    scale: 1.12;
}

.card-display-row iframe {
    height: 300px;
}

.social-wrapper-bottom {
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin-bottom: 2rem;
}

.social-wrapper-bottom>* {
    flex-basis: 100%;
}

.social-wrapper-top>img {
    width: 280px;
}

@media screen and (max-width:800px) {
    .card-display-row iframe {
        height: 180px;
        width: 180px;
    }

    .social-wrapper-top>img {
        width: 200px;
    }

    .social-wrapper-bottom img {
        width: 70px;
    }
}

@media screen and (max-width:500px) {
    .side-by-side-offer-banner img:first-child {
        width: 47%;
    }

    .side-by-side-offer-banner img:last-child {
        width: 48.5%;
    }

    .card-display-row iframe {
        width: 38%;
    }

    .myRewardsTitle {
        margin-top: 1.2rem;
        margin-bottom: 1rem;
    }

    .visit-page,
    .web-icon+span,
    .phone-icon+span {
        font-size: 0.65rem;
    }

    .web-icon,
    .phone-icon {
        width: 11px;
        margin-right: 4px;
    }

    .last-section {
        padding-block: 1rem;
    }

    .visit-page {
        margin-top: 3px;
    }

    .social-wrapper-top>img {
        width: 120px;
    }

    .social-wrapper-bottom img {
        width: 50px;
    }

    .card-display-row iframe {
        height: 125px;
    }
}
.footerContainer {
  background: #1b325e;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  max-width: 1920px;
  width: 100%;
}

.footer_company_detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 4px;
  gap: 4px;
  margin-bottom: 1.5rem;
  height: 0px;
}

.footer_logo {
  cursor: pointer;
  padding-right: 0rem;
}

.about-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.about-heading {
  font-weight: bold;
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

.about {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.policy-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.policy-heading {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

#mail-neg-margin a {
  text-decoration: none !important;
  color: #ffffff !important;
}

.policy {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-container {
  display: flex;
  flex-direction: column;
  color: white;
}

.help-heading {
  color: #ffffff;
  font-weight: 500;
  font-size: 1.3rem;
}

.help {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.payment-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  color: white;
  justify-content: center;
}

.payment-method {
  display: flex;
  flex-direction: column;
}

.payment-cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.payment-cards img {
  width: 200px;
  height: 40px;
}

.contact_details {
  background: #ffcc28;
  padding: 24px 1rem;
}

.contact_heading {
  letter-spacing: -0.01em;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 0 0 18px;
}

.contact_link_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  color: white;
  text-align: start;
}

.contact_link {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  align-items: center;
}

.contact_link img {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.contact_link_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

footer_last .new-con {
  display: flex;
  flex-direction: column;
}

.footer-items {
  font-family: "Quicksand";
  display: flex;
  font-weight: 700;
  flex-direction: row;
  padding: 0px 5.5rem;
  font-size: 13px;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.footer_last {
  display: flex;
  flex-direction: row;
  grid-gap: 4rem;
  gap: 4rem;
}

.footer_last>div>div {
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

.footer_last_heading {
  display: none;
}

.footer_last_heading-desk {
  display: none;
}

.social_links_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  /* width: 150px; */
}

.social_links_wrapper img {
  max-width: 32px;
  max-height: 32px;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.socialMedia-icon {
  margin-right: 0.5rem;
}

.vertical-div {
  border-left: 2px solid white;
  height: 130px;
  margin-right: 40px;
}

.tx-white {
  color: white;
}

.mail-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#mail-neg-margin {
  margin-top: -10px;
  margin-bottom: 8px;
}

.heading-2xl {
  font-size: 1.3rem;
  font-weight: 700;
  color: #8792a7;
  margin-bottom: 5px;
}

.customer-care {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer_terms_wrapper {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer_terms_wrapper-desk a {
  text-decoration: none;
}

.footer_terms_column1,
.footer_terms_column2 {
  display: flex;
  flex-direction: column;
  grid-gap: 1.125rem;
  gap: 1.125rem;
}

.footer_terms {
  letter-spacing: -0.01em;
  color: #ebedf0;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  cursor: pointer;
  margin: 0;
}

.footer_terms_desk {
  font-family: "" Noto Sans Kannada ", Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  color: #ebedf0;
}

.last_contact_details {
  display: none;
}

.footer_copyright {
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  align-items: center;
  justify-content: center;
  margin: 4rem 0px 16px 0px;
}

.footer_copyright p {
  letter-spacing: 0.015em;
  color: #ebedf0;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin: 0;
}

.footerContainer-res a {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.policy-item,
.about-item,
.help-item {
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
}

.footer-items a:hover {
  color: #ffffff;
}

@media screen and (min-width: 768px) {
  .footerContainer {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    /* bottom: 0;
    position: absolute; */
  }

  .footer_details {
    font-size: 16px;
    line-height: 24px;
    max-width: 648px;
  }

  .footer_company_detail {
    padding: 1.5rem 10rem 3.75rem;
  }

  .contact_details {
    display: none;
  }

  .footer_terms_container {
    order: 1;
    flex: 1 1;
  }

  /* .footer_logo img {
    width: 130px;
  } */

  .social_links_container {
    order: 2;
    flex: 1 1;
  }

  .last_contact_details {
    flex: 1 1;
    display: flex;
    flex-direction: column;
  }

  #last_contact_details-sm-q {
    width: 220px;
  }

  .last_contact img {
    -webkit-filter: invert(1);
            filter: invert(1);
  }

  .last_contact p {
    color: #ebedf0;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .footer_last {
    display: flex;
    flex-direction: row;
  }

  .footer_last_heading {
    display: block;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    margin: 0 0 18px 0;
    /* border: 1px solid red; */
  }

  .footer_last_heading_desk {
    letter-spacing: -0.01em;
    color: #8792a7;
    line-height: 28px;
  }

  .footer_last_heading-desk {
    display: block;
    letter-spacing: -0.01em;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 0 0 18px 0;
  }

  #copy-right {
    font-size: 0.7rem;
  }

  .footer_terms {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: 0.004em;
  }
}

@media screen and (min-width: 1024px) {
  .footer_last {
    padding: 0 0rem;
  }
}

@media screen and (min-width: 1400px) {
  .footer_last {
    padding: 0 0rem;
  }
}


.footerContainer-res {
  background: #1b325e;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: auto;
  max-width: 1920px;
  width: 100%;
  flex-wrap: wrap;
  color: white;
  font-family: 'Quicksand';
}

.company-detail-res {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 2rem 1rem;
  justify-content: space-between;
}

.detail-left {
  display: flex;
  flex-direction: column;
  width: 40%;
}

.detail-right {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.footer-details-bottom {
  display: flex;
  width: 100%;
  padding: 0rem 3rem;

}

.details-res {
  display: flex;
  flex-direction: column;
  margin-bottom: -1rem;
  padding: 1rem;
}

.details-res span {
  font-size: 20px;
}

.footer-btm1 {
  width: 40%;
}

.footer-btm2 {
  width: 50%;
}

.footer-last-res {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 1rem;
}

.footer-pay {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.footer-payment {
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.footer-payment-last {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}

.footer-payment-last img {
  width: 90%;
}

#ft-pay {
  margin-right: 5px;
}

.marginStyle {
  margin: 4px 0;
}

.footer-heading-2xl {
  font-size: 15px;
  font-weight: 700;
  color: #8d98ae;
  margin-bottom: 2px;
}

.footer-details-bottom {
  margin-top: -5rem;
}

.footer-logo-res {
  margin: 4rem 0rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo-res img {
  width: 45%;
}

.footer-copy-right {
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.footer-copy-right span {
  font-size: 10px;
}

@media screen and (max-width: 468px) {
  .details-res span {
    font-size: 12px;
  }

  .marginStyle {
    margin: 6% 0;
  }

  .footer-copy-right span {
    font-size: 8px;
  }
}
.cart_Product_Contianer {
  padding: 1rem 0.75rem 0.125rem;
  background: #ffffff;
}

.cart_Product_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0.25rem 1rem;
  border-bottom: 0.063rem solid #ebedf0;
}

.product_Details.cart_product_card {
  max-width: 230px;
}

.cart_Product_Left {
  display: flex;
  flex-direction: column;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  margin: 0 0 1rem;
}
.cart_Product_Left a {
  text-decoration: none;
}
.cart_Product_Name {
  line-height: 1.25rem;
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Color {
  letter-spacing: 0.004em;
  color: #6d747a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0 0 0.625rem;
}

.cart_Product_Price_Section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 0.5rem;
  gap: 0.5rem;
}

.cart_Product_Discount_Price {
  letter-spacing: -0.01em;
  color: #08090a;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
}

.cart_Product_Original_Price {
  color: #6d747a;
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Discount {
  letter-spacing: -0.01em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0;
}

.cart_Product_Offers {
  letter-spacing: 0.004em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Delivery_Info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 0.375rem;
  gap: 0.375rem;
  flex: 1 1;
}

.cart_Product_Delivery_Estimate {
  letter-spacing: 0.004em;
  color: #08090a;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Delivery_Charge {
  color: #08090a;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0;
}

.cart_Product_Availability {
  letter-spacing: 0.004em;
  color: #3f8f00;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0;
}

.cart_Product_Card_Right {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  min-width: 140px;
}

.cart_Product_Image_Container {
  max-width: 122px;
  max-height: 122px;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.cart_Product_Image_Container img {
  width: 100%;
  height: 100%;
}

.cart_Product_Counter_Container {
  max-width: 122px;
  max-height: 40px;
  border: 0.063rem solid #08090a;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.counter_Icon_Container {
  padding: 0.625rem;
  cursor: pointer;
}

.counter_Icon_Container:first-child {
  border-right: 0.063rem solid #08090a;
}

.counter_Icon_Container:last-child {
  border-left: 0.063rem solid #08090a;
}

.counter_Icon_Container img {
  max-width: 20px;
  max-height: 20px;
  width: 100%;
  height: 100%;
}

.cart_Product_Counter {
  margin: 0;
  letter-spacing: -0.01em;
  color: #1b325e;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.75rem;
  padding: 0.5rem 1rem;
}

.combined_Button_Container {
  padding: 0.125rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.combined_Button_Container img {
  max-width: 18px;
  max-height: 18px;
  width: 100%;
  height: 100%;
}

.combined_Button_One,
.combined_Button_Two {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 1.875rem 0.625rem 1.125rem;
  grid-gap: 0.25rem;
  gap: 0.25rem;
  width: 50%;
  cursor: pointer;
}

.combined_Button_One p,
.combined_Button_Two p {
  margin: 0;
  color: #3d5176;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.combined_Button_One {
  border-right: 0.063rem solid #ebedf0;
}

.color_Red {
  color: #c5292a;
}

.cart_Product_Details {
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .cart_Product_Name {
    font-size: 1rem;
    line-height: 1.5rem;
    max-width: 30ch;
    margin: 0 0 0.125rem;
  }

  .cart_Product_Color {
    letter-spacing: 0.004em;
    margin: 0;
  }

  .cart_Product_Price_Section {
    margin: 1rem 0 0;
    grid-gap: 0.875rem;
    gap: 0.875rem;
  }

  .cart_Product_Discount_Price,
  .cart_Product_Original_Price {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cart_Product_Offers {
    margin: 0 0 1rem;
  }

  .product_Details.cart_product_card {
    /* justify-content: flex-start; */
    grid-gap: 1.5rem;
    gap: 1.5rem;
    width: 75%;
    max-width: 100%;
  }

  .combined_Button_Container img {
    max-width: 20px;
    max-height: 20px;
  }

  .combined_Button_One p,
  .combined_Button_Two p {
    letter-spacing: -0.015em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    letter-spacing: -0.015em;
  }

  .cart_Product_Left {
    flex: 1 1;
    margin: 0;
  }
}

.cart-banner {
  display: flex;
  /* border: 1px solid yellowgreen; */
  justify-content: space-evenly;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #0066c0;
  margin-top: 10rem;
  margin-bottom: 3rem;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  flex-wrap: wrap;
}

.cart-banner-item {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  color: #0066c0!important;
  text-decoration: none!important;
}

@media screen and (max-width: 520px) {
  .cart-banner {
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
  .cart-banner-item {
    width: 40%;
  }

  .cart-banner-item span {
    font-size: 10px;
    line-height: 14px;
  }
  .cart-banner-item span:last-child {
    font-size: 11px;
    line-height: 14px;
  }

}

@media screen and (min-width: 1024px) {
  .cart_Product_Wrapper {
    padding: 1rem 1rem 0.25rem;
  }

  .cart_Product_Wrapper {
    padding: 0 0.5rem 1.625rem;
  }

  .cart_Product_Name {
    font-size: 1.25rem;
    line-height: 1.75rem;
    max-width: 100%;
    margin: 0 0 0.25rem;
  }

  .cart_Product_Color {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cart_Product_Discount_Price,
  .cart_Product_Original_Price,
  .cart_Product_Discount {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .cart_Product_Delivery_Estimate {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #08090a;
  }

  .cart_Product_Offers,
  .cart_Product_Availability {
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .combined_Button_Container {
    grid-gap: 0.375rem;
    gap: 0.375rem;
    padding: 0.25rem 0 0;
  }

  .combined_Button_One,
  .combined_Button_Two {
    padding: 0.875rem 1.875rem 0.625rem 1.125rem;
  }

  .combined_Button_Container img {
    max-width: 24px;
    max-height: 24px;
  }
}

#sub-banner1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: unset;
}

.crt-banenr-center {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid red;
}

.icn-sz {
  width: 35px;
  height: 35px;
  /* padding: 1rem; */
  margin-right: 10px;
}
.itm-child {
  display: flex;
  flex-direction: column;
}
@font-face {
    font-family: "Quicksand", sans-serif !important;
    src: url(/static/media/NeoSansProRegular.ad6d69f9.ttf);
    unicode-range: U+0964-0965, U+0C82-0CF2, U+200C-200D, U+20B9, U+25CC;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --headerSearchHeight: 35px;
  --paymentPageLeft: 6rem;
  --pageMarginTop: 6.3rem;
  --primaryYellow: #FFCC0A;
  --primaryDarkBlue: #1b325e;
}

/* Mobile */
@media screen and (max-width: 1000px) {
  :root {
    --pageMarginTop: 5.3rem;
  }
}

/* @media (min-width: 501px ) and (max-width: 1000px) {
  :root {
    --pageMarginTop: 5.3rem;
  }
} */

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.relative {
  position: relative;
}

.signUpParentClass{
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
  background-size: cover;
  border-radius: 10px;
  margin-top: 97px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 48px;
}

.signup-wrapper {
  -webkit-backdrop-filter: blur(50px);
          backdrop-filter: blur(50px);
  background: linear-gradient(180deg, #F3F6FF 0%, rgba(243, 246, 255, 0) 100%);
  border-radius: 10px;
  padding: 48px 1.3rem 1.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 24px;
  gap: 24px;
  width: 100%;
  height: 100%;
  position: relative;
}

.page-heading {
  /* font-size: 20px;
  line-height: 1.75rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #1b325e; */
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 30px;
  color: #000000;
}

.page-desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #3d5176;
}

.input-field {
  padding: 16px;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: white;
  width: 100%;
}

.input-field::-webkit-input-placeholder {
  color: #8d9091;
}

.input-field::placeholder {
  color: #8d9091;
}

.submit-button {
  border-radius: 0 0 0 1rem;
  background: #1b325e;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 328px;
  width: 100%;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
}

.submit-button p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  display: inline-block;
  -webkit-transform: skew(20deg);
          transform: skew(20deg);
  margin: 0;
  color: #ffffff;
}

.submit_Button_2 {
  width: 100%;
}

.submit_Button_2 .submit-button {
  background: #dde0e7;
  box-shadow: none;
}

.submit_Button_2 .submit-button p {
  color: #1b325e;
}

.submit_Button_3 {
  width: 100%;
  text-decoration: none;
}

.submit_Button_3 .submit-button {
  background: #f8f9fa;
  box-shadow: none;
}

.submit_Button_3 .submit-button p {
  color: #1b325e;
}

.extra-btn {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: -0.5px;
  color: #1b325e;
  cursor: pointer;
  text-decoration: none;
}

.extra-btn:hover {
  color: #1b325e;
}

button:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.signup-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  grid-gap: 2px;
  gap: 2px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
}
.inputfield-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 16px;
  gap: 16px;
}

.inputField {
  position: relative;
}

.button-Container {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
  gap: 1rem;
  text-decoration: none;
}

.tc-Footer {
  width: 100%;
  /* position: fixed;
  bottom: 0; */
  /* margin-bottom: 12px; */
  margin: 96px 0 0;
}

.footer-Text {
  text-align: center;
  font-size: 11px;
  line-height: 16px;
  font-weight: 500;
  color: #828ea6;
}

.footer-Text span {
  font-weight: 600;
  color: #1b325e;
  cursor: pointer;
}

/* input[type='date']::before{
  color: lightgray;
  content: attr(placeholder) !important;
}

input[type='date']:focus::before{
  content: '' !important;
} */

.resend-btn {
  color: rgb(0, 102, 192);
  letter-spacing: -0.5px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  cursor: pointer;
  text-align: right;
  background: transparent;
  border: none;
  outline: none;
  opacity: 1 !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-self: flex-end;
}

.btn-disable {
  pointer-events: none !important;
}

.homepage_wrapper {
  margin-top: 6.3rem;
  margin-top: var(--pageMarginTop);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  max-width: 1920px;
}

.page_Margin_Top {
  margin: 14.125rem 0 0;
}

.page_Wrapper {
  margin-top: 6.3rem!important;
  margin-top: var(--pageMarginTop)!important;
  height: 100%;
  width: 100%;
  background-color: white;
  position: relative;
} 

.page_Wrapper:not(:has(.new_Filter_Show)) .new_Filter_Container {
  display: none;
}

.empty_order_sec {
  width: 100%;
  height: 100%;
  background: #e5e5e5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-gap: 24px;
  gap: 24px;
  padding: 80px 0;
  margin: 1rem 0 1rem;
}

.not_found_wrapper{
  margin-top: 6.3rem;
  margin-top: var(--pageMarginTop);
}

.empty_order_text {
  margin: 0;
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

input,
textarea {
  border-radius: 0.25rem;
  background: #ffffff;
  border: 0.063rem solid #efefef;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  width: 100%;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #8d9091;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

input::placeholder,
textarea::placeholder {
  color: #8d9091;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.mobile_None {
  display: none;
}

.custom-date-picker {
  display: block !important;
}

.custom-date-picker input {
  /* padding: 16px;  */
  width: 100%;
  text-align: left;
}

.custom-date-picker .react-date-picker__wrapper {
  border: none;
  margin-top: -11px;
}

.custom-date-picker .react-date-picker__calendar {
  z-index: 1000;
}

.floating_Footer {
  height: 64px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0.5rem 1rem;
}

.floating_Footer_Wrapper {
  display: flex;
  grid-gap: 0.75rem;
  gap: 0.75rem;
  align-items: center;
}

.floating_Footer_Left,
.floating_Footer_Right {
  width: 45%;
}

.floating_Footer_Center {
  width: 100%;
}
.scroll_Buffer {
  min-width: 1px;
  min-height: 50px;
  margin: 0 0px 0 0;
}

.tab_Display_Block {
  display: none !important;
}

.text4_xl {
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

#head-bar-sugges {
  width: 70%;
  margin-left: 1rem;
  height: 1px;
  border: 2px solid #eeeeee;
  background: #eeeeee;
}

@media screen and (min-width: 768px) {
  .submit-button {
    max-width: 552px;
  }

  .page_Wrapper {
    margin: 0;
    padding: 0 0.8rem 0.75rem;
  }

  .section_Wrapper {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.02);
    border-radius: 0.25rem;
    background: #ffffff;
    border: 1px solid #dde0e7;
  }

  .tab_None {
    display: none !important;
  }
  .tab_Display_Block {
    display: block !important;
  }

  .tab_Display_Flex {
    display: flex !important;
  }

  .page_Margin_Top {
    margin: 8.75rem 0 0;
  }

  .floating_Footer {
    display: none;
  }

  .text4_xl {
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
  }  
  
  .signUpParentClass{
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    background-size: cover;
    border-radius: 10px;
    margin-top: 148px;
  }
}

@media screen and (min-width: 850px) {
  .page_Wrapper {
    padding: 0 1.5rem 0.75rem;
  }
}

@media screen and (min-width: 1024px) {
  .signup-wrapper {
    max-width: 360px;
    border-radius: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-backdrop-filter: blur(50px);
            backdrop-filter: blur(50px);
    background: linear-gradient(180deg, #F3F6FF 0%, rgba(243, 246, 255, 0) 100%);
    box-shadow: 0px 4px 20px rgba(27, 50, 94, 0.1);
  }

  .page_Wrapper {
    padding: 0 2.5rem 1.25rem;
  }
}

@media screen and (min-width: 1400px) {
  .page_Wrapper {
    padding: 0 3.75rem 1.5rem;
  }
}

/* ---- Radio Btn ---- */
.radiobtn-label {
  display: flex;
  align-items: center;
  height: 18px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  padding-left: 1.875rem;
}
.radiobtn-label input[type="radio"] {
  position: absolute;
  cursor: pointer;
  opacity: 0;
}
.radiobtn-label .radio-custom {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #98a3b7;
  border-radius: 50%;
  box-sizing: border-box;
}
.radiobtn-label .radio-custom:after {
  content: "";
  position: absolute;
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #1b325e;
}
.radiobtn-label input[type="radio"]:checked + .radio-custom {
  border-color: #1b325e;
}
.radiobtn-label input[type="radio"]:checked + .radio-custom:after {
  display: block;
}

.radiobtn-label input[type="radio"]:checked ~ .radiobtn-label {
  z-index: 100;
}

/* ---- Check Box ---- */
.checkbox-label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  padding-left: 2rem;
  color: #000000;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.checkbox-label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}
.custom-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 24px;
  height: 24px;
  border: 1px solid #1b325e;
  border-radius: 4px;
  background-color: #ffff;
}
.custom-checkmark:after {
  content: "";
  position: absolute;
  /* display: none; */
  opacity: 0;
  top: 6px;
  left: 9px;
  height: 9px;
  width: 4.5px;
  border: 2px solid #1b325e;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  transition: 0.3s ease;
}
.checkbox-label input:checked ~ .custom-checkmark {
  background-color: #ffffff;
}
.checkbox-label input:checked ~ .custom-checkmark::after {
  /* display: block; */
  opacity: 1;
}
.react-parallax-bgimage {
  height: 100% !important;
  /* width: 100% !important; */
  object-fit: contain !important;
}
input.numeric-text::-webkit-outer-spin-button,
input.numeric-text::-webkit-inner-spin-button {
    display:none;
}

.custom-fileSelect {
  display: inline-block;
  background-color: #1b325e;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
  width: 30%;
  text-align: center;
} 


video {
  width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.required_field:invalid {
  outline: 1px solid red;
}
.required_field:valid {
  outline: 2px solid green;
}

.accordion-header button {
  text-transform: capitalize;
}

.yellow-theme-btn {
  border-radius: 0 0 0 1rem;
  background: #ffcc0a;
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  max-width: 200px;
  width: 70%;
  height: 70%;
  outline: none;
  cursor: pointer;
  border: none;
  -webkit-transform: skew(-20deg);
          transform: skew(-20deg);
  box-shadow: 0px 4px 4px 0px #00000040;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
}

.text-xs {
  font-size: 12px;
}

.text-link {
  color: #3581C1;
  font-weight: 500;
}

.SignUpInputLable{
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 19px;
  color: #000000;
}

.input-field {
  padding: 16px;
  height: 15px;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: white;
  width: 100%;
}

.input-field::-webkit-input-placeholder {
  color: #8d9091;
}

.input-field::placeholder {
  color: #8d9091;
}

.TCStyle{
  flex-wrap: wrap;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
}

.socialParent{
  display: flex;
  flex-direction: row;
  grid-gap: 1px;
  gap: 1px;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  background-color: #34509E;
  border-radius: 11px
}

.socialImage{
  width: 22%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media screen and (max-width : 600px) {
  .page_Wrapper{
    margin-top: 0;
  }
}

.loader-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-block: 1rem;
}

.admin_required_field:where(:focus, :focus-visible):invalid {
  outline: 1px solid red;
}
.admin_required_field:valid {
  outline: 1px solid green;
}

.normal-link {
  text-decoration: none;
}

.normal-link:hover {
  color: inherit;
}
/*kannada-font*/
@font-face {
    font-family: "Noto Sans Kannada";
    src: url(/static/media/NotoSerifKannada-VariableFont_wght.847d8c08.ttf);
    unicode-range: U+0964-0965, U+0C82-0CF2, U+200C-200D, U+20B9, U+25CC;
}


body {
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: "Quicksand", sans-serif !important;
}
