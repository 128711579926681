.bank_Offer_Container{
  padding: 1rem;
  background: #FFFFFF;
  margin: 0 0 1rem;
}

.bank_Offer_Heading{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0 0 0.5rem;
}

.bank_Offer_Desc{
  color: #08090A;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 1.5rem;
}

.offer_Question{
  letter-spacing: -0.01em;
  color: #08090A;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  margin: 0 0 0.75rem;
}

.offer_Conditions_List{
  margin: 0;
  padding: 0 0 0 1.2rem;
}

.offer_Conditions_List li{
  color: #08090A;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0 0 0.5rem;
}

