.headerbarContainer {
  background: #1b325e;
  height: fit-content;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 2000;
  display: flex;
}

.headerbarWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.875rem 1rem;
  background: #1b325e;
}

.headerbarLeft,
.headerbarRight {
  display: flex;
  align-items: center;
  gap: 1rem;
} 

.headerbarLeft_Inner {
  display: flex;
  align-items: center;
}

#headerbarCenter {
  display: none;
}

.header-pincode {
  font-size: 0.7rem;
  line-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 60px;
}

#header-hello {
  line-height: 95%;
}

.headerLocation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px;
  padding-bottom: 0px;
}

.location-logo {
  font-size: 2rem;
  width: 45px;
}

.hamburger {
  max-height: 40px;
  max-width: 40px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hamburger img {
  width: 100%;
  height: 100%;
}

.navLogo {
  cursor: pointer;
}

.left_location {
  display: none;
}

.right_login {
  display: none;
}
.cartIcon {
  color: white;
  margin-left: 20px;
}

#cartItems {
  position: relative;
  bottom: 15px;
  right: 21px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  background-color: #ffa500;
  justify-content: center;
  align-items: center;
}
.storeIcon,
.cartIcon,
.user_profile {
  cursor: pointer;
  text-decoration: none;
  text-decoration: none;
}

.storeIcon img,
.cartIcon img,
.user_profile img {
  height: 100%;
  width: 100%;
  max-width: 24px;
  max-height: 24px;
}

.storeIcon p,
.cartIcon p,
.user_profile p {
  display: none;
  margin: 0;
  text-decoration: none;
}

.storeLogo {
  display: flex;
  flex-direction: column-reverse;
}

.searchbarWrapper {
  width: 100%;
  padding: 0px 1rem 0.875rem 1rem;
  background: #1b325e;
}

.searchbar_Container {
  position: relative;
  width: 100%;
  height: inherit;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}
.search {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-top-left-radius: 0.7rem;
  border-bottom-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}

.search-btn {
  width: 40%;
  height: var(--headerSearchHeight);
  font-size: 1.3rem;
  cursor: pointer;
  background-color: orange;
  border: none;
  border-top-right-radius: 0.7rem;
  border-bottom-right-radius: 0.7rem;
}
.searchbar {
  border: none;
  outline: none;
  height: var(--headerSearchHeight);
}

.searchbar::placeholder {
  color: #1b325e;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 24px;
}

.seachbar_Icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.search_Results {
  position: absolute;
  top: 104px;
  left: 0;
  background: #ffffff;
  border-radius: 0.25rem;
  margin: 0 0.5rem;
  height: fit-content;
  max-height: 500px;
  width: 100%;
  max-width: 500px;
  overflow: hidden;
  overflow-y: auto;
  z-index: 2001;
}

.search_Results::-webkit-scrollbar {
  display: none;
}

.search_Result_Item {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0 0 0 0.625rem;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 1px solid #969696;
}

.search_Result_Item p {
  margin: 0;
  color: var(--primaryDarkBlue);
  font-size: 15px; 
}

.search_Result_Item:hover {
  background: #e5e5e5;
}

.locationbarWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.25rem;
  background: #546586;
  padding: 0.875rem 1rem;
}

.locationbarWrapper p {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  letter-spacing: 0.004em;
  color: #ffffff;
  margin: 0;
}

.login_tab_only {
  display: none;
}

.categories_Container {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  cursor: grabbing;
  scroll-snap-type: x mandatory;
  padding: 0 0 0 1rem;
  cursor: auto;
  background-color: #f2f2f2;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: -1;
  padding-top: 15px;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.categories_Container::-webkit-scrollbar {
  display: block;
  background: rgb(216, 214, 214);
  height: 2px;
}

.categories_Wrapper {
  display: flex;
  gap: 32px;
  white-space: nowrap;
  margin: auto;
  max-width: 90%;
}

.category {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  cursor: pointer;
  min-width: fit-content;
  justify-content: center;
}

.category img {
  max-width: 60px;
  max-height: 60px;
  border: 2px solid rgb(255, 255, 255);
  border-radius: 50%;
}

.category p {
  margin: 0;
  letter-spacing: 0.015em;
  color: #707070;
  font-weight: 500;
  line-height: 1rem;
  font-size: 0.325rem;
  /* width: 100px; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  text-align: center;
}

.header2_tab {
  display: none;
}

.headerbar3_container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 56px;
  z-index: 200;
  background: #1b325e;
}

.headerbar3_Wrapper {
  padding: 0.875rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  gap: 0.75rem;
}

.headerbar3_Wrapper .back_Btn {
  max-width: 28px;
  max-height: 28px;
  width: 100%;
  height: 100%;
}

.back_Btn:focus,
.back_Btn:hover {
  scale: 1.3;
  transition: all ease-in 200ms;
  cursor: pointer;
}

.headerbar3_Wrapper .nav_Logo {
  max-width: 26px;
  max-height: 30px;
  width: 100%;
  height: 100%;
}

.headerbar3_Wrapper p {
  letter-spacing: -0.01em;
  color: #ffffff;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;
  justify-self: center;
  margin: 0;
}

.radio_input:checked {
  border-color: #ffcc0a;
}
.lang_list_item {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  color: #757878;
}
.signIn_list_item {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #757878;
}

.header_sign_in_btn {
  background-color: #ffcc0a !important;
  outline: none;
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #000000 !important;
  padding: 7px 10px;
  margin: 0 auto;
  width: 120px;
  transform: skew(-20deg);
  border-radius: 0 0 0 0.7rem;
}

.header_sign_in_btn p {
  transform: skew(20deg);
  margin-bottom: 0;
}

@media screen and (min-width: 768px) {
  .category img {
    max-width: 80px;
    max-height: 80px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
  }
  .category p {
    margin: 0;
    letter-spacing: 0.015em;
    color: #707070;
    font-weight: 500;
    line-height: 1rem;
    font-size: 0.625rem;
    /* width: 100px; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    text-align: center;
  }

  .header2_tab {
    display: flex;
  }

  .headerbarWrapper {
    padding: 0.75rem 1.5rem;
    justify-content: flex-start;
    gap: 0.5rem;
    margin: auto;
    max-width: 1920px;
    width: 100%;
  }

  .headerbarCenter {
    flex: 1;
  }
  .headerbarCenter,
  .headerbarRight {
    justify-content: center;
  }

  .headerbarCenter {
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    position: relative;
    margin-left: 10px;
  }

  .left_location {
    display: flex;
    gap: 0.25rem;
    cursor: pointer;
  }

  .left_location p {
    letter-spacing: -0.01em;
    color: #dde0e7;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    margin: 0;
  }

  .map-location {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .searchbar {
    padding: 0.625rem 0.75rem 0.625rem 0.5rem;
  }

  .search-icon {
    margin-left: 2%;
  }

  .searchbar::placeholder {
    line-height: 1.25rem;
  }

  .search_Results {
    top: 50px;
    margin: 0;
  }
  .personIcon {
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 2rem;
  }
  .right_login_comp {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0%;
  }
  .right_login {
    line-height: 0%;
    display: flex;
    flex-direction: column;
    color: #ffffff;
    margin: 0;
    font-size: 0.4rem;
  }

  .right_login a {
    cursor: pointer;
    letter-spacing: -0.01em;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .right_login a:hover {
    color: #ffffff;
  }

  .headerbarRight {
    gap: 1.5rem;
  }

  .map-location {
    display: flex;
    margin-right: 40px;
  }
  #lang-select {
    width: 100px;
    font-size: 1rem;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
  }
  .IndianFlagIcon {
    width: 30px;
  }
  .lang-item {
    color: black;
    color: white;
  }
  .storeIcon,
  .cartIcon,
  .user_profile {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .storeIcon p,
  .cartIcon p,
  .user_profile p {
    display: block;
    color: #dde0e7;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
    white-space: nowrap;
  }

  .searchbarWrapper,
  .locationbarWrapper {
    display: none;
  }
  .login_tab_only {
    display: block;
  }

  .categories_Container {
    padding: 0.5rem 1.5rem;
  }

  .categories_Wrapper {
    gap: 1.125rem;
  }

  .category p {
    font-family: "" Noto Sans Kannada ", Montserrat";
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: #707070;
  }

  .headerbar3_container {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .headerbarLeft_Inner {
    gap: 1.5rem;
  }

  .headerbarWrapper {
    gap: 1.25rem;
  }
  .storeIcon {
    display: flex;
    gap: 0.25rem;
    align-items: center;
  }

  .storeIcon p {
    display: block;
    color: #dde0e7;
    letter-spacing: -0.01em;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.5rem;
  }

  .cartIcon {
    order: 1;
  }

  .right_login,
  .user_profile {
    display: flex;
    order: 3;
  }

  .storeIcon,
  .cartIcon,
  .right_login,
  .user_profile {
    gap: 0.375rem;
  }

  .login_tab_only {
    display: none;
  }

  .categories_Container {
    padding: 0rem 3.75rem;
  }

  .categories_Wrapper {
    gap: 32px;
  }

  .headerbarRight {
    gap: 2.5rem;
  }
}

@media screen and (min-width: 1200px) {
  headerbarLeft.headerbarWrapper {
    padding: 0.75rem 1.875rem;
  }

  .headerbarLeft {
    justify-content: space-around;
  }
}

@media screen and (min-width: 1400px) {
  .headerbarWrapper {
    padding: 0.75rem 3.75rem;
  }

  .headerbarLeft {
    gap: 1.5rem;
  }

  .headerbarRight {
    gap: 2.5rem;
    position: relative;
    left: 10px;
  }
}

.rec.rec-arrow {
  border-radius: 0;
}

.rec.rec-arrow:hover {
  border-radius: 50%;
  background-color: #1b325e;
}
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px lightgrey;
}

button.rec-dot {
  display: none;
}
.rec-pagination {
  display: none;
}

.carousel-itm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  cursor: pointer;
}

.header-res-top {
  display: flex;
  width: 100%;
  padding: 0px 1rem;
  flex-direction: row;
  font-family: "Quicksand";
  align-items: center;
}

.headerbarLeft_Inner_res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  height: 70px;
}
.right_login_comp_res {
  width: 100%;
}
.right_login-res {
  color: white;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.personIcon {
  color: white;
}
.right_login-res a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.right_login_comp_res {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.cartIcon_res {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#cartItems_res {
  background-color: #ffa500;
  position: relative;
  bottom: 2.5rem;
  left: 1.4rem;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  font-weight: 600;
}

.header-mob-signIn-res {
  margin-left: 10%;
  margin-right: 2%;
}

:where(.header-mob-signIn-res, .cartIcon_res):where(:focus-visible, :hover) {
  cursor: pointer;
}
.header-res-center {
  padding: 0px 1rem;
  font-family: "Quicksand";
}

.headerbarLeft_res {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}
.left_location_res,
.map-location_res {
  display: flex;
  color: white;
  align-items: center;
}
.left_location_res p,
.map-location_res p {
  margin-block:  0;
}
.headerLocation_res {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 16px;
}
.location-logo_res {
  display: flex;
  justify-content: center;
  justify-content: center;
}
.IndianFlagIcon_res {
  width: 35px;
  border-radius: 5px;
}
#lang-select_res {
  width: 10px;
  background-color: transparent;
  border: none;
  color: grey;
  outline: none;
  cursor: pointer;
}
.map-location_res {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  z-index: 100000;
}
.map-select {
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
}
.header-res-bottom {
  font-family: "Quicksand";
  font-weight: 700;
  padding: 0px 4%;
}

#person-icon_res {
  font-size: 3rem;
}
.text2xl {
  font-weight: 700;
  font-size: 13px;
}
.text1xl {
  font-size: 13px;
}

.storeIcon,
.cartIcon,
.user_profile {
  cursor: pointer;
  text-decoration: none;
  text-decoration: none;
}

.storeIcon img,
.cartIcon img,
.user_profile img {
  height: 100%;
  width: 100%;
  max-width: 24px;
  max-height: 24px;
}

.user_profile img {
  border-radius: 50%;
  height: 25px;
  width: 25px;
}

.smallLogo img {
  width: 100px;
}
@media screen and (max-width: 500px) {
  .user_profile img {
    max-width: 31px;
    max-height: 33px;
  }
  .cartIcon_res {
    margin-top: 23px;
    padding-bottom: 3px;
  }
  #person-icon_res {
    font-size: 2.4rem;
  }
  .carousel-itm img {
    width: 52px;
  }
  .header-mob-signIn-res {
    padding-top: 7px;
  }
  .search_Results{
    top: 107px;
    width: 92%;
    left: 5px;
    z-index: 2001;
  }
  .search_Result_Item {
    height: 38px;
  }
}
.category p {
  font-size: 8.7px;
}

.selected_search_item {
  background-color: #e5e5e5;
}

.my-carousel .slider-prev,
.my-carousel .slider-next {
  background-color: white;
  color: black;
  width: 30px;
  height: 30px;
}

.my-carousel .slider-prev:hover,
.my-carousel .slider-next:hover {
  background-color: red;
}

.locationModalContainer {
  position: relative;
}

.locationModalHeader {
  background-color: #f0f2f2;
  padding: 15px 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: black;
}

.locationModalpara {
  padding: 0 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 4px;
  color: #757878;
}

.locationModalinstruction,
.locationModalpincode {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
  padding: 10px 25px 0px;
  color: #0066c0;
}

.locationModalinstruction {
  display: inline-flex;
  gap: 0.3rem;
  cursor: pointer;
}

.locationModalinstruction:where(:hover, :focus-visible) {
  scale: 1.01;
  transition: all 0.3s ease-in-out;
}

.locationModalinstruction  p {
  margin-bottom: 0;
}

.locationModalInputContainer {
  padding: 0 25px 0 25px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.locationModalinput {
  width: 70%;
  padding: 2px 5px;
}
.locationModalbutton {
  width: 20%;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #0b0b0b;
  outline: none;
  background: #FFFFFF;
  border: 1px solid #D6CFCF;
  padding:4px 16px;
  border-radius: 5px;
}

.locationmodalCloseIcon{
  position: absolute;
  top: 14px;
  right: 8px;
  cursor: pointer;
  z-index: 10000000;
}
@media screen and (max-width:500px) {
  
  .locationModalHeader {
    padding: 15px 20px;
  }
  
  .locationModalpara {
    padding: 0 0px 0px 20px;
  }
  
  .locationModalinstruction,
  .locationModalpincode {
    padding: 10px 20px 0px;
  }
  
  .locationModalInputContainer {
    padding: 0 20px 0 20px;
  }

  .headerbarRight_res{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
  }
}

.language_wrapper {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 60px;
  display: flex;
  z-index: 100000;
}  

.ellipsis {
  text-overflow: ellipsis; /* enables ellipsis */
  white-space: nowrap; /* keeps the text in a single line */
  overflow: hidden; /* keeps the element from overflowing its parent */
}

.dropDownBtn{
  position: relative;
  z-index: 9990;
}

.dropDownText{
  cursor: pointer;
}

@media screen and (min-width: 500px) and (max-width: 992px) {
    .left_location_res .location-logo_res {
      width: 18px!important;
      height: 18px;
    }
    .headerLocation_res, .lang-item {
      font-size: 0.75rem;
    }
    .headerbarRight_res {
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      align-items: center;
      gap: 10px;
    } 
    .categories_Container {
      top: 156px!important;
    }
    .mobilescreen img {
      max-height: 140px!important;
    }
    .products {
      width: 100%!important;
    }
    .storeIcon {
      gap: 0;
    }
    .storeIcon p {
      margin-top: -4px;
    }
}