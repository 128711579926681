.backIcon {
  position: relative;
  left: 12px;
  bottom: 15px;
}

.backSpan {
  cursor: pointer;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 16px;
  bottom: 12px;
}

.back_logo {
  width: 10px;
}

.checkoutSpan {
  font-family: Quicksand;
  font-size: 28px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: 12rem;
  bottom: 12px;
}

.email-edit-btn {
  display: grid;
  place-content: center;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-decoration: underline;
  color: var(--primaryDarkBlue);
}

.main_wrapper {
  display: flex;
  width: 95vw;
  justify-content: space-between;
}

.main_container {
  width: 44vw;
  display: flex;
  flex-direction: column;
}

.card_logo_payment {
  width: 210px;
}

.main_heading {
  display: flex;
  justify-content: space-evenly;

  background: #f1f1f1;
  height: 55px;

  border-radius: 0px;
  align-items: center;
}

.main_heading h3 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  left: -4.7rem;
}

.second_container_main_heading {
  height: 60px;
  width: 52vw;
  background: #f1f1f1;
  display: flex;
  position: relative;
  height: 55px;
  align-items: center;
}

.outline {
  outline: 2px solid gold !important;
}

.order_break {
  width: 90%;
  margin-left: auto;
  /* position: relative; */
  /* left: 15%; */
}

.subtotal {
  display: flex;
  justify-content: space-evenly;
  position: relative;
}

.subtotall_keys p {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

.subtotall_keys span {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.subtotall_value span {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
}

.buy-now {
  background: #ffcc0a !important;
  font-family: Quicksand;
  font-size: 25px;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 0em;
  text-align: left;
  position: relative;
  width: 210px !important;
  margin-bottom: 15px !important;
}

.secondHeading {
  position: relative;
  left: var(--paymentPageLeft);
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: left;
}

.cardDiv {
  display: flex;
  position: relative;
  left: var(--paymentPageLeft);
  margin-top: 15px;
  justify-content: space-between;
  width: 400px;
  height: 150px;
  gap: 20px;
  overflow: hidden;
}

.marginLeft {
  margin-left: var(--paymentPageLeft);
}

.cardDiv h2 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #707070;
}

.cardDiv strong {
  font-family: Quicksand;
  font-size: 26px;
  font-weight: 700;
  line-height: 50px;
  letter-spacing: 0em;
  text-align: left;
  color: #241a6b;
  position: relative;
  bottom: 0.9em;
}

.cardDiv p {
  font-family: "Quicksand";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #3581c1;
  white-space: pre-line;
}

.card__square {
  height: 138px;
  min-width: 138px;
  left: 1165.525390625px;
  top: 569.5244140625px;
  border-radius: 10px;
  margin-right: 10px;

  border: 1px solid lightgray;
}

.shippment_details {
  margin-left: 5em;
  background: #f8f8f8;
  margin-top: 15px;

  margin-bottom: 15px;
  justify-content: space-between;
  height: 236px;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.details {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 0 10px;
  border-bottom: 1px solid rgb(201, 199, 199);
  width: 90%;
  align-items: center;
}

.details h5 {
  padding: 0;

  font-weight: 100;
  transform: scale(0.8);
}

.second_heading {
  display: flex;
  justify-content: space-around;
  width: 50vw;
  background: #f1f1f1;
  height: 55px;

  border-radius: 0px;
  align-items: center;
}

.second_heading h3 {
  font-size: 18px;
  margin-left: 10.5em;
  font-family: Quicksand;

  font-weight: 700;

  text-align: left;
}

.payment_div {
  margin-left: 8em;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.popover-container {
  position: relative;
  display: inline-block;
  width: 800px;
}

.popover-container.pushed {
  margin-bottom: 10px;
}

.popover {
  transition: all 1s ease;
  display: none;
}

.submit-button {
  position: relative;
  height: 54.54199981689453px;
  width: 272.7939147949219px;
  font-size: 20px;
  border-radius: 0px;
}

.submit-button {
  position: relative;
  height: 54.54199981689453px;
  width: 272.7939147949219px;
  font-size: 20px;
  border-radius: 0px;
}

.showPopover {
  display: block;

  background: #f2f2f2;

  height: 611px;
  position: relative;
  left: 16%;
  border-radius: 10px;
  margin-top: 2.1px;
}

.emi_show {
  height: 700px;
}

.inside {
  outline: 2px solid gray;
  border-radius: 10px;
  padding: 12px;
  display: flex;
  width: 100%;
  position: relative;
  left: 16%;
  justify-content: space-between;
  margin-top: 10px;
  cursor: pointer;
}

.inside h4,
small {
  margin-left: 50px;
}

.left_payment_heading {
  display: flex;
  padding-left: 20px;
}

.left_payment_heading h4 {
  font-family: Quicksand;
  font-size: 18px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.left_payment_heading div {
  display: flex;

  flex-direction: column;
}

.card_input_logo {
  display: flex;
  padding: 20px;
}

.cards_input {
  padding-left: 20px;
  width: 100%;
}

.cards_input input {
  height: 55px;
  width: 446px;
  left: 264px;
  top: 1836px;
  border-radius: 10px;
  padding: 15px;
  margin: 15px 0 15px 0;
}

.cards_input label {
  display: inline;
}

.expiry,
.cvv {
  width: 100% !important;
}

.flex {
  display: flex;
}

.flex_left,
.flex_right {
  margin-right: 7.5rem;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.cards_input label {
  height: 31px;

  left: 264px;
  top: 1795px;
  border-radius: nullpx;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.emi_top_heading {
  padding-top: 20px;
  padding-bottom: 20px;
}

.emi_top_heading span {
  font-family: Quicksand;
  font-size: 20px;
  font-weight: 500;

  position: relative;
  padding-top: 2px;

  color: #b7b7b7;
}

.checkBox {
  display: flex;

  align-items: center;
}

.checkBox span {
  color: #0066c0;
}

small {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

input[type="radio"] {
  width: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.hidden {
  display: none !important;
  visibility: hidden;
}

.shipment_div {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid gray;
}

.keys {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
  color: #b2b2b2;
  display: inline-block;
}

.values {
  /* position: absolute; */
  color: #262626;
  /* margin-left: 8em; */
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
}

.submit-button-textPop {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-top: 6px;
  transform: skew(20deg);
  color: #ffffff;
}

@media screen and (max-width: 568px) {
  small {
    font-size: 13px;
    font-weight: 500;
  }

  .cards_input label {
    font-size: 17px;
  }

  .inside h4,
  small {
    margin-left: 20px;
  }

  .backIcon {
    display: none;
  }

  .backSpan {
    display: none;
  }

  .checkoutSpan {
    left: 12px;
    font-size: 22px;
  }

  .flex_left,
  .flex_right {
    margin-right: 5.5rem;
    width: 70% !important;
    display: flex;
    flex-direction: column;
  }

  .expiry,
  .cvv {
    width: 104px !important;
  }

  input[type="radio"] {
    width: 39px !important;
    cursor: pointer;
  }

  .payment_logo {
    margin-left: 0;
    left: 0;
  }

  .submit-button {
    font-size: 18px;
  }

  .submit-buttonn {
    font-size: 18px;
  }

  .checkBox span {
    color: #0066c0;
    font-size: 18px;
  }

  .left_payment_heading h4 {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 700;

    text-align: left;
  }

  .shippment_details {
    width: 90vw;
    left: 0;
    margin-left: 15px !important;
    display: flex;
    flex-direction: column;
    margin-left: 0;
    justify-content: space-between;
    height: 100%;
  }

  .shipment_div {
    display: flex;
    justify-content: flex-start;
  }

  .keys {
    font-family: Quicksand;
    font-size: 17px;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #b2b2b2;
    display: inline-block;
  }

  .values {
    /* position: absolute; */
    color: #262626;
    /* margin-left: 10em; */
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 500;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    word-wrap: break-word;
  }

  .marginLeft {
    margin-left: 10px;
  }

  .main_heading {
    /* width: 98.5vw; */
    /* left: -9px; */
    position: relative;
    justify-content: flex-start;
    margin: 0;
  }

  .payment_logo {
    display: none;
  }

  .second_heading {
    /* width: 98.5vw; */
    /* left: -9px; */
    position: relative;

    margin: 0;
    display: flex;
    justify-content: flex-start;

    background: #f1f1f1;

    border-radius: 0px;
    align-items: center;
  }

  .second_heading h3 {
    font-size: 22px;
    margin-left: 0;
    position: relative;
    left: 1em;
  }

  .payment_div {
    left: 0;
    padding: 0;
    margin-left: 0;
    margin-top: 3rem;
    margin-bottom: 4rem;
  }

  .main_wrapper {
    flex-direction: column;
  }

  .inside {
    border-radius: 10px;
    padding: 0;
    display: flex;
    width: 92vw;
    position: relative;
    left: 4px;
    justify-content: space-around;
  }

  .showPopover {
    display: block;
    width: 560px;
    background: #f2f2f2;
    width: 89vw;
    height: auto;
    padding-bottom: 10px;
    position: relative;
    left: 14px;
    border-radius: 20px;
    margin-top: 3px;
  }

  .submit-button {
    width: 14em !important;
    font-size: 0.9em;
    height: 2.4em;
    margin-top: 10px !important;
  }

  .fromPayment-checkout-button {
    width: 24em!important;
    padding-inline: 1rem;
  }

  .card_logo {
    width: 178px;
  }

  .second_container_main_heading {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 98.5vw;
    /* left: -9px; */
    position: relative;
  }

  .cardDiv {
    display: flex;
    position: relative;
    left: 0;
    margin-top: 15px;
    width: 100%;
    height: 150px;
    gap: 2px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .cardDiv h2 {
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: left;
    color: #707070;
  }

  .cardDiv strong {
    font-family: Quicksand;
    font-size: 23px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    color: #241a6b;
    position: relative;
    bottom: 0.9em;
  }

  .cardDiv p {
    font-family: Segoe UI;
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3581c1;
    white-space: pre-line;
  }

  .card__square {
    height: 138px;
    width: 138px;
    left: 0;
    top: 569.5244140625px;
    border-radius: 10px;

    border: 1px solid lightgray;
  }

  .subtotal {
    left: 0;
  }

  .buy-now {
    left: 0;
  }

  .page_Margin_Top_Secondary {
    margin-top: 20px;
  }

  .secondHeading {
    position: relative;
    left: 1em;
    font-family: Quicksand;
    font-size: 22px;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0em;
  }

  .cards_input input {
    width: 18rem;

    margin-top: 0;
  }

  .card_input_logo {
    padding: 15px 0 15px 0;
  }

  .main_heading h3 {
    font-size: 22px;
    position: relative;
    left: 1em;
  }

  .order_break {
    width: 100%;
    left: 0;
  }
  .email-edit-btn {
    place-content: end;
    margin-top: -1rem;
    margin-bottom: 0.75rem;
  }
}

@media screen and (max-width: 500px) {
  .cardDiv {
    flex-direction: column;
    align-items: center;
    height: 100%;
  }
}

@media screen and (max-width : 991px) {
  .main_wrapper {
    flex-direction: column;
    width: 100%;
  }

  .main_container {
    width: 100%;
  }

  .second_container_main_heading {
    width: 100%;
  }

  .cardDiv {
    width: 100%;
    left: 0;
    justify-content: center;
  }
}

@media screen and (min-width: 501px) and (max-width: 1600px) {

  /* styles for screens between 500px and 1600px wide */
  .buy-now {
    background: #ffcc0a !important;
    font-family: Quicksand;
    font-size: 18px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    position: relative;
    width: 210px !important;
    margin-bottom: 15px !important;
  }

  .cardDiv p {
    font-family: "Quicksand";
  }

  .cardDiv p {
    font-size: 10px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #3581c1;
    white-space: pre-line;
  }

  .submit-buttonn {
    position: relative;
    height: 54.54199981689453px;
    width: 372px;
    font-size: 19px;
    border-radius: 0px;
  }

  .submit-button {
    position: relative;
    height: 54.54199981689453px;
    width: 202px;
    font-size: 19px;
    border-radius: 0px;
  }
}

.css-11prve5 .MuiTextField-root {
  width: 39ch !important;
}