@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
/*kannada-font*/
@font-face {
    font-family: "Noto Sans Kannada";
    src: url(/src/assets/fonts/Noto_Serif_Kannada/NotoSerifKannada-VariableFont_wght.ttf);
    unicode-range: U+0964-0965, U+0C82-0CF2, U+200C-200D, U+20B9, U+25CC;
}


body {
  margin: 0;
  font-family: "Quicksand", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: "Quicksand", sans-serif !important;
}