.prod {
  display: flex;
  /* border: 10px solid red; */
}

.products-component {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position:relative;
  /* height: 500px; */
}
.aside {
  background-color: #f2f2f2;
  width: 20%;
  margin-left: 1rem;
}
.aside > img {
  width: 100%;
}
.products {
  width: 80%;
  padding: 0 10px;
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.pre-btn,
.next-btn {
    border: none;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (max-width : 500px) {
  .products {
    overflow-x: scroll;
    width: 100%;
  }
  .products::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width :1024px) {
  .products-component {
    margin: 0;
  }
}